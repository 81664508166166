
// React Bootstrap
import { Col, Container, Row } from "react-bootstrap"

const ContainerText = ({ props, marginLeft, marginRight, containerPadding, display }) => {
    return (
        <Container style={{ marginLeft: marginLeft, marginRight: marginRight, display: display  }} fluid="xxl">
            <Row> 
                <Col style={{ padding: containerPadding }}>
                    {props}
                </Col>
            </Row>
        </Container>
    )
}

export default ContainerText