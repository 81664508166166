import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Slide } from "react-slideshow-image";

// CSS styling
import "../../styles/activitiesPublic.css";

// Axios and API
import { fetchFounderStoryDetail } from "../../api/founderStories";

// HTML react parser
import parse from "html-react-parser";

// Sweet Alert v2
import Swal from "sweetalert2";

// Icons & Backgrounds
import Dummy1 from "../../images/dummy/Dummy1.jpeg";
import Dummy3 from "../../images/dummy/Dummy3.jpeg";
import Dummy4 from "../../images/dummy/Dummy4.webp";
import TemporaryContentFounderStory1 from "../../images/dummy/TemporaryContentFounderStory1.jpeg";
import TemporaryContentFounderStory2 from "../../images/dummy/TemporaryContentFounderStory2.jpeg";
import ArrowRightLearnMore from "../../images/icons/ArrowRightLearnMore.png";

// Components
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import ContainerText from "../../components/Container";
import SocialMedia from "../../components/SocialMedia";

const FoundersStoryDetails = () => {
  const { foundersStoryId } = useParams();
  const navigate = useNavigate();
  const [isMobileSize, setIsMobileSize] = useState(false);
  const [isTabSize, setIsTabSize] = useState(false);
  const mediaQueryMobile = window.matchMedia("(max-width: 640px)");
  const mediaQueryTab = window.matchMedia("(max-width: 910px)");

  const [founderStory, setFounderStory] = useState(null);
  const exploreMoreContents = [
    {
      id: 2,
      title: "Alumnus Australia Ini Kembangkan Pendidikan S-2 Khusus CSR",
      input_summary:
        "Mengajar telah menjadi passion dari alumni Australia yang satu ini. Mulai dari iseng mengajarkan boneka di rumah ketika kecil, menjadi asisten dosen ketika kuliah, hingga akhirnya berhasil mendirikan satu-satunya Program Studi Magister di Indonesia dan Asia...",
      FounderStoryTags: [{ id: 1, Tag: { tag_name: "Article" } }],
      FounderStoryImages: [
        {
          img_url: TemporaryContentFounderStory1,
        },
        {
          img_url: TemporaryContentFounderStory2,
        },
      ],
    },
    {
      id: 3,
      title:
        "Menelaah Kembali Konsep Corporate Social Responsibility (CSR) bersama Ibu Maria Nindita Radyati",
      input_summary:
        "Halo…, Sebenarnya, waktu jaman kuliah dulu dan awal ngeblog, sempat share tentang materi-materi kuliah. Nah, berhubung saya sudah kerja dan mulai nge-blog lagi, pengennya bisa posting-posting materi yang terkait sama pekerjaan, atau sharing ilmu yang...",
      FounderStoryTags: [{ id: 1, Tag: { tag_name: "Article" } }],
      FounderStoryImages: [
        {
          img_url: TemporaryContentFounderStory2,
        },
        {
          img_url: TemporaryContentFounderStory1,
        },
      ],
    },
    {
      id: 1,
      title: "Maria Rosaline Nindita Radyati : CSR dan Distribusi Keuntungan",
      input_summary:
        "Di Indonesia, CSR sering salah dipahami. Kalau dijalankan dengan baik, CSR mampu berperan besar dalam mengentaskan kemiskinan. Kepedulian sosial telah menjadi satu bagian penting dari setiap perusahaan. Corporate Social Resposibility (CSR) adalah satu...",
      FounderStoryTags: [{ id: 1, Tag: { tag_name: "Article" } }],
      FounderStoryImages: [
        {
          img_url: TemporaryContentFounderStory1,
        },
        {
          img_url: TemporaryContentFounderStory2,
        },
      ],
    },
  ];

  const fetchOneFounderStory = async (foundersStoryId) => {
    // const response = await fetchFounderStoryDetail(foundersStoryId);
    // if (response && response.status === 200 && response.data) {
    //   const { data = {} } = response;
    //   setFounderStory(data);
    // }

    const founderStories = [
      {
        id: 1,
        title: "Maria Rosaline Nindita Radyati : CSR dan Distribusi Keuntungan",
        input_summary:
          "Di Indonesia, CSR sering salah dipahami. Kalau dijalankan dengan baik, CSR mampu berperan besar dalam mengentaskan kemiskinan. Kepedulian sosial telah menjadi satu bagian penting dari setiap perusahaan. Corporate Social Resposibility (CSR) adalah satu...",
        FounderStoryTags: [{ id: 1, Tag: { tag_name: "Article" } }],
        FounderStoryImages: [
          {
            img_url: TemporaryContentFounderStory1,
          },
          {
            img_url: TemporaryContentFounderStory2,
          },
        ],
        description: `<p>
        Di Indonesia, CSR sering salah dipahami. Kalau dijalankan
        dengan baik, CSR mampu berperan besar dalam mengentaskan
        kemiskinan.
      </p>
      <p>
        Kepedulian sosial telah menjadi satu bagian penting dari
        setiap perusahaan. Corporate Social Resposibility (CSR)
        adalah satu bagian dalam perusahaan yang berperan mengatur
        setiap kegiatan sosial ini. Namun, di Indonesia,
        pelaksanaan CSR masih belum maksimal. Bagian ini masih
        perlu dikaji lebih lanjut.
      </p>
      <p>
        Maria Rosaline Nindita Radyati berupaya mengadakan
        berbagai penelitian tentang CSR dan social
        entrepreneurship. Ia mendirikan Program Studi Magister
        Manajemen (MM) CSR. Program ini menjadi yang pertama di
        Indonesia dan Asia Pasifik.
      </p>
      <p>
        <b>Kontribusi Akademis</b>
      </p>
      <p>
        Saat lulus dari perguruan tinggi, Nita demikian ia disapa,
        termasuk dalam tiga besar mahasiswa dengan nilai
        tertinggi. Karenanya, ia ditawari menjadi dosen di
        Fakultas Ekonomi dan Bisnis Universitas Trisakti pada
        tahun 1990. Tawaran ini pun ia terima. Setelah menjadi
        dosen, ia berkeinginan untuk berkarya dalam lembaga pusat
        studi. Menurutnya, melalui pusat studi, Nita bisa
        mengaplikasikan teori ke dalam praktik. Ia percaya, bahwa
        ilmu tidak akan berguna jika tidak diterapkan untuk
        mempermudah kehidupan manusia.
      </p>
      <p>
        Sebelas tahun bekerja sebagai dosen, Nita diangkat sebagai
        Kepala Center for Entrepreneurship Change and Third Sector
        (CECT). Untuk semakin memperdalam pengetahuannya, ia
        melanjutkan pendidikan sampai mendapat gelar Doktor di
      </p>
      <p>
        University of Technology Sydney, Australia dan lulus pada
        tahun 2004.
      </p>
      <p>
        Selama menjalani pendidikan ini, Nita tergugah dengan
        sebuah pertanyaan dari Ford Foundation. “Bagaimana
        institusi akademis bisa berkontribusi pada pembangunan
        berkelanjutan?” tuturnya.
      </p>
      <p>
        Sebagai jawaban atas pertanyaan ini, Nita merintis Program
        Studi MM-CSR. Dengan kapasitasnya sebagai Kepala CECT
        Universitas Trisakti, awalnya ia dipercaya mengelola dana
        hibah yang berasal dari yayasan yang berpusat di Amerika
        Serikat itu. Ia mengingat, untuk mendapat kepercayaan ini,
        ia harus bolak balik Jakarta-Sydney untuk melakukan riset.
      </p>
      <p>
        Praktis, Nita mengawali semua dari “nol”. Ia merekrut
        pegawai, menjalankan training dosen, menyusun sistem
        akademik, dan marketing. Semua itu dilakukannya
        disela-sela kesibukannya sebagai mahasiswa doktoral.
      </p>
      <p>
        Nita mendapatkan kenyataan, seluruh kegiatan CSR di
        Indonesia masih belum holistik. Akibatnya, program ini
        hampir tidak memiliki dampak. Berdasarkan fakta tersebut,
        diajukan proposal pendirian sekolah CSR di Indonesia.
        Tahun 2006, proposalnya disetujui Ford Foundation untuk
        empat program; melakukan riset CSR, menyelenggarakan
        training dan seminar, publikasi mengenai CSR
        berkelanjutan, serta mendirikan Program MMCSR.
      </p>
      <p>
        <b>Program MM-CSR</b>
      </p>
      <p>
        Tahun 2007 lahir program MMCSR Universitas Trisakti. Di
        beberapa kampus lain di Indonesia, CSR diberikan sebagai
        mata kuliah. Mayoritas mahasiswa MMCSR merupakan level
        manajerial seperti CEO, komisaris, atau pemilik
        perusahaan. CSR memang berada pada level strategis.
        Ratusan alumni MMCSR kini menjadi praktisi CSR yang
        tersebar di berbagai perusahaan dan organisasi.
      </p>
      <p>
        Nita mengatakan, kurikulum MMCSR disusun dengan model
        akademik praktis yang mengacu pada teori dan International
        Organization for Standardization (ISO) 26000, tentang
        social responsibility. Kurikulum ini telah disepakati di
        162 negara dan dipadukan dengan riset-riset, serta diskusi
        dengan para profesor dari berbagai negara. “CSR Tools ini
        berfungsi sebagai panduan praktis bagi mahasiswa dalam
        menyelesaikan kasus di lapangan atau mendesain sebuah
        program CSR,“ jelas Nita.
      </p>
      <p>
        Setiap tahun, CECT sebagai pengelola MMCSR mengadakan
        survei untuk mengevaluasi manfaat dan dampak pendidikan.
        Nita mengungkapkan, perubahan utama yang dirasakan
        mahasiswa adalah thinking system yang mereka bawa ke
        perusahaan. Mereka mengadakan kebijakan CSR sesuai SOP
        atau memperbaiki SOP yang telah ada.
      </p>
      <p>
        Di Indonesia CSR sering salah dipahami. Nita menjelaskan,
        CSR identik dengan bakti sosial atau pemberian donasi. CSR
        tidak menjadi kebijakan strategis perusahaan, seolah hanya
        menjadi kebijakan residu, yaitu alokasi sisa laba. “Banyak
        perusahaan yang kiss and run, sekali membantu, selesai.
        Tidak menciptakan kemandirian masyarakat, tidak efisien,
        bahkan tanpa sadar justru menciptakan mentalitas meminta,”
        papar Nita.
      </p>
      <p>
        Perusahaan-perusahaan di Indonesia mulai berupaya
        menerapkan CSR sejak dikeluarkannya UU No 40 Tahun 2007
        dan Peraturan Menteri Negara BUMN No 4/2007. Perusahaan
        diwajibkan untuk melakukan kegiatan tanggung jawab sosial
        dan lingkungan. Empat persen laba perusahaan harus
        diperuntukkan bagi program kemitraan dan bina lingkungan.
      </p>
      <p>
        <b>Paradigma CSR</b>
      </p>
      <p>
        Nita menemukan sebuah fenomena, bahwa dana yang
        dialokasikan perusahaan untuk CSR sangat besar, mencapai
        miliaran rupiah. Bahkan, salah satu BUMN menyiapkan dana
        Rp 400 miliar per tahun. “Persoalannya, perusahaan yang
        melakukan CSR masih bisa dihitung dengan jari,” ujarnya.
      </p>
      <p>
        Mengingat besarnya potensi dana yang dialokasikan
        perusahaan untuk kegiatan CSR, Nita berkeyakinan, dana CSR
        di Indonesia jika dikelola secara benar akan sangat
        strategis dalam menciptakan kesejahteraan dan mengentaskan
        kemiskinan di masyarakat. Bila merujuk pada Sustaina ble
        Development Goals (SDGs) 2030, masa depan keberlangsungan
        masyarakat akan banyak dipengaruhi oleh CSR. Perusahaan
        besar maupun kecil nantinya akan sangat terdampak bila
        menolak untuk melakukan CSR dengan baik.
      </p>
      <p>
        “Perusahaan besar terutama yang memerlukan investor asing,
        harus mengikuti tren internasional, SDGs. Jika tidak, tak
        ada yang mau beli sahamnya sehingga mereka tidak bisa
        melakukan ekspansi usaha. Sementara perusahaan kecil di
        daerah misalnya, jika tidak peduli dengan masyarakat di
        sekitarnya, lama-lama akan diprotes karena tidak memberi
        manfaat dari keberadaannya. Protes ini akan mengganggu
        kelancaran produksi,” lanjutnya.
      </p>
      <p>
        Nita menambahkan, tujuan akhir CSR adalah mengurangi
        gangguan bisnis. Dalam praktiknya, banyak CSR tidak
        ditujukan untuk mitigasi risiko. Perusahaan tidak mengerti
        mengapa sudah banyak mengeluarkan uang untuk CSR, namun
        masih sering diprotes oleh masyarakat setempat. Nita
        menekankan, pentingnya merancang CSR yang dikaitkan dengan
        strategi mitigasi risiko.
      </p>
      <p>
        CSR yang benar, kata Nita, adalah tanggung jawab seluruh
        divisi dan merupakan kegiatan di awal kegiatan bisnis,
        bukan kegiatan di akhir tahun setelah perusahaan
        memperoleh profit. Justru CSR yang benar dilakukan di awal
        saat perencanaan bisnis. Jika CSR dilakukan dengan benar
        maka mudah mengukur keuntungan yang dihasilkan dari CSR.
      </p>
      <p>
        Nita sangat gigih mengawal agenda pembangunan
        berkelanjutan di Indonesia dengan menelurkan
        praktisi-praktisi CSR yang mumpuni. Ia mengaku, kepuasan
        terbesar dari kiprahnya di dunia CSR selama ini adalah ia
        mampu membantu masyarakat banyak melalui sektor swasta.
        “Mimpi saya sejak awal mendirikan program studi ini, saya
        ingin sektor swasta dapat membantu meningkatkan
        kesejahteraan komunitas di sekitarnya dengan cara-cara
        yang sustainable,” tutur umat Paroki Maria Bunda Karmel
        Tomang, Jakarta Barat ini.
      </p>
      <p>
        <b>Maria Rosaline Nindita Radyati</b>
      </p>
      <p>Lahir : Malang, 30 Agustus 1967</p>
      <p>Paroki : Maria Bunda Karmel Tomang, Jakarta Barat</p>
      <p>Pendidikan :</p>
      <ul>
        <li>Sarjana Ekonomi – Universitas Trisakti (1990)</li>
        <li>Magister Ekonomi – Universitas Indonesia (1996)</li>
        <li>
          Executive Education – Massachusetts Institute of
          Technology, Sloan School of Management, Boston, Amerika
          Serikat (2010)
        </li>
        <li>
          Doctor of Philosophy – University of Technology Sydney,
          Australia (2011)
        </li>
      </ul>`,
      },
      {
        id: 2,
        title: "Alumnus Australia Ini Kembangkan Pendidikan S-2 Khusus CSR",
        input_summary:
          "Mengajar telah menjadi passion dari alumni Australia yang satu ini. Mulai dari iseng mengajarkan boneka di rumah ketika kecil, menjadi asisten dosen ketika kuliah, hingga akhirnya berhasil mendirikan satu-satunya Program Studi Magister di Indonesia dan Asia...",
        FounderStoryTags: [{ id: 1, Tag: { tag_name: "Article" } }],
        FounderStoryImages: [
          {
            img_url: TemporaryContentFounderStory2,
          },
          {
            img_url: TemporaryContentFounderStory1,
          },
        ],
        description: `<p>
        Mengajar telah menjadi passion dari alumni Australia yang
        satu ini. Mulai dari iseng mengajarkan boneka di rumah
        ketika kecil, menjadi asisten dosen ketika kuliah, hingga
        akhirnya berhasil mendirikan satu-satunya Program Studi
        Magister di Indonesia dan Asia Pasifik dibidang Corporate
        Social Responsibility (CSR) dan banyak dari mahasiswanya
        adalah direktur dan CEO perusahaan.
      </p>
      <p>
        Maria Rosaline Nindita Radyati atau akrab disapa Nita,
        meraih gelar PhD dari University of Technology Sydney
        (UTS), Australia pada tahun 2010.
      </p>
      <p>
        Perempuan kelahiran Malang, 30 Agustus 1967 ini dikenal
        sebagai pakar mengenai Tanggung Jawab Sosial Perusahaan
        atau Corporate Social Responsibility (CSR) di Indonesia.
      </p>
      <p>
        Namanya saat ini tercatat sebagai Komite Tetap CSR di
        Kamar Dagang dan Industri (Kadin) Jakarta dan Ketua
        Masyarakat Riset Sektor Ketiga Internasional (ISTR).
        Selain itu hingga kini, sosok alumni Australia yang satu
        ini juga banyak terlibat dalam berbagai penelitian di
        bidang CSR dan kewiraswastaan sosial (social
        entrepreneurship) serta kerap menjadi narasumber pada
        berbagai seminar, pelatihan dan workshop di dalam maupun
        luar negeri.
      </p>
      <p>
        Karena kepakarannya dibidang CSR inilah, pada tahun 2006
        Ia dipercayakan untuk mengelola dana Grant dari Ford
        Foundation untuk mendirikan Sekolah Magister Managemen
        Corporate Social Reponsibility (MM-CSR) yang bernaung
        dibawah lembaga Center for Entrepreneurship Change and
        Third Sector (CECT) Universitas Trisakti, Jakarta, yang
        dipimpinnya sejak tahun 2001.
      </p>
      <p>
        Namun menurut Nita dalam perbincangannya dengan wartawan
        ABC Australia Plus Indonesia Iffah Nur Arifah, kepercayaan
        ini tidak didapatnya dengan mudah.
      </p>
      <p>
        Tawaran mendirikan Program S-2 ini didapat ketika Ia baru
        memulai studi S-3 di Australia. Walhasil ditengah
        kesibukannya sebagai mahasiswa PhD, Ia harus bolak balik
        Jakarta – Sydney juga untuk melakukan riset dan persiapan
        mendirikan sekolah S-2 tersebut.
      </p>
      <p>
        “Tahun 2005, Ford Foundation menantang saya sebagai
        akademisi untuk mampu berkontribusi lebih dalam isu global
        Sustainability dan CSR di Indonesia. Saya diberi waktu
        satu bulan untuk melakukan riset untuk melakukan tugas
        tersebut,”
      </p>
      <p>
        “Akhirnya saya melakukan riset, untuk meneliti apa yang
        sudah dilakukan, apa yang belum dilakukan oleh semua
        sektor yang terlibat dalam Sustainability dan CSR di
        Indonesia, dan juga apa yang diperlukan.“ katanya lagi.
      </p>
      <p>
        “Dari riset ini disimpulkan seluruh kegiatan CSR di
        Indonesia masih berbentuk filantropi, belum holistis dan
        dampaknya nyaris tidak ada. Indonesia juga perlu
        pendidikan tinggi dibidang CSR dan harus level S-2,"
      </p>
      <p>
        “Tahun 2006, propsal saya untuk menghadirkan sekolah CSR
        di Indonesia ini akhirnya disetujui Ford Foundation,
        mereka memberikan grant sebesar Rp3,5 miliar untuk
        mendirikan 4 program, yakni melakukan riset CSR,
        menyelenggarakan training dan seminar serta publikasi
        mengenai Sustainability dan CSR serta mendirikan Program
        MM-CSR.”
      </p>
      <p>
        “Jadi sambil kuliah Saya, bolak balik Jakarta – Sydney
        untuk merekrut pegawai, mentraining dosen, menyusun sistem
        akademik, marketing dan semuanya..sampai-sampai saya jatuh
        sakit karena demam dan depresi, karena harus kuliah dan
        mengurus persiapan ini,” kenang Nita.
      </p>
      <p>
        Tapi kerja kerasnya kini berbuah manis, MM-CSR Trisakti
        yang mulai resmi dibuka tahun 2007, kini telah memasuki
        angkatan ke-16 dan mayoritas mahasiswa S-2 MM-CSR
        merupakan level manajerial seperti CEO, komisaris, atau
        pemilik perusahaan. Ratusan alumni MM-CSR kini menjadi
        praktisi CSR yang tersebar diberbagai perusahaan dan
        organisasi.
      </p>
      <p>
        MM-CSR Universitas Trisakti juga tercatat menjadi program
        studi pertama dan satu-satunya di Indonesia, bahkan di
        Asia Pasifik. Di beberapa kampus lain di Indonesia, CSR
        masih diberikan sebatas mata kuliah, belum ada perguruan
        tinggi yang menjadikan CSR sebagai program studi dan
        secara holistis mempelajari CSR.
      </p>
      <p>
        Maria Nindita Radyati, mengaku dirinya sangat terinspirasi
        dengan sistem pendidikan di Australia, khususnya
        pendidikan fokasi atau program diploma yang mampu mencetak
        tenaga didik siap kerja dibidangnya. Oleh karena itu, Ia
        menyusun kurikulum di program MM-CSR Universitas Trisakti
        dengan model akademik praktis yang mengacu pada teori dan
        International Organization for Standardization (ISO) 26000
        tentang social responsibility yang sudah disepakati 162
        negara.
      </p>
      <p>
        Bersama suaminya seorang warga Australia yang juga pakar
        project management, Tony Simmonds, Nita merumuskan CSR
        tools yang nantinya dapat digunakan oleh mahasiswa untuk
        menyelesaikan kasus-kasus CSR.
      </p>
      <p>
        “Jadi kurikulum disini itu akademik praktis. Mahasiswa
        disini kami ajarkan menggunakan CSR tools berupa panduan
        ‘How To’ .. yang kami susun berdasarkan teori dan ISO
        26000. CSR Tools ini berfungsi sebagai panduan praktis
        bagi mahasiswa dalam menyelesaikan kasus di lapangan atau
        mendesain sebuah program CSR. “
      </p>
      <p>
        “Biasanya kalau sudah lulus, mahasiswa kami percaya diri,
        karena mereka sudah tahu harus melakukan apa. Mereka sudah
        memiliki table-tabel yang harus mereka isi berdasarkan
        studi kasus di lapangan. Jadi bukan hanya teori-teori
        saja, sehingga pengetahuan mereka bisa customize atau
        disesuaikan dengan kasus yang mereka hadapi nanti ditempat
        mereka berkiprah kelak,”
      </p>
      <p>
        “Jadi mungkin universitas lain boleh mengklaim punya
        jurusan CSR tapi kami yakin hanya Program MM-CSR
        Universitas Trisakti yang memiliki CSR tools - how to yang
        unik dan aplikatif seperti ini,”
      </p>
      <p>
        Terinspirasi oleh keunggulan pendidikan fokasi di
        Australia, Nita menyusun kurikulum akademik praktis untuk
        sekolah MM-CSR yang didirikannya. (Foto: MM-CSR Univ.
        Trisakti).
      </p>
      <p>
        Terinspirasi oleh keunggulan pendidikan fokasi di
        Australia, Nita menyusun kurikulum akademik praktis untuk
        sekolah MM-CSR yang didirikannya. (Foto: MM-CSR Univ.
        Trisakti).
      </p>
      <p>
        Dengan metode ini, Nita berharap program magister yang
        dirintisnya mampu menciptakan perubahan dan meluruskan
        praktek kebijakan CSR di Indonesia ke arah CSR yang
        menyeluruh.
      </p>
      <p>
        Yakni program CSR yang bisa menciptakan pembangunan yang
        berkelanjutan berbasis kompotensi masyarakat disekitar
        perusahaan tersebut. Bukan program CSR yang bersifat
        bagi-bagi uang.
      </p>
      <p>
        Mengingat besarnya potensi dana yang dialokasikan
        perusahaan untuk kegiatan CSR, Nita berkeyakinan dana CSR
        di Indonesia jika dikelola secara benar akan sangat
        strategis dalam menciptakan kesejahteraan dan mengentaskan
        kemiskinan di masyarakat.
      </p>
      <p>
        “Ada salah satu BUMN besar ditanah air, itu dana CSR-nya
        setahun Rp 400 miliar, bayangkan berapa banyak masyarakat
        kita yang bisa tersejahterakan dari dana CSR tersebut.
        Jadi saya katakan kalau dana CSR dikelola dengan benar,
        seharusnya tidak ada orang miskin di Indonesia,”
      </p>
      <p>
        "Tapi sayang banyak perusahaan yang menggunakan dana
        CSRnya masih bersifatnya kiss and run. Sekali membantu,
        setelah itu selesai. Tidak menciptakan
      </p>
      <p>
        kemandirian masyarakat, tidak efisien. Progam CSR seperti
        ini menjadikan CSR seperti mesin atm dan menciptakan
        mentalitas meminta," papar Nita.
      </p>
      <p>
        Apalagi terkait dengan 17 agenda global pembangunan
        berkelanjutan atau Sustainable Development Goals tahun
        2030, kedepan menurut Nita, CSR akan memainkan peran
        penting untuk mewujudkan pembangunan yang berkelanjutan.
      </p>
      <p>
        “Untuk perusahaan besar terutama yang memerlukan investor
        asing, mau tidak mau mereka harus mengikuti tren
        internasional - Sustainable Development Goals. Kalo tidak,
        maka tidak ada yang mau beli sahamnya sehingga mereka
        tidak bisa melakukan ekspansi usaha.”
      </p>
      <p>
        “Sementara untuk perusahaan kecil di daerah misalnya, jika
        dia tidak peduli dengan masyarakat disekitarnya, lama-lama
        mereka akan protes karena merasa tidak mendapat manfaat
        dari keberadaan perusahaan tersebut. Protes ini akan
        mengganggu kelancaran produksi mereka.
      </p>
      <p>
        “’Jadi CSR itu sebetulnya tujuan akhirnya adalah untuk
        mengurangi atau menghilangkan gangguan bisnis. Jadi kalau
        ada perusahaan gak mau peduli dengan masyarakat sekitarnya
        atau tidak melakukan CSR, kedepannya akan habis usahanya."
      </p>
      <p>
        Pemerintah daerah kini banyak yang mengandalkan dana CSR
        untuk membantu program pembangunan. Seperti Program
        Penataan Pemukiman Kumuh di Jakarta (MHT Plus) yang
        didanai oleh dana CSR perusahaan di Jakarta. (Foto:
        Flickr)
      </p>
      <p>
        2000-an. Tepatnya sejak diberlakukannya pasal 74
        Undang-Undang No 40 Tahun 2007 tentang Perseroan Terbatas
        yang mewajibkan setiap perusahaan untuk melakukan kegiatan
        tanggung jawab sosial dan lingkungan. UU tersebut, antara
        lain, dipicu kejadian lumpur Lapindo di Sidoarjo pada
        2006.
      </p>
      <p>
        Aturan ini juga diperkuat dengan Peraturan Menteri Negara
        BUMN No 4 Tahun 2007 yang menetapkan bahwa laba perusahaan
        harus disisihkan sebesar 4 persen. Perinciannya, 2 persen
        persen untuk program kemitraan dan 2 persen lainnya untuk
        program bina lingkungan.
      </p>
      <p>
        Namun lepas dari kegigihannya mengawal agenda pembangunan
        berkelanjutan di Indonesia dengan menelurkan
        praktisi-praktisi CSR yang mumpuni, Maria Nindita Radyati
        mengaku kepuasan terbesar dari kiprahnya di dunia CSR
        selama ini adalah Ia mampu membantu masyarakat banyak
        melalui sektor swasta.
      </p>
      <p>
        “Mimpi saya sejak awal mendirikan S-2 ini, saya ingin
        membantu private sector, biar nanti private sector bisa
        membantu masyarakat. Jadi sektor swasta saya kasih
        panduannya,rumusan how to-nya supaya bisa membantu
        meningkatkan kesejahteraan komunitas di sekitarnya dengan
        cara-cara yang sustainable.” ungkapnya.
      </p>
      <p>
        Disclaimer: Berita ini merupakan kerja sama
        Republika.co.id dengan ABC News (Australian Broadcasting
        Corporation). Hal yang terkait dengan tulisan, foto,
        grafis, video, dan keseluruhan isi berita menjadi tanggung
        jawab ABC News (Australian Broadcasting Corporation).
      </p>`,
      },
      {
        id: 3,
        title:
          "Menelaah Kembali Konsep Corporate Social Responsibility (CSR) bersama Ibu Maria Nindita Radyati",
        input_summary:
          "Halo…, Sebenarnya, waktu jaman kuliah dulu dan awal ngeblog, sempat share tentang materi-materi kuliah. Nah, berhubung saya sudah kerja dan mulai nge-blog lagi, pengennya bisa posting-posting materi yang terkait sama pekerjaan, atau sharing ilmu yang...",
        FounderStoryTags: [{ id: 1, Tag: { tag_name: "Article" } }],
        FounderStoryImages: [
          {
            img_url: TemporaryContentFounderStory1,
          },
          {
            img_url: TemporaryContentFounderStory2,
          },
        ],
        description: `<p>Halo…</p>
        <p>
          Sebenarnya, waktu jaman kuliah dulu dan awal ngeblog,
          sempat share tentang materi-materi kuliah. Nah, berhubung
          saya sudah kerja dan mulai nge-blog lagi, pengennya bisa
          posting-posting materi yang terkait sama pekerjaan, atau
          sharing ilmu yang didapat dari tempat kerja. Yaaaa,
          hitung-hitung biar agak berbobot lah ya, sekalian saya
          mengingat materi yang saya dapat sama mengasah ide-ide
          yang mungkin aja bisa diterapkan di tempat kerja
          barangkali.
        </p>
        <p>
          Beberapa waktu lalu, saya berkesempatan ‘nyelundup’ ke
          acara Rapat Kerja bidang SDM & Umum yang lagi
          diselenggarain di perusahaan tempat saya bekerja. Kenapa
          ‘nyelundup’? Soalnya saya masih kacung kampret, jadi yang
          diundang ke acara itu para bangsawan alias bos-bos aja
          sebenernya. Kebetulan atasan saya mengijinkan anak buahnya
          menyelundup, demi mendapatkan ilmu yang bermanfaat.
          Ahseeeeek!
        </p>
        <p>
          Pada acara tersebut, saya mendapatkan wawasan baru tentang
          arti Corporate Social Responsibility (CSR) yang
          sebenarnya. Tersebutlah, Ibu Maria Rosaline Nindita
          Radyati, PhD, Dip. Cons, Dip. PM, Cert.IV IRM, yang
          menjadi pembicara di seminar pada acara tersebut. Gelarnya
          banyak ya ibu itu. Beliau adalah Executive Director CECT
          (Center for Entrepreneurship, Change and Third Sector)
          Universitas Trisakti serta Founding Director MM-CSR
          (Corporate Social Responsibility) & MM-CE (Community
          Entrepreneurship) Universitas Trisakti. Seriusan loh, saya
          baru tahu bahwa CSR sekarang menjadi bidang keilmuan
          sendiri. Ibu Maria Radyati ini bisa dibilang Ahli CSR
          Indonesia. Beliau terlibat dalam perumusan peraturan dan
          perundang-undangan terkait CSR di Indonesia. Ga perlu
          diragukan lagi lah ya urusan pengalaman beliau di bidang
          CSR.
        </p>
        <p>
          SR, Sustainable Development, and Shared Value : Where do
          we go?
        </p>
        <p>
          Ada yang pernah dengar CSR atau Corporate Social
          Responsibility ? Umumnya kita mengenal CSR sebagai program
          PKBL (Program Kemitraan dan Bina Lingkungan). Banyak kita
          temui di situs perusahaan yang menyebutkan bahwa CSR sama
          dengan PKBL. Orang akan berfikir bahwa CSR memiliki
          pengertian sebagai Corporate Philantropy atau Donasi
          Perusahaan sebagai bentuk pemberian kepada komunitas
          berupa penyisihan laba. Padahal ini adalah pengertian yang
          kurang tepat.
        </p>
        <p>
          Kalau ditelaah lagi, sebenarnya apa manfaat perusahaan
          melakukan aktivitas CSR?
        </p>
        <ul>
          <li>
            Pencitraan Perusahaan, sehingga dapat meningkatkan
            penjualan (membantu tugas Unit Sales & Marketing)
          </li>
          <li>
            Relasi yang baik dengan komunitas, sehingga proses
            bisnis (produksi) akan berjalan dengan baik tanpa
            gangguan (melancarkan tugas Unit Operasi)
          </li>
          <li>
            Relasi yang baik dengan pemerintah, sehingga proses
            perizinan yang dilakukan akan lancar (melancarkan tugas
            unit Hukum)
          </li>
        </ul>
        <p>
          Berdasarkan CSR telah diatur melalui ISO 26000. ISO 26000
          provides guidance on how businesses and organizations can
          operate in a socially responsible way. Ini artinya telah
          ada kesepakatan dunia mengenai CSR yang dirumuskan dalam
          ISO ini. ISO dengan kode awal 2 memiliki artian sebagai
          panduan (bukan sertifikasi maupun standarisasi).
        </p>
        <p>
          Dalam ISO 26000, disebutkan bahwa “The objective of social
          responsibility is to contribute to sustainable
          development.” Sementara itu, Sustainable development
          memiliki pengertian sebagai “development that meets the
          needs of the present without compromising the ability of
          future generations to meet their own needs” (United
          Nations General Assembly, 1987, p. 43). Dengan demikian
          CSR dalam ISO 26000 memiliki tujuan nyata untuk
          pengembangan berkelanjutan, tidak hanya saat ini dan perlu
          dikelola dengan baik. Kalau pikiran kita CSR adalah hanya
          memberikan sebagian keuntungan perusahaan maka ini adalah
          pikiran yang kurang tepat, karena cenderung hanya
          memberikan kebutuhan pada masa sekarang.
        </p>
        <p>
          Lalu, bagaimana ruang lingkup Social Responsibility
          berdasarkan ISO 26000?
        </p>
        <p>
          Bab 6 ISO 26000, menjelaskan tentang inti dan hal-hal lain
          yang berkaitan dengan social responsibility, termasuk
          ruang lingkup, hubungan dengan tanggung social
          responsibility, prinsip, pertimbangan, tindakan, dan
          harapan. 7 subyek inti dari Social Responsibility adalah :
        </p>
        <ol>
          <li>
            Tata kelola organisasi (organizational governance),
            sistem pengambilan dan penerapan keputusan perusahaan
            dalam rangka mencapai tujuan
          </li>
          <li>
            Hak asasi manusia (human rights), hak-hak dasar yang
            berhak dimiliki semua orang sebagai manusia.
          </li>
          <li>
            Praktik ketenagakerjaan (labour practices), segala
            kebijakan dan praktik yang terkait dengan
            ketenagakerjaan.
          </li>
          <li>
            Lingkungan (the environment), dampak keputusan dan
            kegiatan perusahaan terhadap lingkungan
          </li>
          <li>
            Prosedur operasi yang wajar (fair operating
            procedures),perilaku organisasi saat berhubungan dengan
            organisasi atau kelompok lain.
          </li>
          <li>
            Isu konsumen (consumer issues), tanggung jawab
            perusahaan terhadap konsumen dan pelanggannya.
          </li>
          <li>
            Pelibatan dan pengembangan masyarakat (community
            involvement and development), hubungan organisasi dengan
            masyarakat di sekitar wilayah operasinya.
          </li>
        </ol>
        <p>
          Menurut Ibu Maria Radyati, CSR adalah cara meningkatkan
          keuntungan perusahaan dengan cara yang bertanggung jawab
          secara berkelanjutan. Hal ini memiliki pengertian
          perusahaan dapat menggunakan sumber daya (material) yang
          ramah dan berkelanjutan, pemilihan supplier yang memiliki
          tanggung jawab sosial, pelaksanaan produksi yang
          mempertimbangkan tanggung jawab sosial, sistem distribusi
          yang juga memiliki tanggung jawab sosial, pengelolaan
          promosi dan penjualan yang didasari tanggung jawab sosial,
          serta mengelola pelanggan dengan tanggung jawab.
        </p>
        <p>
          Kalau kita melihat kebanyakan realita yang terjadi di
          Indonesia, pada saat ini perilaku CSR di Indonesia masih
          identik dengan bakti sosial atau pemberian donasi, belum
          menjadi isu strategis dalam perusahaan. Akhirnya, yang
          terjadi kebanyakan adalah komunitas yang mendapatkan CSR
          bisa menjadi membandingkan antara perusahaan satu dengan
          lainnya yang pernah memberikan CSR, yang berujung kepada
          permintaan dana yang semakin tinggi lagi oleh komunitas.
          Bagi perusahaan sendiri, hasil riset yang dilakukan Ibu
          Maria Radyati menghasilkan fakta bahwa dana yang
          dialokasikan perusahaan untuk CSR sangat besar, mencapai
          miliaran rupiah. Banyak sekali perusahaan yang sifat CSR
          nya adalah Philanthropic dan Kiss & Run Program, jadi
          hanya sekali membantu, tidak menciptakan kemandirian,
          tidak efisien, bahkan mengubah mentalitas masyarakat
          menjadi mentalitas meminta.
        </p>
        <p>Mengenal Creating Shared Value (CSV)</p>
        <p>
          Michael Porter & Mark Kramer mengenalkan konsep Shared
          Value pada melalui artikel dalam Harvard Business Review,
          yaitu “corporate policies and practices that enhance the
          competitiveness of a company while simultaneously
          advancing social and economic conditions in the
          communities in which the company sells and operates“.
          Konsep ini kini telah digunakan oleh perusahaan kelas
          dunia seperti Nestle, Intel, Unilever, dll.
        </p>`,
      },
    ];

    let finalObjFounderStory = null;
    for (let l = 0; l < founderStories.length; l++) {
      const { id } = founderStories[l];
      if (id === parseInt(foundersStoryId)) {
        finalObjFounderStory = founderStories[l];
      }
    }
    setFounderStory(finalObjFounderStory);
  };

  const handleDetectWindowSize = (mediaQuery, tab) => {
    if (tab === "mobile") {
      setIsMobileSize(mediaQuery.matches);
    } else {
      setIsTabSize(mediaQuery.matches);
    }
  };

  const copyLink = () => {
    navigator.clipboard.writeText(
      `https://www.sustainability.co.id/Founder-story/${foundersStoryId}`
    );
    Swal.fire({
      position: "center",
      imageUrl:
        "https://cdn.discordapp.com/attachments/796711355876245534/986824551537209404/copy_clipboard_icon.png",
      imageWidth: 200,
      imageHeight: 200,
      width: "45em",
      title: "Success",
      text: "Link has been copied to clipboard",
      showCloseButton: true,
      showConfirmButton: false,
      timer: 1500,
      background: "#E4E4E4",
    });
  };

  useEffect(() => {
    if (foundersStoryId) {
      fetchOneFounderStory(foundersStoryId);
    }
  }, [foundersStoryId]);

  useEffect(() => {
    if (mediaQueryMobile) {
      handleDetectWindowSize(mediaQueryMobile, "mobile");
    }
  }, [mediaQueryMobile]);

  useEffect(() => {
    if (mediaQueryTab) {
      handleDetectWindowSize(mediaQueryTab, "tab");
    }
  }, [mediaQueryTab]);

  const buttonStyle = {
    border: "none",
    outline: "none",
    color: "#FFFFFF",
    fontSize: "30px",
    backgroundColor: "#000000",
    padding: "5px",
  };

  const btnContainerStyle = {
    position: "absolute",
    zIndex: "1",
    cursor: "pointer",
    backgroundColor: "#000000",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    bottom: "5%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const properties = {
    prevArrow: (
      <div
        style={{ ...btnContainerStyle, left: isMobileSize ? "60%" : "82.5%" }}
      >
        <button className="arrow arrow-left" style={{ ...buttonStyle }}>
          <span>&lt;</span>
        </button>
      </div>
    ),
    nextArrow: (
      <div style={{ ...btnContainerStyle, right: "3%" }}>
        <button className="arrow arrow-right" style={{ ...buttonStyle }}>
          <span>&gt;</span>
        </button>
      </div>
    ),
  };

  return (
    <div id="activityPublic">
      <Header />
      {founderStory ? (
        <div className="activityPublicContainer">
          <ContainerText
            props={
              <div
                className="activityPublicDetailCardContainerDesc"
                style={{ margin: "25px" }}
              >
                <div
                  className="previousButton"
                  onClick={() => navigate("/Founders-story")}
                  style={{ marginTop: "4em" }}
                >
                  <span className="expertiseArrowRight">
                    <i className="fas fa-angle-left" />
                  </span>
                  <a href="">Back to previous page</a>
                </div>
                {founderStory &&
                founderStory.FounderStoryTags &&
                Array.isArray(founderStory.FounderStoryTags) &&
                founderStory.FounderStoryTags.length > 0 ? (
                  <div
                    style={{ display: "flex", gap: "1em", marginTop: "1em" }}
                  >
                    {founderStory.FounderStoryTags.map((tag) => {
                      const { id, Tag: { tag_name } = {} } = tag || {};
                      return (
                        <div key={id}>
                          <div
                            style={{
                              color: "#FF9F1E",
                              fontSize: isMobileSize ? "16px" : "20px",
                              fontWeight: "700",
                            }}
                          >
                            {tag_name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {founderStory && founderStory.title ? (
                  <div style={{ marginTop: "1em", marginBottom: "3em" }}>
                    <div
                      href="#"
                      style={{
                        fontSize: isMobileSize ? "35px" : "45px",
                        color: "#0D4C86",
                        fontWeight: "600",
                      }}
                    >
                      {founderStory.title}
                    </div>
                  </div>
                ) : null}
                {founderStory &&
                founderStory.FounderStoryImages &&
                Array.isArray(founderStory.FounderStoryImages) &&
                founderStory.FounderStoryImages.length > 1 ? (
                  <Slide {...properties}>
                    {founderStory.FounderStoryImages.map((slideImage, idx) => {
                      const { img_url } = slideImage;
                      return (
                        <div
                          key={idx}
                          className="founderStoryPublicCardPicture"
                        >
                          <img
                            src={img_url}
                            alt={`dummy slide ${idx + 1}`}
                          ></img>
                        </div>
                      );
                    })}
                  </Slide>
                ) : founderStory.FounderStoryImages &&
                  Array.isArray(founderStory.FounderStoryImages) &&
                  founderStory.FounderStoryImages.length === 1 ? (
                  <div className="founderStoryPublicCardPicture">
                    <img
                      src={founderStory.FounderStoryImages[0]["img_url"]}
                      alt={`dummy slide`}
                    ></img>
                  </div>
                ) : null}
                {founderStory && founderStory.description ? (
                  <div
                    className="activityPublicCardDescription"
                    style={{
                      lineHeight: "30px",
                      marginTop: "2em",
                      textAlign: isMobileSize ? "" : "justify",
                      fontSize: isMobileSize ? "16px" : "20px",
                    }}
                  >
                    {/* <p>{founderStory.description}</p> */}
                    {parse(founderStory.description)}
                  </div>
                ) : null}
                <div
                  className="detailBlogSocialMedia left"
                  style={{
                    marginLeft: "-1vw",
                    marginTop: "1em",
                    marginBottom: "2em",
                  }}
                >
                  <SocialMedia
                    twitterURL={`https://www.sustainability.co.id/Founders-story/${foundersStoryId}`}
                    facebookURL={`https://www.sustainability.co.id/Founders-story/${foundersStoryId}`}
                    linkedinURL={`https://www.sustainability.co.id/Founders-story/${foundersStoryId}`}
                    mailSubject={founderStory.title}
                    mailContent={`Hello, I recommend you to read the articles on Institute for Sustainability and Agility (ISA): ${founderStory.title} https://www.sustainability.co.id/Founders-story/${foundersStoryId}`}
                    copyLink={copyLink}
                  />
                </div>
                <div
                  className="aboutUsTitleCenter"
                  style={{ marginBottom: "1.5em" }}
                >
                  <p>Explore more</p>
                </div>
                <div className="blogCardContainerWorkshop">
                  {exploreMoreContents &&
                    Array.isArray(exploreMoreContents) &&
                    exploreMoreContents.length > 0 &&
                    exploreMoreContents.map((exploreMoreContent) => {
                      const {
                        id,
                        FounderStoryImages,
                        FounderStoryTags,
                        title,
                        input_summary,
                      } = exploreMoreContent || {};
                      let founderStoryCoverImage = "";
                      if (
                        FounderStoryImages &&
                        Array.isArray(FounderStoryImages) &&
                        FounderStoryImages.length > 0
                      ) {
                        founderStoryCoverImage = FounderStoryImages[0].img_url;
                      }

                      return (
                        <div className="founderStoryExploreMoreEach" key={id}>
                          <div className="founderStoryExploreMorePicture">
                            <img
                              src={founderStoryCoverImage}
                              onClick={() => navigate(`/Founders-story/${id}`)}
                              alt="academy"
                            />
                          </div>
                          {FounderStoryTags &&
                          Array.isArray(FounderStoryTags) &&
                          FounderStoryTags.length > 0 ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "1em",
                                marginTop: "1em",
                              }}
                            >
                              {FounderStoryTags.map((tag) => {
                                const { id, Tag: { tag_name } = {} } =
                                  tag || {};
                                return (
                                  <div key={id}>
                                    <div
                                      style={{
                                        color: "#FF9F1E",
                                        fontSize: isMobileSize
                                          ? "16px"
                                          : "18px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      {tag_name}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}
                          {title ? (
                            <div
                              className="founderStoryCardTitle"
                              style={{ marginTop: "0" }}
                              onClick={() => navigate(`/Founders-story/${id}`)}
                            >
                              <a
                                href="#"
                                style={{
                                  fontSize: "25px",
                                  color: "#0D4C86",
                                  fontWeight: "600",
                                }}
                              >
                                {title}
                              </a>
                            </div>
                          ) : null}
                          {input_summary ? (
                            <div
                              className="containerDesc"
                              style={{
                                marginTop: "1em",
                                marginLeft: "0",
                                textAlign: "justify",
                              }}
                            >
                              <div
                                className="eachMembersDesc"
                                style={{
                                  fontSize: isMobileSize ? "16px" : "20px",
                                }}
                              >
                                <p>{input_summary}</p>
                              </div>
                            </div>
                          ) : null}
                          <div
                            className="learnMoreContainer"
                            onClick={() => navigate(`/Founders-story/${id}`)}
                            style={{ marginTop: "1em" }}
                          >
                            <div>Click to read more</div>
                            <div>
                              <img
                                src={ArrowRightLearnMore}
                                style={{
                                  width: isMobileSize
                                    ? "3.7vw"
                                    : isTabSize
                                    ? "1.5vw"
                                    : "1vw",
                                  height: "auto",
                                  marginLeft: isMobileSize ? "2vw" : "",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            }
          />
        </div>
      ) : null}
      <Footer />
    </div>
  );
};

export default FoundersStoryDetails;
