// Events Pagination
export const events = [
  {
    title: "Project Management",
    image:
      "https://media.discordapp.net/attachments/796711355876245534/938290086934642718/Cloudxier.png",
    type: "Workshop",
    reviews: "50",
  },
  {
    title: "Creating Shared Value (CSV)",
    image:
      "https://media.discordapp.net/attachments/796711355876245534/938290087437951026/Cloudxier1.png",
    type: "Workshop",
    reviews: "65",
  },
  {
    title: "Leadership in ESG",
    image:
      "https://media.discordapp.net/attachments/796711355876245534/938290087664447599/Cloudxier2.png",
    type: "Webinar",
    reviews: "80",
  },
  {
    title: "Creating Shared Value (CSV)",
    image:
      "https://media.discordapp.net/attachments/796711355876245534/938290087437951026/Cloudxier1.png",
    type: "Workshop",
    reviews: "65",
  },
  {
    title: "Project Management",
    image:
      "https://media.discordapp.net/attachments/796711355876245534/938290086934642718/Cloudxier.png",
    type: "Workshop",
    reviews: "50",
  },
  {
    title: "Leadership in ESG",
    image:
      "https://media.discordapp.net/attachments/796711355876245534/938290087664447599/Cloudxier2.png",
    type: "Webinar",
    reviews: "80",
  },
  {
    title: "Project Management",
    image:
      "https://media.discordapp.net/attachments/796711355876245534/938290086934642718/Cloudxier.png",
    type: "Workshop",
    reviews: "50",
  },
  {
    title: "Project Management",
    image:
      "https://media.discordapp.net/attachments/796711355876245534/938290086934642718/Cloudxier.png",
    type: "Workshop",
    reviews: "50",
  },
  {
    title: "Leadership in ESG",
    image:
      "https://media.discordapp.net/attachments/796711355876245534/938290087664447599/Cloudxier2.png",
    type: "Webinar",
    reviews: "80",
  },
];

// ISA Academy
export const academies = [
  {
    id: 4,
    picture:
      "https://storage.googleapis.com/testing_storage_cloudxier/isa/esg_concept_15feb.png",
    title:
      "ESG, CSR, GRI, SDGs: Concept, Issues and Context with (Case Studies)",
    htmlTitle:
      "<span>ESG, CSR, GRI, SDGs: Concept, Issues and Context with (Case Studies)</span>",
    htmlTitleNoColor:
      "<span>ESG, CSR, GRI, SDGs: Concept, Issues and Context with (Case Studies)</span>",
    description:
      "ESG and SDGs are lately very popular points of measurements for sustainable practices. Businesses have been using them to help decide on how they can become more sustainable. On top of those, companies also opt to use ISO 26000 as a backbone of their CSR Practices. There are many real-life examples of the applications of these concepts. We have used the correlated these concepts for many of our clients, and in this workshop we will use those as our case studies!",
    date: "15 February 2023  |  09.00 - 15.00 WIB",
    whatWillYouLearn: `
        <strong>
            Workshop Materials:
        </strong>
        <br />
        <ol>
            <li>ESG, ISO 26000, GRI, CSR, SDGs: what, why, and examples of its application</li>
            <li>ESG Ratings and its relation with GRI</li>
            <li>The link between ESG, ISO 26000, CSR</li>
            <li>Linking Targets and Indicators on SDGs with ESG and ISO 26000</li>
            <li>Exercise: Formulating the materiality of the SDGs</li>
            <li>Exercise: Conduct a self-assessment of your company's ESG performance</li>
            <li>Choosing the right ESG standard</li>
            <li>Formulating ESG materiality</li>
            <li>What is needed to improve ESG reputation in the eyes of investors and ESG Rating Agencies</li>
            <li>Exercise: Formulate recommendations for strategic CSR for your company, namely those that achieve good ESG performance, follow ISO 26000, create Shared-Value, and contribute to the right SDGs.</li>
        </ol>`,
    isaAcademy: `
        <strong>
            Our Pre-eminence:
        </strong>
        <br />
        <ol>
            <li>Conducted by Instructors who are expert with professional experiences in assisting
            multinational and national companies in the formulation of the ESG Roadmap, such as:
            Vale Inco, Saratoga Investama, Amartha, and PLN.</li>
            <li>During the workshop, the participants will conduct hands-on experience, hence they can
            immediately formulate recommendations for companies guided by the expert instructor.</li>
            <li>After the workshop is finished, all knowledge and tools can be directly used for the
            company.</li>
        </ol>`,
    whatWillYouGet: `
        <ol>
            <li>Materials that can be used as a company guide</li>
            <li>Tools that can be used directly by the company</li>
        </ol>
    `,
    targetAudience: `
        <ol>
            <li>State-Owned Enterprises, Private Sector and Civil Society</li>
            <li>ESG and CSR practitioners</li>
            <li>Sustainability Report writer</li>
            <li>Students of Master Degree, Doctorate or PhD Programs</li>
            <li>General Managers</li>
            <li>Public Relations Managers</li>
            <li>Other practitioners who are interested in ESG, ISO 26000, CSR</li>
        </ol>
    `,
    investment: `
        <p>
            One (1) day workshop: IDR 3.000.000 (online)
        </p>
        <strong>Packages for organisations' group:</strong>
        <ul>
            <li>IDR 8.500.000 for 3 participants</li>
            <li>IDR 14.000.000 for 5 participants</li>
        </ul>
    `,
    methodOfTraining: `
            <ul>
                <li>
                    <strong>Method of training:</strong> Zoom Call Application
                </li>
                <li>
                    <strong>How to register:</strong>
                    <ol>
                        <li>Fill in registration form in the link bellow</li>
                        <li>Once submitted, you will receive confirmation email for the next steps</li>
                    </ol>
                </li>
            </ul>
            <strong>
                For further information, please contact:
            </strong>
            <br />
            <br />
            <span>WhatsApp: </span>
            <br />
            <br />
            <div className="contactWhatsAppButton">
                <a href="https://api.whatsapp.com/send/?phone=6281218467661&text&app_absent=0" target="_blank" rel="noreferrer">
                    +6281218467661 (Billy)
                </a>
            </div>
            <br />
            <span>
                Email: &nbsp;
                <a className="academyEmail" href="mailto:isa-academy@sustainability.co.id">
                    isa-academy@sustainability.co.id
                </a>
            </span>
            <br />
            <br />
        `,
    registrationLink: "bit.ly/ISAESG2",
  },
  {
    id: 3,
    picture:
      "https://storage.googleapis.com/testing_storage_cloudxier/isa/workshop_26_jan.png",
    title: "How to Calculate Social Return on Investment (SROI)",
    htmlTitle:
      "<span>How to Calculate Social Return on Investment (SROI)</span>",
    htmlTitleNoColor:
      "<span>How to Calculate Social Return on Investment (SROI)</span>",
    date: "26-27 January 2023",
    description:
      "The Social Return on your CSR Investment can be used as a multiplier for your financial return, and through this workshop you will learn how to calculate the Social Return on your investment (SROI). You can better communicate the social value of your project using SROI, because SROI helps manage the social value of a project in the form of measurable numbers that can be understood by stakeholders.",
    firstContent: `
            The Social Return on your CSR Investment can be used as a multiplier for your financial return, and through this workshop you will learn how to calculate the Social Return on your investment (SROI). You can better communicate the social value of your project using SROI, because SROI helps manage the social value of a project in the form of measurable numbers that can be understood by stakeholders. <br /><br />
            Similarly to ROI, SROI compares the net present value of benefits to the net present value of the resources invested, but it aims to do so by accounting for the whole range of value that is generated. It is a framework for measuring and accounting for this much broader concept of value; it seeks to reduce inequality and environmental degradation and improve well-being by incorporating social, environmental and economic costs and benefits. <br /><br />
            Furthermore, SROI is not just calculating value in monetary units, but SROI also seeks to see the impact created by a program on society and the effectiveness of a company’s social investment.        
        `,
    whatWillYouLearn: `
            <strong>
                Workshop Materials:
            </strong>
            <br />
            <strong>
                Day 1:
            </strong>
            <ol>
                <li>Key Elements and Social Investment Development Steps</li>
                <li>Introduction to types of Social Impact Calculation Methods</li>
                <li>Benefit Cost Analysis</li>
                <li>Poverty and Social Impact Analyzing (PSIA)</li>
                <li>Introduction to Social Return on Investment</li>
                <li>What is Social Return on Investment (SROI)?</li>
                <li>Why SROI needs to be measured?</li>
                <li>Who are the stakeholders involved in SROI calculation?</li>
                <li>When is right time to use SROI calculation?</li>
                <li>How to calculate SROI?</li>
                <li>Evaluative, conducted retrospectively and</li>
                <li>Forecast, predict how much prospective social values</li>
            </ol>
            <strong>
                Day 2:
            </strong>
            <ol>
                <li>Stakeholder's Input and Activity</li>
                <li>Output</li>
                <li>Outcomes</li>
                <li>SROI Ratio</li>
                <li>Operate SROI calculation through study cases</li>
                <li>Conduct Simulation using SROI on any specified programs</li>
            </ol>
        `,
    isaAcademy: `
            <strong>
                Facilitator
            </strong>
            </br>
            Maria Nindita Radyati Ph. D, Dip, Cons., Dip. PM
            </br>
            Maria has over 20 years research and consulting experience in projects with the Corporate Social Responsibility (CSR) and Higher Education Sectors. She has established two master’s degree programs specializing in CSR and Social Entrepreneurship, and she also has a Ph. D from The University of Technology Sydney. She has also led numerous sustainability-related programs for various private and state-owned enterprises.
            </br></br>
            <strong>
                Our Pre-eminence:
            </strong>
            <br />
            <ol>
                <li>Conducted by an Instructor who is an expert with professional experiences in assisting multinational and national companies in the formulation of the ESG Roadmap, such as: Vale Inco, Saratoga Investama, Amartha, and PLN.</li>
                <li>During the workshop, the participants will conduct hands-on experience, hence they can immediately formulate recommendations for companies guided by the expert instructor.</li>
                <li>After the workshop is finished, all knowledge and tools can be directly used for the company.</li>
            </ol>
        `,
    whatWillYouGet: `
            <ol>
                <li>Materials that can be used as a company guide</li>
                <li>Tools that can be used directly by the company</li>
            </ol>
        `,
    targetAudience: `
            <ol>
                <li>CSR practitioners</li>
                <li>Lecturers who are conducting Community Service activities</li>
                <li>General Managers</li>
                <li>Community Relations Managers</li>
                <li>Community Development Managers</li>
                <li>Public Relations Managers</li>
                <li>Other practitioners who are interested in effectiveness of monitoring community projects or any other project</li>
            </ol>
        `,
    investment: `
            <p>
                Two-day (2) workshop: IDR 5.500.000 (online)
            </p>
            <strong>Packages for organisations' group:</strong>
            <ul>
                <li>IDR 16.000.000 for 3 participants</li>
                <li>IDR 26.500.000 for 5 participants</li>
                <li>IDR 52.000.000 for 10+ participants</li>
            </ul>
        `,
    methodOfTraining: `
            <ul>
                <li>
                    <strong>Method of training:</strong> Zoom Call Application
                </li>
                <li>
                    <strong>How to register:</strong>
                    <ol>
                        <li>Fill in registration form in the link bellow</li>
                        <li>Once submitted, you will receive confirmation email for the next steps</li>
                    </ol>
                </li>
            </ul>
            <strong>
                For further information, please contact:
            </strong>
            <br />
            <br />
            <span>WhatsApp: </span>
            <div className="contactWhatsAppButton">
                <a href="https://api.whatsapp.com/send/?phone=6281218467661&text&app_absent=0" target="_blank" rel="noreferrer">
                    +6281218467661 (Billy)
                </a>
            </div>
            <br />
            <span>
                Email: &nbsp;
                <a className="academyEmail" href="mailto:isa-academy@sustainability.co.id">
                    isa-academy@sustainability.co.id
                </a>
            </span>
            <br />
            <br />
        `,
    registrationLink: "http://bit.ly/ISASROI1",
  },
  {
    id: 1,
    picture:
      "https://cdn.discordapp.com/attachments/796711355876245534/996271935879127040/Card_event_raUSO8no6.webp",
    title:
      "ESG, ISO 26000, CSV (Creating Shared Value) and SDGs: its relations, standards, and strategies for business Sustainability",
    htmlTitle:
      "<span>ESG, ISO 26000, CSV (Creating Shared Value) and SDGs: <i style='color: black;'>its relations, standards, and strategies for business Sustainability</i></span>",
    htmlTitleNoColor:
      "<span>ESG, ISO 26000, CSV (Creating Shared Value) and SDGs: <i>its relations, standards, and strategies for business Sustainability</i></span>",
    date: "9-10 August 2022  |  09.00 - 15.00 WIB",
    description:
      "Good ESG understanding will lead to good ESG performance, and that will lead to more appealing shares for investors and rating agencies. So to boost the company's ESG performance and their reputation in the eyes of stakeholders, it is crucial that a company fully grasps the concept of ESG.",
  },
  {
    id: 2,
    picture:
      "https://cdn.discordapp.com/attachments/796711355876245534/996271935879127040/Card_event_raUSO8no6.webp",
    title:
      "ESG, ISO 26000, CSV (Creating Shared Value) and SDGs: its relations, standards, and strategies for business Sustainability",
    htmlTitle:
      "<span>ESG, ISO 26000, CSV (Creating Shared Value) and SDGs: <i style='color: black;'>its relations, standards, and strategies for business Sustainability</i></span>",
    htmlTitleNoColor:
      "<span>ESG, ISO 26000, CSV (Creating Shared Value) and SDGs: <i>its relations, standards, and strategies for business Sustainability</i></span>",
    date: "6-7 September 2022  |  09.00 - 15.00 WIB",
    description:
      "Good ESG understanding will lead to good ESG performance, and that will lead to more appealing shares for investors and rating agencies. So to boost the company's ESG performance and their reputation in the eyes of stakeholders, it is crucial that a company fully grasps the concept of ESG.",
  },
];

// Countries
export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
