// Axios
import Axios from "./axios"

export const addNews = (value) => {
    return new Promise((resolve, reject) => {
        const data = Axios.post(`/news`, value, {
            headers: {
                'access-token': localStorage.getItem("access_token")
            }
        })
        setTimeout(() => resolve(data), 1000)
    })

}