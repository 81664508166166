import React, { useEffect, useState } from "react";

// React DOM
import { useNavigate } from "react-router-dom";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// Icons & Backgrounds
import CompanyBanner from "../../images/background/company.png";
import ArrowRightLearnMore from "../../images/icons/ArrowRightLearnMore.png";
import TemporaryContentFounderStory1 from "../../images/dummy/TemporaryContentFounderStory1.jpeg";
import TemporaryContentFounderStory2 from "../../images/dummy/TemporaryContentFounderStory2.jpeg";

// CSS styling
import "../../styles/about.css";

// Components
import ContainerText from "../../components/Container";
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";

// Apis
import { fetchFounderStories } from "../../api/founderStories.js";

const FoundersStory = () => {
  const navigate = useNavigate();
  const [founderStories, setFounderStories] = useState([
    {
      id: 1,
      title: "Maria Rosaline Nindita Radyati : CSR dan Distribusi Keuntungan",
      input_summary:
        "Di Indonesia, CSR sering salah dipahami. Kalau dijalankan dengan baik, CSR mampu berperan besar dalam mengentaskan kemiskinan. Kepedulian sosial telah menjadi satu bagian penting dari setiap perusahaan. Corporate Social Resposibility (CSR) adalah satu...",
      FounderStoryTags: [{ id: 1, Tag: { tag_name: "Article" } }],
      FounderStoryImages: [
        {
          img_url: TemporaryContentFounderStory1,
        },
        {
          img_url: TemporaryContentFounderStory2,
        },
      ],
    },
    {
      id: 2,
      title: "Alumnus Australia Ini Kembangkan Pendidikan S-2 Khusus CSR",
      input_summary:
        "Mengajar telah menjadi passion dari alumni Australia yang satu ini. Mulai dari iseng mengajarkan boneka di rumah ketika kecil, menjadi asisten dosen ketika kuliah, hingga akhirnya berhasil mendirikan satu-satunya Program Studi Magister di Indonesia dan Asia...",
      FounderStoryTags: [{ id: 1, Tag: { tag_name: "Article" } }],
      FounderStoryImages: [
        {
          img_url: TemporaryContentFounderStory2,
        },
        {
          img_url: TemporaryContentFounderStory1,
        },
      ],
    },
    {
      id: 3,
      title:
        "Menelaah Kembali Konsep Corporate Social Responsibility (CSR) bersama Ibu Maria Nindita Radyati",
      input_summary:
        "Halo…, Sebenarnya, waktu jaman kuliah dulu dan awal ngeblog, sempat share tentang materi-materi kuliah. Nah, berhubung saya sudah kerja dan mulai nge-blog lagi, pengennya bisa posting-posting materi yang terkait sama pekerjaan, atau sharing ilmu yang...",
      FounderStoryTags: [{ id: 1, Tag: { tag_name: "Article" } }],
      FounderStoryImages: [
        {
          img_url: TemporaryContentFounderStory1,
        },
        {
          img_url: TemporaryContentFounderStory2,
        },
      ],
    },
  ]);
  const [isMobileSize, setIsMobileSize] = useState(false);
  const [isTabSize, setIsTabSize] = useState(false);
  const mediaQueryMobile = window.matchMedia("(max-width: 640px)");
  const mediaQueryTab = window.matchMedia("(max-width: 910px)");

  const fetchAllFounderStories = async () => {
    const response = await fetchFounderStories();
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      setFounderStories(data);
    }
  };

  const handleDetectWindowSize = (mediaQuery, tab) => {
    if (tab === "mobile") {
      setIsMobileSize(mediaQuery.matches);
    } else {
      setIsTabSize(mediaQuery.matches);
    }
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  // useEffect(() => {
  //   fetchAllFounderStories();
  // }, []);

  useEffect(() => {
    if (mediaQueryMobile) {
      handleDetectWindowSize(mediaQueryMobile, "mobile");
    }
  }, [mediaQueryMobile]);

  useEffect(() => {
    if (mediaQueryTab) {
      handleDetectWindowSize(mediaQueryTab, "tab");
    }
  }, [mediaQueryTab]);

  return (
    <div>
      <Header />
      <div id="company">
        <div className="companyMainContainer">
          <div className="companyBanner">
            <img src={CompanyBanner} alt="company" />
            <ContainerText
              props={
                <div className="companyBannerText">
                  <div className="companyBannerTitle">
                    <p>Founder's Story</p>
                  </div>
                  <div className="companyBannerSubtitle">
                    <p>
                      This page is dedicated to the story of our Founder &
                      President Director, Dr. Maria Rosaline Nindita Radyati.
                      Contained here are various publications from different
                      media outlets that describe the vocation and outputs of
                      Dr. Maria Radyati. We hope that this can give you an image
                      of the experience that gained over many years, prior to
                      the founding of ISA.
                    </p>
                  </div>
                </div>
              }
            />
          </div>
          {founderStories &&
          Array.isArray(founderStories) &&
          founderStories.length > 0 ? (
            <div style={{ marginTop: "2em" }}>
              {founderStories.map((founderStory) => {
                const {
                  id,
                  FounderStoryImages,
                  FounderStoryTags,
                  input_summary,
                  title,
                } = founderStory || {};
                let founderStoryCoverImage = "";
                if (
                  FounderStoryImages &&
                  Array.isArray(FounderStoryImages) &&
                  FounderStoryImages.length > 0
                ) {
                  founderStoryCoverImage = FounderStoryImages[0].img_url;
                }

                return (
                  <ContainerText
                    key={id}
                    props={
                      <div className="testimonialMembersContainer">
                        <div
                          className="eachMembers"
                          style={{ maxWidth: "100vw" }}
                        >
                          <div
                            className="eachMembersDescContainer"
                            style={{
                              maxWidth: "100vw",
                            }}
                          >
                            <div
                              style={{
                                maxWidth: isMobileSize
                                  ? "100vw"
                                  : isTabSize
                                  ? "50vw"
                                  : "33vw",
                                marginRight: isMobileSize
                                  ? "0"
                                  : isTabSize
                                  ? "4.5vw"
                                  : "3vw",
                              }}
                            >
                              <div
                                className="eachMembersPicture"
                                style={{
                                  maxHeight: "50vh",
                                  maxWidth: isMobileSize
                                    ? "100vw"
                                    : isTabSize
                                    ? "50vw"
                                    : "30vw",
                                }}
                              >
                                <img
                                  src={founderStoryCoverImage}
                                  alt={"founder-story-image"}
                                  style={{
                                    width: isMobileSize
                                      ? "100vw"
                                      : isTabSize
                                      ? "50vw"
                                      : "30vw",
                                    height: "50vh",
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: isMobileSize ? "2vh" : "0",
                                maxWidth: "100vw",
                              }}
                            >
                              <div>
                                {FounderStoryTags &&
                                Array.isArray(FounderStoryTags) &&
                                FounderStoryTags.length > 0 ? (
                                  <div style={{ display: "flex", gap: "1em" }}>
                                    {FounderStoryTags.map((tag) => {
                                      const { id, Tag: { tag_name } = {} } =
                                        tag || {};
                                      return (
                                        <div key={id}>
                                          <div
                                            style={{
                                              color: "#FF9F1E",
                                              fontSize: isTabSize
                                                ? "16px"
                                                : "20px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {tag_name}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : null}
                                {title ? (
                                  <div
                                    className="founderStoryCardTitle"
                                    style={{
                                      marginTop: "0",
                                      maxWidth: isMobileSize
                                        ? "100vw"
                                        : "43.5vw",
                                    }}
                                    onClick={() =>
                                      navigate(`/Founders-story/${id}`)
                                    }
                                  >
                                    <a
                                      href="#"
                                      style={{
                                        fontSize: isMobileSize
                                          ? "25px"
                                          : isTabSize
                                          ? "28px"
                                          : "35px",
                                        color: "#0D4C86",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {title}
                                    </a>
                                  </div>
                                ) : null}
                                {input_summary ? (
                                  <div
                                    className="containerDesc"
                                    style={{
                                      marginTop: "1em",
                                      marginLeft: "0",
                                      maxWidth: isMobileSize
                                        ? "100vw"
                                        : "43.5vw",
                                      textAlign: "justify",
                                    }}
                                  >
                                    <div
                                      className="eachMembersDesc"
                                      style={{
                                        fontSize:
                                          isTabSize || isMobileSize
                                            ? "16px"
                                            : "20px",
                                      }}
                                    >
                                      <p>{input_summary}</p>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className="learnMoreContainer"
                                onClick={() =>
                                  navigate(`/Founders-story/${id}`)
                                }
                                style={{ marginTop: "1em" }}
                              >
                                <div>Click to read more</div>
                                <div>
                                  <img
                                    src={ArrowRightLearnMore}
                                    style={{
                                      width: isMobileSize
                                        ? "3.7vw"
                                        : isTabSize
                                        ? "1.5vw"
                                        : "1vw",
                                      height: "auto",
                                      marginLeft: isMobileSize ? "2vw" : "",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FoundersStory;
