import React, { useEffect, useState } from "react";

// React Navigation
import { useLocation, useNavigate } from "react-router-dom";

// React Burger Menu
import { slide as Menu } from "react-burger-menu";

// CSS styling
import "../styles/home.css";

// Icons, Background, Card, and Logo
import ISAlogo from "../images/companyLogo/ISA-Logo.png";
import ISAmobile from "../images/icons/ISA.png";
import DummyPerson from "../images/icons/dummyPerson.png";
import TwitterLogo from "../images/icons/TwitterLogo.png";
import FacebookLogo from "../images/icons/FacebookLogo.png";
import InstagramLogo from "../images/icons/InstagramLogo.png";
import LinkedInLogo from "../images/icons/LinkedInLogo.png";
import YoutubeLogo from "../images/icons/YoutubeLogo.png";

// Components
import ContainerText from "./Container";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // const [burger, setBurger] = useState(false)

  const changeBurgerColor = () => {
    if (pathname === "/") {
      if (window.scrollY >= 100) {
        document.getElementsByClassName("navigationMobile")[0].style.height =
          "100px";
        document.getElementsByClassName(
          "bm-burger-bars"
        )[0].style.backgroundColor = "black";
        document.getElementsByClassName(
          "bm-burger-bars"
        )[1].style.backgroundColor = "black";
        document.getElementsByClassName(
          "bm-burger-bars"
        )[2].style.backgroundColor = "black";
      } else {
        document.getElementsByClassName("navigationMobile")[0].style.height =
          "0px";
        document.getElementsByClassName(
          "bm-burger-bars"
        )[0].style.backgroundColor = "white";
        document.getElementsByClassName(
          "bm-burger-bars"
        )[1].style.backgroundColor = "white";
        document.getElementsByClassName(
          "bm-burger-bars"
        )[2].style.backgroundColor = "white";
      }
    } else {
      document.getElementsByClassName("navigationMobile")[0].style.height =
        "100px";
      document.getElementsByClassName(
        "bm-burger-bars"
      )[0].style.backgroundColor = "black";
      document.getElementsByClassName(
        "bm-burger-bars"
      )[1].style.backgroundColor = "black";
      document.getElementsByClassName(
        "bm-burger-bars"
      )[2].style.backgroundColor = "black";
    }
  };

  useEffect(() => {
    changeBurgerColor();
    window.addEventListener("scroll", changeBurgerColor);
  }, [pathname]);

  return (
    <header id="navigationBar">
      <div className="navigationBarMainContainer">
        <ContainerText
          props={
            <div>
              <div className="navigationBarContainer bigScreen">
                <div className="navigationBarLogoContainer">
                  <img
                    className="navigationBarLogo"
                    src={ISAlogo}
                    alt="ISA"
                    onClick={() => navigate("/")}
                  />
                </div>
                <div className="navigationBarMenuContainer navLink">
                  <ul className="list-group list-group-horizontal">
                    <div className="navigationBarAbout">
                      <li
                        className="list-group-item bg-transparent"
                        style={{ width: "7.5em" }}
                      >
                        <a href="#">About us</a>
                        <span className="angleDropdown">
                          <i className="fas fa-angle-down" />
                        </span>
                      </li>
                      <div className="aboutUsList">
                        <ul className="list-group list-group-vertical border-0">
                          <li
                            className="list-group-item bg-transparent border-0 d-none"
                            onClick={() => navigate(`/About/TheFounder`)}
                          >
                            <p>Founder</p>
                          </li>
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/About/Company`)}
                            style={{ justifyContent: "left" }}
                          >
                            <p>Our Company</p>
                          </li>
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/About/TeamMembers`)}
                            style={{ justifyContent: "left" }}
                          >
                            <p>Team Members</p>
                          </li>
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/Founders-story`)}
                            style={{ display: "flex", justifyContent: "left" }}
                          >
                            <p>Founder's Story</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ width: "11em" }}
                      onClick={() => navigate(`/Expertise`)}
                    >
                      <a href="#">ISA Consulting</a>
                    </li>
                    <div className="navigationBarAbout">
                      <li
                        className="list-group-item bg-transparent"
                        style={{ width: "10.5em" }}
                      >
                        <a href="#">ISA Academy</a>
                        <span className="angleDropdown">
                          <i className="fas fa-angle-down" />
                        </span>
                      </li>
                      <div className="aboutUsList">
                        <ul className="list-group list-group-vertical border-0">
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/Diploma-PM-for-ESG`)}
                            style={{ display: "flex", justifyContent: "left" }}
                          >
                            <p>Diploma PM for ESG</p>
                          </li>
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/Academy`)}
                            style={{ justifyContent: "left" }}
                          >
                            <p>Webinars and workshops</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="navigationBarAbout">
                      <li
                        className="list-group-item bg-transparent"
                        style={{ width: "9.5em" }}
                      >
                        <a href="#">Our Media</a>
                        <span className="angleDropdown">
                          <i className="fas fa-angle-down" />
                        </span>
                      </li>
                      <div className="aboutUsList">
                        <ul className="list-group list-group-vertical border-0">
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/Activities`)}
                            style={{ display: "flex", justifyContent: "left" }}
                          >
                            <p>Activities</p>
                          </li>
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/Blog`)}
                            style={{ justifyContent: "left" }}
                          >
                            <p>Articles</p>
                          </li>
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/Books`)}
                            style={{ justifyContent: "left" }}
                          >
                            <p>Books</p>
                          </li>
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/Learning-videos`)}
                            style={{ justifyContent: "left" }}
                          >
                            <p>Learning Videos</p>
                          </li>
                          <li
                            className="list-group-item bg-transparent border-0"
                            onClick={() => navigate(`/Diploma-PM-for-ESG`)}
                            style={{ justifyContent: "left" }}
                          >
                            <p>infographics</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ width: "15em" }}
                    >
                      <a href="https://www.cloudxier.com/" target="_blank">
                        IT Consultancy Services
                      </a>
                    </li>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ width: "9.5em" }}
                      onClick={() => navigate(`/Clients`)}
                    >
                      <a href="#">Our Clients</a>
                    </li>
                    <li className="list-group-item bg-transparent d-none">
                      Resources
                    </li>
                    <li
                      className="list-group-item bg-transparent"
                      style={{ width: "9.5em" }}
                      onClick={() => navigate(`/Contact`)}
                    >
                      <a href="#">Contact us</a>
                    </li>
                  </ul>
                </div>
                <div className="navigationBarLoginContainer d-none">
                  <ul className="list-group list-group-horizontal">
                    <li className="list-group-item bg-transparent border-0">
                      <i className="navigationBarSearch" />
                    </li>
                    <li className="list-group-item bg-transparent border-0">
                      <img
                        className="navigationBarDummy"
                        src={DummyPerson}
                        alt="dummy"
                      />
                    </li>
                    <div className="navigationBarLogin">
                      <li className="list-group-item bg-transparent border-0">
                        Login
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          }
        />
        <div className="fullWidthNavbarFollowUsOnSocialMedia">
          <div className="navbarFollowUsOnSocialMediaContainer">
            <div className="followUsOnSMContainer">
              FOLLOW US ON OUR SOCIAL MEDIA
            </div>
            <div className="navbarFollowUsOnSocialMediaImgContainer">
              <a
                href="https://twitter.com/isa_company"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TwitterLogo} alt="" />
              </a>
              <a
                href="https://www.facebook.com/isa.sustainability"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FacebookLogo} alt="" />
              </a>
              <a
                href="https://www.instagram.com/isa.sustainability"
                target="_blank"
                rel="noreferrer"
              >
                <img src={InstagramLogo} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/institute-for-sustainability-and-agility/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedInLogo} alt="" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCWsQCHf9PodPl-_V_GaYorg/featured"
                target="_blank"
                rel="noreferrer"
              >
                <img src={YoutubeLogo} alt="" />
              </a>
            </div>
          </div>
        </div>
        <ContainerText
          props={
            <div className="navigationMobile smallScreen">
              <div>
                <img src={ISAmobile} alt="ISA" onClick={() => navigate("/")} />
              </div>
              <div>
                <Menu noOverlay={false} right className="burgerMenuStyle">
                  <a className="menu-item" href="/">
                    Home
                  </a>
                  <a
                    className="menu-item"
                    style={{ marginBottom: "10px" }}
                    href="#"
                  >
                    About us
                  </a>
                  <a
                    className="menu-item"
                    style={{ marginBottom: "10px", fontSize: "0.8em" }}
                    href="#"
                    onClick={() => navigate(`/About/Company`)}
                  >
                    Our company
                  </a>
                  <a
                    className="menu-item"
                    style={{ fontSize: "0.8em" }}
                    href="#"
                    onClick={() => navigate(`/About/TeamMembers`)}
                  >
                    Team Members
                  </a>
                  <a
                    className="menu-item"
                    href="#"
                    onClick={() => navigate(`/Expertise`)}
                  >
                    Expertise
                  </a>
                  <a
                    className="menu-item"
                    style={{ marginBottom: "10px" }}
                    href="#"
                  >
                    ISA Academy
                  </a>
                  <a
                    className="menu-item"
                    style={{ marginBottom: "10px", fontSize: "0.8em" }}
                    href="#"
                    onClick={() => navigate(`/Diploma-PM-for-ESG`)}
                  >
                    Diploma PM for ESG
                  </a>
                  <a
                    className="menu-item"
                    style={{ fontSize: "0.8em" }}
                    href="#"
                    onClick={() => navigate(`/Academy`)}
                  >
                    Webinars and workshops
                  </a>
                  <a
                    className="menu-item"
                    style={{ marginBottom: "10px" }}
                    href="#"
                  >
                    Our Media
                  </a>
                  <a
                    className="menu-item"
                    style={{ marginBottom: "10px", fontSize: "0.8em" }}
                    href="#"
                    onClick={() => navigate(`/Activities`)}
                  >
                    Activities
                  </a>
                  <a
                    className="menu-item"
                    style={{ marginBottom: "10px", fontSize: "0.8em" }}
                    href="#"
                    onClick={() => navigate(`/Blog`)}
                  >
                    Articles
                  </a>
                  <a
                    className="menu-item"
                    style={{ marginBottom: "10px", fontSize: "0.8em" }}
                    href="#"
                    onClick={() => navigate(`/Books`)}
                  >
                    Books
                  </a>
                  <a
                    className="menu-item"
                    style={{ marginBottom: "10px", fontSize: "0.8em" }}
                    href="#"
                    onClick={() => navigate(`/Learning-videos`)}
                  >
                    Learning Videos
                  </a>
                  <a
                    className="menu-item"
                    style={{ fontSize: "0.8em" }}
                    href="#"
                    onClick={() => navigate(`/Diploma-PM-for-ESG`)}
                  >
                    infographics
                  </a>
                  <a
                    className="menu-item"
                    href="https://www.cloudxier.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    IT Consultancy Services
                  </a>
                  <a
                    className="menu-item"
                    href="#"
                    onClick={() => navigate(`/Clients`)}
                  >
                    Our Clients
                  </a>
                  <a
                    className="menu-item"
                    href="#"
                    onClick={() => navigate(`/Contact`)}
                  >
                    Contact us
                  </a>
                </Menu>
              </div>
            </div>
          }
        />
      </div>
    </header>
  );
};

export default Header;
