// Axios
import Axios from "./axios"

export const fetchNews = (status, type, date, customDate) => {
    return new Promise((resolve, reject) => {
        const data = Axios.get(`/news`, {
            params: {
                statusSelected: status,
                contentSelected: type,
                dateSelected: date,
                customDate: customDate,
                dateEnd: customDate
            }
        })
        setTimeout(() => resolve(data), 0)
    })

}