
// CSS Styling
import "../styles/blog.css";

const BlogButton = ({ onClickButton, icon, iconDesc, iconWidth, text, buttonPadding }) => {
    return(
        <div className="blogFunctionButtonContent">
            <button onClick={onClickButton} style={{padding: buttonPadding}}>
                <img src={icon} alt={iconDesc} style={{width: iconWidth}}/> {text}
            </button>
        </div>
    )
}

export default BlogButton