import React from "react";

// React DOM
import { useNavigate, useParams } from "react-router-dom";

// Components
import ActivitiesTable from "./ActivitiesTable";
import ActivitiesForm from "./ActivitiesForm";

const Activities = (props) => {
  const {
    isLoading,
    setLoading,
    activities,
    setShowAlertWorkshop,
    setWorkshopAlertMsg,
    fetchAllActivities,
  } = props;
  const { crud } = useParams();
  const navigate = useNavigate();
  const { editId } = useParams();

  if (crud) {
    return (
      <ActivitiesForm
        navigate={navigate}
        setShowAlertWorkshop={setShowAlertWorkshop}
        setWorkshopAlertMsg={setWorkshopAlertMsg}
        fetchAllActivities={fetchAllActivities}
        editId={editId}
      />
    );
  } else {
    return (
      <ActivitiesTable
        navigate={navigate}
        isLoading={isLoading}
        fetchAllActivities={fetchAllActivities}
        setLoading={setLoading}
        activities={activities}
      />
    );
  }
};

export default Activities;
