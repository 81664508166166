import React, { Component } from 'react';

// React Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// CSS styling
import "../styles/home.css";

// Images and Icons
import ArrowLeft from "../images/icons/ArrowLeft.png"
import Certification from "../images/icons/Certification.png"
import Certification1 from "../images/icons/Certification1.png"
import Filter from "../images/icons/Filter.png"

// Database
import { events } from '../utilities/content';

const items = events


class PaginatedItems extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };
        return (
            <div>
                <div className="eventsController">
                    <div className="eventsControllerFilter">
                        <button type="button" className="btn">
                                <img src={Filter} alt="filter"/>
                                Filter
                        </button>
                    </div>
                    <div className="eventsControllerPagination">
                        <div className="eventsControllerPaginationLink">
                            <a href="#">
                                Show all {items.length}
                            </a>
                        </div>
                        <div className="eventsControllerPaginationArrowLeft" onClick={this.previous}><img src={ArrowLeft} alt="arrowLeft"/></div>
                        <div className="eventsControllerPaginationArrowRight" onClick={this.next}><img src={ArrowLeft} alt="arrowRight"/></div>
                    </div>
                </div>
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {items.map((item) => (
                        <div className="eventsPagination" key={item.id}>
                            <div className="eventsEachCard">
                                <div className="eventsEachCardImageContainer">
                                    <img src={item.image.toString()} alt=""></img>
                                </div>
                                <div className="eventsEachCardName">
                                    <p>
                                        {item.title.toString()}
                                    </p>
                                    <button type="button" className="btn">{item.type.toString()}</button>
                                </div>
                                <div className="eventsEachCardSubtitle">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                    </p>
                                </div>
                                <div className="eventsEachCardReviews">
                                    <div className="eventsEachCardReviewsImage">
                                        <img src={Certification} alt="certification"/>
                                    </div>
                                    <div className="eventsEachCardReviewsImage">
                                        <img src={Certification1} alt="certification1"/>
                                    </div>
                                    <div className="eventsEachCardReviewsLink">
                                        <a href='#'>
                                            See reviews {item.reviews.toString()}
                                        </a>
                                    </div>
                                </div>
                                <div className="eventsEachCarButton">
                                    <button type="button" className="btn btn-warning">
                                        See details
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}

export default PaginatedItems