import { useState } from "react";

// React DOM
import { useNavigate } from "react-router-dom";

// API
import { logoutUser } from "../api/logoutUser";

// Icons
import notificationAdmin from "../images/icons/admin/notificationAdmin.png";
import profileAdmin from "../images/icons/admin/profileAdmin.png";

// CSS Styling
import "../styles/admin.css";

// Components
import SearchBar from "./SearchBar";

// Sweet Alert v2
import Swal from "sweetalert2";

const AdminNavigation = ({ customValue, workshops, testimonials, params }) => {
  const [buttonStatus, setButtonStatus] = useState(false);

  const email = localStorage.getItem("email");
  const navigate = useNavigate();

  // Handle category and status change button on click
  const handleButton = (className) => {
    if (!buttonStatus) {
      document.getElementsByClassName(className)[0].style.visibility = "unset";
      setButtonStatus(!buttonStatus);
    } else {
      document.getElementsByClassName(className)[0].style.visibility = "hidden";
      setButtonStatus(!buttonStatus);
    }
  };

  const logout = () => {
    logoutUser(email)
      .then((res) => {
        localStorage.removeItem("isVerified");
        localStorage.removeItem("access_token");
        localStorage.removeItem("email");
        navigate("/signIn");
      })
      .catch((err) => {
        const { message } = err.response.data || {};
        if (
          message &&
          (message === "Unauthorized" ||
            message === "Unauthenticated" ||
            message === "TokenExpiredError")
        ) {
          Swal.fire({
            position: "top-end",
            text: "Token expired, please re-login",
            showConfirmButton: false,
            timer: 1500,
            backdrop: false,
            width: "450px",
          });
          localStorage.removeItem("isVerified");
          localStorage.removeItem("access_token");
          localStorage.removeItem("email");
          navigate("/signIn");
        }
      });
  };

  return (
    <div className="adminTopNavigation">
      <div className="adminTopNavigationContainer">
        <div className="adminSearch">
          <SearchBar
            selectedValue={customValue}
            workshops={workshops}
            testimonials={testimonials}
            params={params}
          />
        </div>
        <div className="adminProfile">
          {/* <span className=""><img src={notificationAdmin} alt="notification"/></span> */}
          <span
            className="profileIcon"
            onClick={() => handleButton("profileDropdown")}
          >
            <img src={profileAdmin} alt="profile" />
            <div className="profileDropdown" onClick={() => logout()}>
              <p>Logout</p>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AdminNavigation;
