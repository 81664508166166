import axios from "axios";
import { getAPIUrl, isStaging } from '../helpers/common'

const staging = "https://api-isa-stg.cloudxier.com";

const localHost = "http://localhost:3000";

const url = isStaging() ? staging : localHost;

const Axios = axios.create({
    baseURL: getAPIUrl()
})  

export default Axios;
