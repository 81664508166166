import { useEffect, useState } from "react";

// React DOM
import { useNavigate, useParams } from "react-router-dom";

// Icons
import ISAlogo from "../../images/icons/ISA.png"
import PasswordLogin from "../../images/icons/PasswordLogin.png"
import EyePasswordHide from "../../images/icons/EyePasswordHide.png"
import EyePasswordShow from "../../images/icons/EyePasswordShow.png"

// CSS Styling
import "../../styles/loginPage.css";

// React Form
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

// Components
import ContainerText from "../../components/Container";
import Loader from "../../components/Loader";

// API
import { sendTokenReset } from "../../api/sendTokenReset";
import { verifyResetPassword } from "../../api/verifyResetPassword";

// Sweet alert
import Swal from "sweetalert2";

const ResetPassword = () => {

    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: "all"
    })
    const navigate = useNavigate()
    const { encryptedEmail } = useParams()

    const [showPassword, setShowPassword] = useState("password");
    const [isForgetPass, setForgetPass] = useState(false)
    const [tokenPass, setTokenPass] = useState("")
    const [emailUser, setEmailUser] = useState("")
    const [isLoading, setLoading] = useState(false)

    const handleEyePassword = () => {
        if (showPassword === "password") {
            setShowPassword("text")
        } else {
            setShowPassword("password")
        }
    }

    const onSubmit = async (values) => {
        const { newPassword, retypePassword } = values
        try {
            setLoading(true)
            if (tokenPass !== "" && tokenPass !== null && tokenPass !== undefined && isForgetPass) {
                if (newPassword === retypePassword) {
                    const result = await verifyResetPassword(emailUser, {
                        password: newPassword
                    })
                    if (result) {
                        setForgetPass(false)
                        Swal.fire(
                            `Success`,
                            `${result.data}`,
                            `success`
                        )
                    } else {
                        Swal.fire(
                            `Oops..`,
                            `Something error happened. Please contact our team`,
                            `error`
                        )
                    }
                } else {
                    Swal.fire(
                        `Oops..`,
                        `Password not match`,
                        `error`
                    )
                }
            } else {
                Swal.fire(
                    `Oops..`,
                    `You've already reset your password`,
                    `error`
                )
            }
        } catch (err) {
            Swal.fire(
                `Oops..`,
                `Something error happened. Please contact our team [500]`,
                `error`
            )
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        sendTokenReset(encodeURIComponent(encryptedEmail))
        .then((res) => {
            setEmailUser(res.data.email)
            setForgetPass(res.data.isForgetPass)
            setTokenPass(res.data.token_password)
        })
        .catch((err) => {
            Swal.fire(
                `Oops..`,
                `You're not authorized`,
                `error`
            )
            .then(() => navigate("/signIn"))
        })
    }, [isForgetPass])

    return(
        <div id="resetPassword">
            <div className="loginPageContainer">
                <ContainerText props={
                    <div className="loginFormContainer">
                        <div className="loginFormImage">
                            <img src={ISAlogo} alt="ISA Logo"/>
                        </div>
                        <div className="loginFormTitle">
                            <p>Reset your password</p>
                        </div>
                        <div className="loginForm">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="loginFormInput">
                                    <label> <img src={PasswordLogin} alt="Password Icon"/>Password</label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="newPassword"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                    <input name="newPassword" type={showPassword} className="form-control" placeholder="Enter your new password" {...register('newPassword', { required: "This field is required", 
                                    pattern: {
                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
                                        message: "password must contain at least one letter, one number and one special character"
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "password at least must have 8 characters"
                                    } })} style={{marginBottom: "0px"}}></input>
                                    <span className="loginEyePassword" onClick={handleEyePassword}><img src={showPassword === "password" ? EyePasswordHide : EyePasswordShow} alt="Eye Password"/></span>
                                </div>
                                <div className="loginFormInput">
                                    <label> <img src={PasswordLogin} alt="Password Icon"/>Password</label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="retypePassword"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                    <input name="retypePassword" type={showPassword} className="form-control" placeholder="Retype your new password" {...register('retypePassword', { required: "This field is required"})} style={{marginBottom: "0px"}}></input>
                                    <span className="loginEyePassword" onClick={handleEyePassword}><img src={showPassword === "password" ? EyePasswordHide : EyePasswordShow} alt="Eye Password"/></span>
                                </div>
                                <button className="btn" type="submit">
                                    Sign in
                                </button>
                            </form>
                            { 
                                isLoading ?
                                <span style={{display: "flex", justifyContent: "center", marginBottom: "1em"}}>
                                    <Loader
                                    type="spin"
                                    color="black"
                                    height="25px"
                                    width="25px"
                                    />
                                </span>
                                :
                                null
                            }
                        </div>
                    </div>
                }/> 
            </div>
        </div>
    )
}

export default ResetPassword