// Axios
import Axios from "./axios"

export const getOldestNews = (contentSelected) => {
    return new Promise((resolve, reject) => {
        const data = Axios.get(`/news/outdatedLimit`, {
            params: {
                contentSelected: contentSelected
            }
        })
        setTimeout(() => resolve(data), 1000)
    })

}