// Axios
import Axios from "./axios"

export const getUserByEmail = (email) => {
    return new Promise((resolve, reject) => {
        const data = Axios.get(`/user/findUser/${email}`, {
            headers: {
                'access-token': localStorage.getItem("access_token")
            }
        })
        setTimeout(() => resolve(data), 0)
    })
}