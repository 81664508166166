// Axios
import Axios from "./axios"

export const deleteMultipleNews = (news) => {
    return new Promise((resolve, reject) => {
        const data = Axios.delete(`/news/delete/multiple`, {
            headers: {
                'access-token': localStorage.getItem("access_token")
            },
            params: {
                deletedNews: news
            }
        })
        setTimeout(() => resolve(data), 0)
    })

}