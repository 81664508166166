// Axios
import Axios from "./axios";

export const deleteActivity = (activityId) => {
  return new Promise((resolve, reject) => {
    const data = Axios.delete(`/activities/delete/${activityId}`, {
      headers: {
        "access-token": localStorage.getItem("access_token"),
      },
    });
    resolve(data);
  });
};
