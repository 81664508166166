// Axios
import Axios from "./axios";

export const editActivity = (activityId, body) => {
  return new Promise((resolve, reject) => {
    const data = Axios.put(`/activities/edit/${activityId}`, body, {
      headers: {
        "access-token": localStorage.getItem("access_token"),
      },
    });
    resolve(data);
  });
};
