import React from "react";

const InputUploadImages = (props) => {
  const {
    firstInput,
    counter,
    images,
    addMoreImageInput,
    addMoreIcon,
    onImageChange,
    uploadImageBtn,
    imageEmpty,
    iconChangeImage,
    handleDeleteImage,
    deleteIcon,
  } = props;

  return (
    <div key={counter} className="workshopInputContainer">
      <div className="labelForWorkshopInput">
        <div className="eachLabel">{`Upload image ${counter + 1}`}</div>
        {firstInput ? (
          <div className="btnAddMoreInput" onClick={() => addMoreImageInput()}>
            <img
              src={addMoreIcon}
              alt="add more icon"
              width={15}
              height={15}
            ></img>
            <div style={{ marginLeft: "7.5px" }}>Add more image</div>
          </div>
        ) : null}
      </div>
      <div className="adminPostInput">
        <div
          className={
            images[counter] && images[counter]["image"]
              ? "inputImageContainerNoPointer"
              : "inputImageContainer"
          }
          onClick={() =>
            images[counter] && images[counter]["image"]
              ? null
              : document.querySelector(`.input-image-field${counter}`).click()
          }
        >
          <input
            type="file"
            accept="image/*"
            className={`input-image-field${counter}`}
            hidden
            onChange={({ target: { files } }) =>
              onImageChange(files, counter, "image")
            }
          />
          <div className="imageToViewContainer">
            <img
              src={
                images[counter] && images[counter]["image"]
                  ? images[counter] && images[counter]["image"]
                  : uploadImageBtn
              }
              style={
                images[counter] && images[counter]["image"]
                  ? { maxWidth: 250, maxHeight: 175 }
                  : { maxWidth: 35, maxHeight: 35 }
              }
              alt="coverImage"
            ></img>
            {images[counter] &&
            !images[counter]["failedToUpload"] &&
            images[counter]["fileName"] ? (
              <div className="imageFileNameFontStyle">
                {images[counter]["fileName"]}
              </div>
            ) : null}
            {images[counter] &&
              images[counter]["failedToUpload"] &&
              images[counter]["failedToUpload"] !== "" && (
                <div className="errorFailedToUpload">
                  <p>{images[counter]["failedToUpload"]}</p>
                </div>
              )}
          </div>
          {images[counter] && images[counter]["image"] && (
            <div>
              <div
                className="btnChangeImage"
                onClick={() =>
                  document.querySelector(`.input-image-field${counter}`).click()
                }
              >
                <img
                  src={iconChangeImage}
                  alt="iconChangeImage"
                  width={17}
                  height={17}
                ></img>
                <div className="changeImageLabel">Change image</div>
              </div>
              <div
                className="btnChangeImage"
                onClick={() => handleDeleteImage(images[counter]['image'])}
                style={{ marginTop: "1em" }}
              >
                <img
                  src={deleteIcon}
                  alt="iconDeleteImage"
                  width={17}
                  height={17}
                ></img>
                <div className="changeImageLabel">Remove image</div>
              </div>
            </div>
          )}
          {images[counter] && images[counter]["image"] ? null : (
            <div className="labelImage">{"Drag & drop your file(s) here"}</div>
          )}
          {images[counter] && images[counter]["image"] ? null : (
            <div className="placeholderImage">
              {"Recommended size is 300 x 300 px"}
            </div>
          )}
        </div>
        {(images.length <= 0 && imageEmpty && firstInput) ||
        (images.length > 0 &&
          images[counter] &&
          images[counter]["emptyImage"] === true) ||
        (images.length > 0 &&
          images[counter] &&
          images[counter]["isFileSizeTooLargeMsg"] &&
          images[counter]["isFileSizeTooLargeMsg"] !== "") ||
        (images.length > 0 &&
          images[counter] &&
          images[counter]["imageFileTooLarge"] &&
          images[counter]["imageFileTooLarge"] !== "") ? (
          <div className="errorMessage">
            <p>
              {(images.length <= 0 && imageEmpty && firstInput) ||
              (images.length > 0 &&
                images[counter] &&
                images[counter]["emptyImage"] &&
                images[counter]["emptyImage"] === true)
                ? "This field is required"
                : (images.length <= 0 && imageEmpty && firstInput) ||
                  (images.length > 0 &&
                    images[counter] &&
                    images[counter]["isFileSizeTooLargeMsg"] &&
                    images[counter]["isFileSizeTooLargeMsg"] !== "")
                ? images[counter]["isFileSizeTooLargeMsg"]
                : images[counter]["imageFileTooLarge"]}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InputUploadImages;
