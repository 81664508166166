// Axios
import Axios from "./axios";

export const downloadBrochure = (body) => {
  return new Promise((resolve, reject) => {
    const data = Axios.post(
      `/download/brochure/email-notification/admin`,
      body
    );
    resolve(data);
  });
};
