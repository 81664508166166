
// Sun Editor
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

// CSS Styling
import "../styles/editor.css";

// API
import { convertPicture } from '../api/convertPicture';
import { useEffect } from 'react';


const EditorText = ({ 
    onChange, 
    preloadValue = 'Write your content here...' ,
    noImage = false
}) => {

    const handleImageUploadBefore = (files, info, uploadHandler) => { // DO NOT USE ASYNC FUNCTION
        Object.defineProperty(files[0], 'name', {
            writable: true,
            value: 'upload'
        })
        const data = new FormData();
        data.append("picture", files[0])
        convertPicture(data)
        .then((res) => {
            uploadHandler({ // MUST BE OBJECT! properties: url, name, size - always follow the step!
                "result": [{
                    "url": res.data.url,
                    "name": res.data.name,
                    "size": res.data.size 
                }]
            })
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const checkNull = () => {
        if (preloadValue === '<p>null</p>') return 'Write your content here...'
        return preloadValue
    }

    let buttonList;

    if (noImage) {
        buttonList = [
            ['undo', 'redo'],
            ['fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'removeFormat'],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list'],
            ['table', 'link'],
            ['fullScreen', 'showBlocks', 'codeView'],
            ['preview'],
        ]
    } else {
        buttonList = [
            ['undo', 'redo'],
            ['fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'removeFormat'],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list'],
            ['table', 'link', 'image'],
            ['fullScreen', 'showBlocks', 'codeView'],
            ['preview'],
        ]
    }

    useEffect(() => {
        document.getElementsByClassName('se-input-form')[3].placeholder = 'Not required, you can leave it blank'
        document.getElementsByClassName('se-input-form')[4].placeholder = 'Not required, you can leave it blank'
    })

    return(
        <SunEditor
            name='text-editor'
            onImageUploadBefore={handleImageUploadBefore}
            onChange={onChange}
            defaultValue={checkNull(preloadValue)}
            setContents={checkNull(preloadValue)}
            setAllPlugins={true}
            setDefaultStyle="font-family: poppins; font-size: 20px; line-height: 40px"
            setOptions={{
                buttonList,
                minHeight: '500px',
                height: "100%",
                linkTargetNewWindow: true,
                imageUploadSizeLimit: 1000000,
            }}
        />
    )
}

export default EditorText