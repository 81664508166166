import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CSS styling
import "../../styles/activitiesPublic.css";
import "../../styles/diplomaESG.css";

// Styled components
import styled from "styled-components";

// Icons & Backgrounds
import BannerDiplomaESG from "../../images/eventCards/banner_DIP_PM_ESG.jpeg";
import BrochureMMUSDImage from "../../images/eventCards/mm_usd_brochure_image.png";

// Sweet Alert v2
import Swal from "sweetalert2";

// Icons & Backgrounds
import WhoShouldLearnIcon from "../../images/icons/WhoShouldLearnIcon.svg";
import BenefitIcon from "../../images/icons/BenefitIcon.svg";
import DeliveryMethodIcon from "../../images/icons/DeliveryMethodIcon.svg";
import PrerequisiteIcon from "../../images/icons/PrerequisiteIcon.svg";
import TuitionFeeIcon from "../../images/icons/TuitionFeeIcon.svg";
import classSchedule from "../../images/diplomaESG/classSchedule.png";
import paymentScheduleAndScholarship from "../../images/diplomaESG/paymentScheduleAndScholarship.png";
import interlink_logo_new from "../../images/diplomaESG/interlink_logo_new.png";
import dip_pm_esg_page_infographic_banner from "../../images/diplomaESG/dip_pm_esg_page_infographic_banner.png";

// Components
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import ContainerText from "../../components/Container.js";

const DiplomaESG = () => {
  const navigate = useNavigate();

  const mediaQueryMobile = window.matchMedia("(max-width: 640px)");
  const mediaQueryTab = window.matchMedia("(max-width: 910px)");

  const [isMobileSize, setIsMobileSize] = useState(false);
  const [isTabSize, setIsTabSize] = useState(false);
  const [infoName, setInfoName] = useState("classSchedule");

  const handleDetectWindowSize = (mediaQuery, tab) => {
    if (tab === "mobile") {
      setIsMobileSize(mediaQuery.matches);
    } else {
      setIsTabSize(mediaQuery.matches);
    }
  };

  // Options renderer
  function renderOptions() {
    if (infoName === "classSchedule") {
      return (
        <div className="scheduleESGContainer">
          <img src={classSchedule} alt="Class schedule" />
        </div>
      );
    } else if (infoName === "paymentScheduleAndScholarship") {
      return (
        <div className="scheduleESGContainer">
          <img
            src={paymentScheduleAndScholarship}
            alt="Payment schedule and scholarship"
          />
        </div>
      );
    } else {
      return (
        <div>
          <p>Data not found, please try another options...</p>
        </div>
      );
    }
  }

  useEffect(() => {
    if (mediaQueryMobile) {
      handleDetectWindowSize(mediaQueryMobile, "mobile");
    }
  }, [mediaQueryMobile]);

  useEffect(() => {
    if (mediaQueryTab) {
      handleDetectWindowSize(mediaQueryTab, "tab");
    }
  }, [mediaQueryTab]);

  return (
    <div>
      <Header />
      <div className="activityPublicContainer">
        <ContainerText
          props={
            <div
              className="activityPublicDetailCardContainerDesc"
              style={{ margin: "25px" }}
            >
              <div style={{ marginTop: "10em", marginBottom: "3em" }}>
                <div
                  style={{
                    color: "#FF9F1E",
                    fontSize: isMobileSize ? "16px" : "20px",
                    fontWeight: "700",
                  }}
                >
                  ISA Academy
                </div>
                <div
                  style={{
                    fontSize: isMobileSize ? "35px" : "45px",
                    color: "#0D4C86",
                    fontWeight: "600",
                  }}
                >
                  Diploma of Project Management for ESG/Sustainability Projects
                  & Certification form Australia
                </div>
              </div>
              <div
                className="interlinkImageContainer"
                style={{ marginBottom: "3em" }}
              >
                <img src={interlink_logo_new} alt="Interlink" />
              </div>
              <div
                className="founderStoryPublicCardPicture"
                style={{
                  marginBottom: "5vh",
                  height: "100%",
                }}
              >
                <img src={BannerDiplomaESG} alt={"Banner Diploma ESG"} />
              </div>
              <div className="dipPmESGDescContainer">
                <div
                  className="dipPmESGDescEachContainer"
                  style={{ marginBottom: "8vh" }}
                >
                  <div className="dipPmESGDescEachContainerTitle">
                    <img src={WhoShouldLearnIcon} alt="who should learn icon" />
                    <div className="dipPmESGDescEachContainerTitleLabel">
                      Who should learn
                    </div>
                  </div>
                  <div className="dipPmESGDescEachContainerTitleHr" />
                  <div className="dipPmESGDescEachContainerDescription">
                    <div className="dipPmESGDescEachContainerEachDescription">
                      1. ESG Practitioners (Officers, Staffs, Managers): Anybody
                      who is planning, leading, managing, monitoring, controling
                      and evaluating ESG/CSR/Sustainability P3 (Projects,
                      Program, Portfolio)
                    </div>
                    <div
                      className="dipPmESGDescEachContainerEachDescription"
                      style={{ marginTop: "3vh" }}
                    >
                      2. Those who want to learn and apply P3 to an ESG context
                    </div>
                  </div>
                </div>
                <div className="dipPmESGDescEachContainer">
                  <div className="dipPmESGDescEachContainerTitle">
                    <img src={BenefitIcon} alt="Benefit icon" />
                    <div className="dipPmESGDescEachContainerTitleLabel">
                      Benefit
                    </div>
                  </div>
                  <div className="dipPmESGDescEachContainerTitleHr" />
                  <div className="dipPmESGDescEachContainerDescription">
                    <ul>
                      <li>
                        Getting Diploma Degree from Government of Australia
                      </li>
                      <li>
                        Graduate from Australia Polytechnic, study in Indonesia
                      </li>
                      <li>
                        Getting Certification of Project Management from
                        Australia Institute of Project Management
                      </li>
                      <li>
                        Possibility to get points for Australia Visa Application
                      </li>
                      <li>
                        Get opportunity to do "Professional Immersion Program"
                        in Sydney at Australian Companies (at additional
                        investment)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="dipPmESGDescContainer">
                <div
                  className="dipPmESGDescEachContainer"
                  style={{ marginBottom: "8vh" }}
                >
                  <div className="dipPmESGDescEachContainerTitle">
                    <img src={DeliveryMethodIcon} alt="Delivery methods icon" />
                    <div className="dipPmESGDescEachContainerTitleLabel">
                      Delivery methods
                    </div>
                  </div>
                  <div className="dipPmESGDescEachContainerTitleHr" />
                  <div className="dipPmESGDescEachContainerDescription">
                    <div
                      className="dipPmESGDescEachContainerEachDescription"
                      style={{ fontWeight: "700" }}
                    >
                      Module 1: (6 days)
                    </div>
                    <div className="dipPmESGDescEachContainerEachDescription">
                      Day 1 and 2 : Offline/In Class Room setting;
                    </div>
                    <div className="dipPmESGDescEachContainerEachDescription">
                      Day 3 until 6 : Online
                    </div>
                    <div
                      className="dipPmESGDescEachContainerEachDescription"
                      style={{ fontWeight: "700" }}
                    >
                      Module 2: (6 days)
                    </div>
                    <div
                      className="dipPmESGDescEachContainerEachDescription"
                      style={{ fontWeight: "700" }}
                    >
                      Module 3: (6 days)
                    </div>
                    <div
                      className="dipPmESGDescEachContainerEachDescription"
                      style={{ fontWeight: "700" }}
                    >
                      Module 4: (6 days)
                    </div>
                    <div className="dipPmESGDescEachContainerEachDescription">
                      <b style={{ fontWeight: "700" }}>Independent works: </b>
                      Homeworks/ Research and Assigments during and between
                      Modules (6 - 12 months)
                    </div>
                    <div
                      className="dipPmESGDescEachContainerEachDescription"
                      style={{
                        fontWeight: "700",
                        fontSize: "22px",
                        marginTop: "3vh",
                      }}
                    >
                      Teaching time:
                    </div>
                    <div style={{ marginBottom: "3vh" }}>
                      <ul>
                        <li>09:00 - 12:00</li>
                        <li>13:00 - 16:00</li>
                      </ul>
                    </div>
                    <div
                      style={{
                        marginTop: "3vh",
                        marginBottom: "5vh",
                        fontSize: "22px",
                      }}
                    >
                      <b style={{ fontWeight: "700" }}>Start on</b> 1 December
                      2024 - 30 January 2026
                    </div>
                    <div
                      style={{
                        marginBottom: "3vh",
                        fontSize: "22px",
                        fontWeight: "700",
                      }}
                    >
                      Optional Trip to Australia (additional investment is
                      required)
                    </div>
                    <div style={{ fontSize: "22px" }}>
                      Professional Immersion Project: Visit to Australia's
                      Companies for 2 Days
                    </div>
                  </div>
                </div>
                <div className="dipPmESGDescEachContainer">
                  <div className="dipPmESGDescEachContainerTitle">
                    <img src={TuitionFeeIcon} alt="Tuition fee icon" />
                    <div className="dipPmESGDescEachContainerTitleLabel">
                      Tuition fee
                    </div>
                  </div>
                  <div className="dipPmESGDescEachContainerTitleHr" />
                  <div
                    className="dipPmESGDescEachContainerDescription"
                    style={{ marginBottom: "8vh" }}
                  >
                    <div
                      className="dipPmESGDescEachContainerEachDescription"
                      style={{ fontWeight: "700", marginBottom: "3vh" }}
                    >
                      Rp 65.000.000,-
                    </div>
                    <div className="dipPmESGDescEachContainerEachDescription">
                      Installment up to 3 times*
                    </div>
                    <div className="dipPmESGDescEachContainerEachDescription">
                      Please contact us for further information
                    </div>
                  </div>
                  <div className="dipPmESGDescEachContainerTitle">
                    <img src={PrerequisiteIcon} alt="Prerequisite icon" />
                    <div className="dipPmESGDescEachContainerTitleLabel">
                      Prerequisite
                    </div>
                  </div>
                  <div className="dipPmESGDescEachContainerTitleHr" />
                  <div className="dipPmESGDescEachContainerDescription">
                    <div className="dipPmESGDescEachContainerEachDescription">
                      1. Bachelor Degree
                    </div>
                    <div className="dipPmESGDescEachContainerEachDescription">
                      2. Good English both in writing & speaking
                    </div>
                    <div className="dipPmESGDescEachContainerEachDescription">
                      3. Dealing with Project Management for at least 5 years
                      (can be Engineering or Community Development Projects or
                      other type of projects)
                    </div>
                  </div>
                </div>
              </div>
              <MentorOptions>
                <MentorOptionSelect
                  onClick={() => setInfoName("classSchedule")}
                  className={infoName}
                  match="classSchedule"
                >
                  <MentorOptionText>Class schedule</MentorOptionText>
                  <MentorOptionLine
                    className={infoName}
                    match="classSchedule"
                  />
                </MentorOptionSelect>
                <MentorOptionSelect
                  onClick={() => setInfoName("paymentScheduleAndScholarship")}
                  className={infoName}
                  match="paymentScheduleAndScholarship"
                >
                  <MentorOptionText>
                    Payment schedule and scholarship
                  </MentorOptionText>
                  <MentorOptionLine
                    className={infoName}
                    match="paymentScheduleAndScholarship"
                  />
                </MentorOptionSelect>
              </MentorOptions>
              <MentorInvisibleLine />
              {renderOptions()}
              <div
                className="dipPmESGDescEachContainerTitle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15vh",
                  marginBottom: "4vh",
                }}
              >
                <div className="dipPmESGDescEachContainerTitleLabel">
                  Registration procedures, payment and class schedule
                </div>
              </div>
              <div
                className="buttonDownloadHereDiplomaESGContainer"
                style={{ marginBottom: "5vh" }}
              >
                <button
                  type="submit"
                  className="btn"
                  onClick={() =>
                    navigate(
                      "/Diploma-PM-for-ESG/download-file-form/registration-procedures-payment-and-class-schedule"
                    )
                  }
                >
                  <div
                    style={{
                      marginLeft: "4vw",
                      marginRight: "4vw",
                    }}
                  >
                    Download here
                  </div>
                </button>
              </div>
              <div className="buttonDiplomaESGContainer">
                <button
                  type="submit"
                  className="btn"
                  onClick={() =>
                    navigate("/Diploma-PM-for-ESG/registration-form")
                  }
                >
                  <div style={{ marginLeft: "4vw", marginRight: "4vw" }}>
                    Pre-registration form
                  </div>
                </button>
              </div>
              <div
                className="dipPmESGDescEachContainerTitle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15vh",
                  marginBottom: "4vh",
                }}
              >
                <div className="dipPmESGDescEachContainerTitleLabel">
                  Download Diploma PM for ESG Brochure
                </div>
              </div>
              <div
                className="buttonDiplomaESGContainer"
                style={{ marginBottom: "15vh" }}
              >
                <button
                  type="submit"
                  className="btn"
                  onClick={() =>
                    navigate(
                      "/Diploma-PM-for-ESG/download-file-form/dip-pm-esg"
                    )
                  }
                >
                  <div style={{ marginLeft: "4vw", marginRight: "4vw" }}>
                    Click to download
                  </div>
                </button>
              </div>
              <MentorInvisibleLine />
              <div
                className="dipPmESGDescEachContainerTitle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10vh",
                  marginBottom: "4vh",
                }}
              >
                <div className="dipPmESGDescEachContainerTitleLabel">
                  Infographic about ESG & Sustainability
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="founderStoryPublicCardPicture"
                  style={{
                    height: "100%",
                    width: isMobileSize ? "75%" : "50%",
                  }}
                >
                  <img
                    src={dip_pm_esg_page_infographic_banner}
                    alt="Infographic banner"
                  />
                </div>
              </div>
              <div
                className="buttonDiplomaESGContainer"
                style={{ marginBottom: "15vh" }}
              >
                <button
                  type="submit"
                  className="btn"
                  onClick={() =>
                    navigate(
                      "/Diploma-PM-for-ESG/download-file-form/  infographic-esg-sustainability"
                    )
                  }
                >
                  <div style={{ marginLeft: "4vw", marginRight: "4vw" }}>
                    Click to download
                  </div>
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="founderStoryPublicCardPicture"
                  style={{
                    height: "100%",
                    width: isMobileSize ? "75%" : "50%",
                  }}
                >
                  <img src={BrochureMMUSDImage} alt={"Banner Diploma ESG"} />
                </div>
              </div>
              <div
                className="buttonDiplomaESGContainer"
                style={{ marginBottom: "15vh" }}
              >
                <button
                  type="submit"
                  className="btn"
                  onClick={() =>
                    navigate("/Diploma-PM-for-ESG/download-file-form/mm-usd")
                  }
                >
                  <div style={{ marginLeft: "4vw", marginRight: "4vw" }}>
                    Download brochure MM-USD
                  </div>
                </button>
              </div>
            </div>
          }
        />
      </div>
      <Footer />
    </div>
  );
};

export default DiplomaESG;

const MentorOptions = styled.div`
  display: flex;
  width: 100%;
`;

const MentorOptionSelect = styled.div`
  color: ${({ className, match }) =>
    className === match ? "#FF9902" : "#C4C4C4"};
  font-weight: ${({ className, match }) => (className === match ? "bold" : "")};
  /* flex-grow: 1; // Important */
  width: 50%;
`;

const MentorOptionLine = styled.hr`
  opacity: ${({ className, match }) => (className === match ? 1 : 0)};
  padding: 2px;
  margin-top: 0.5em;
  // width: 33vw;
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: -20px;
  padding: 2px;
`;

const MentorOptionText = styled.p`
  text-align: center;
  padding: 0 1em 0;
  margin-bottom: 0;
  cursor: pointer;
  color: #000000;
  &:hover {
    font-weight: bold;
  }

  &:hover + ${MentorOptionLine} {
    opacity: 1;
    transition: 0.2s;
    font-weight: bold;
  }
`;
