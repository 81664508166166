const getAPIUrl = () => {
  const staging = "https://api-isa-stg.cloudxier.com"
  const localhost = "http://localhost:3000"
  const production = "https://api-isa.cloudxier.com"
  // Get host from here
  let host = window.location.host;
  if (host) {
    host = host.toLowerCase();
  }
  let env
  switch (host) {
    case "isa-stg.cloudxier.com":
      env = staging
      break;
    case "www.sustainability.co.id":
      env = production
      break;
    case "sustainability.co.id":
      env = production
      break;
    default:
      env = localhost
  }
  return env
}

const isStaging = () => { 
  let host = window.location.host;  
  if (host) { 
    host = host.toLowerCase();  
  } 
  if (host.includes("isa-stg")) { 
    return true 
  }   
  return false  
} 
  
module.exports = {  
  getAPIUrl,
  isStaging 
} 
