import { useEffect, useLayoutEffect, useState } from "react";

// React DOM
import { useParams, useNavigate } from "react-router-dom";

// Axios - Base URL
import Axios from "../../api/axios";

// React Bootstrap
import { Accordion } from "react-bootstrap";

// React Form
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// React Parallax
import { ParallaxBanner } from "react-scroll-parallax";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// Images
import ExpertiseBackground from "../../images/background/expertiseParallax.png";
import PlayButton from "../../images/icons/playButton.png";
import DownloadBlue from "../../images/icons/DownloadBlue.png";
import ESGmatters from "../../images/background/CloudxierVideo.png";

// PDF
import NATcertificateIV from "../../pdf/ISA_10911NAT_Certificate_IV_in_Integrated_Risk_Management.pdf";
import NATdiplomaRisk from "../../pdf/ISA_10941NAT_Diploma_of_Integrated_Risk_Management.pdf";
import NATdipConsultancy from "../../pdf/ISA_10942NAT_DIP_CONSULTANCY.pdf";
import BSBprojectManagement from "../../pdf/ISA_BSB40920_Certificate_IV_in_Project_Management_Practice.pdf";
import BSBdipManagement from "../../pdf/ISA_BSB50820_Dip_Project_Management.pdf";
import BSBprogramManagement from "../../pdf/ISA_BSB60720_Advanced_Diploma_of_Program_Management.pdf";

// CSS styling
import "../../styles/expertise.css";

// Components
import ContainerText from "../../components/Container";
import PopupExpertise from "../../components/PopupExpertise";

// Helpers
import { openModal } from "../../helpers/openModal";
import { closeModal } from "../../helpers/closeModal";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// Google recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// React phone number input
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

// Sweetalert
import Swal from "sweetalert2";

const Expertise = () => {
  const [show, setShow] = useState(false);
  const [formContent, setFormContent] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [course, setCourse] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const { section } = useParams();
  const navigate = useNavigate();

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all" });

  // open popup after click "Download Brochure"
  const openExpertiseModal = (link) => {
    openModal();
    setDownloadLink(link);
    if (link === BSBdipManagement) {
      setCourse("BSB50820 Diploma of Project Management");
    } else if (link === NATdiplomaRisk) {
      setCourse("10941NAT Diploma of Integrated Risk Management");
    } else if (link === NATcertificateIV) {
      setCourse("10911NAT Certificate IV in Integrated Risk Management");
    } else if (link === BSBprojectManagement) {
      setCourse("BSB40920 Certificate IV in Project Management Practice");
    } else if (link === BSBprogramManagement) {
      setCourse("BSB60720 Advanced Diploma of Program Management");
    } else if (link === NATdipConsultancy) {
      setCourse("10942NAT Diploma of Consultancy");
    }
  };

  // recaptcha verification
  const onChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  // close popup
  const closeExpertiseModal = () => {
    closeModal();
    setFormContent(false);
  };

  // Show and hide text in first parallax background
  const bannerText = () => {
    if (window.scrollY > 600) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  // Endpoint for downloading pdf
  const onSubmit = (values) => {
    if (isCaptchaCorrect) {
      const { emailSubscriber, name, company, jobLevel, jobFunction } = values;
      Axios.post("/subscriber/downloadPdf", {
        emailSubscriber,
        courseType: course,
        name,
        company,
        jobLevel,
        jobFunction,
        phone: phoneNumber,
      })
        .then((res) => {
          setFormContent(true);
          window.open(downloadLink, "_blank");
          // Reset form input
          // reset(res);
        })
        .catch((err) => {
          err.response.data.errors.map((error) => {
            if (error.path === "emailSubscriber") setEmailError(error.message);
            if (error.path === "name") setNameError(error.message);
            if (error.path === "phone") setPhoneError(error.message);
          });
          return console.log(err.response);
        });
    } else {
      Swal.fire(`Oops..`, `Please verify our ReCaptcha`, "error");
    }
  };

  useEffect(() => {
    if (section) {
      document
        .getElementById(section)
        .scrollIntoView({ block: "center", inline: "start" });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", bannerText);
    Aos.init({ duration: 1000 });
    return () => {
      window.removeEventListener("scroll", bannerText);
    };
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("load", setShow(true));
  }, []);

  return (
    <div>
      <Header />
      <div id="expertise">
        <div className="expertiseMainBanner">
          <div className="expertiseMainBannerImageContainer">
            <ParallaxBanner
              layers={[
                {
                  image: ExpertiseBackground,
                  speed: -20,
                  scale: [0.6, 1.5],
                  shouldAlwaysCompleteAnimation: true,
                },
              ]}
              style={{ aspectRatio: "2 / 1.2" }}
            />
            <ContainerText
              props={
                <div className="expertiseMainBannerText">
                  {show ? (
                    <p>
                      Helping Organizations and Leaders to Realize
                      Sustainability goals for Better World
                    </p>
                  ) : null}
                </div>
              }
            />
          </div>
        </div>
        <ContainerText
          props={
            <div className="experimeMainBannerSmallScreen smallScreen">
              <p>
                Helping Organizations and Leaders to Realize Sustainability
                goals for Better World
              </p>
            </div>
          }
        />
        <div className="expertiseSubBanner">
          <ContainerText
            props={
              <div className="expertiseSubContainer">
                <div id="ESGroadMap" className="expertiseSubTitle">
                  <p>ESG Roadmap, Policies, Target and Metrics</p>
                </div>
                <Accordion defaultActiveKey="0" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        Why
                      </div>
                      {/* Why */}
                    </Accordion.Header>
                    <Accordion.Body>
                      The world slowly revives from a misconception that views
                      Businesses as mere profit makers. While pursuing income is
                      important for businesses to thrive, it does not ensure
                      resiliency as businesses come with risks.
                      <br />
                      <br />
                      Integrating sustainability principles, such as the
                      Environmental, Social, and Governance (ESG) principles
                      into business operations has long been acknowledged as a
                      practice to mitigate risks. The urgency to build a
                      resilient business rises, following the COVID-19 pandemic
                      that increases businesses' vulnerability to risks. Besides
                      working as a mitigation strategy, incorporating ESG may
                      also enhance financial performance by attracting Impact
                      Investors.
                      <br />
                      <br />
                      ISA understands that many corporations are determined to
                      take a step forward towards ESG. As a leading and
                      experienced sustainability consultancy company from
                      Indonesia, ISA offers reliable and professional ESG
                      consultancy services for businesses, whether private or
                      public, from various industries.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        Objective
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      To assist businesses in integrating ESG principles within
                      their business operations.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        Output
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Among other things are ESG Gap analysis, Roadmap,
                      Policies, Target and Metrics.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        How
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Together with ISA, clients will go through a series of
                      processes towards ESG, from identifying the current state
                      of sustainability or ESG performance in the company,
                      assessing risk and determining materiality, creating ESG
                      roadmap, determining program and target, and finally
                      composing ESG policy.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            }
          />
          <div className="expertiseParallax background1" />
          <ContainerText
            props={
              <div className="expertiseSubContainer">
                <div id="ImpactAnalysis" className="expertiseSubTitle">
                  <p>Impact Analysis and Reporting</p>
                </div>
                <Accordion defaultActiveKey="0" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        Why
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      A question that’s usually asked in budget meetings is
                      whether or not a program is impactful enough to be carried
                      on in the following year. Another one that likes to pop up
                      is "How do companies properly measure the full impact of
                      their programs?" or "How do companies avoid overclaiming
                      the impact oftheir programs?". ISA is believes that
                      assessing program impact is part of companies
                      accountability in providing quality programs for the
                      public.
                      <br />
                      <br />
                      There are multiple approaches to identify program impact,
                      from Theory of Change to Social Return on Investment
                      (SROI). ISA experts have learned from first-rate
                      institutions to calculate program impact and nurtured top
                      national companies along the way. With more than a decade
                      of experience, ISAis prepared to support your company in
                      measuring and reporting impact.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        Objective
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Measure and report the impact of corporate social
                      responsibility programs.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        Output
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Impact report for internal and external stakeholders.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        How
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      ISA will guide and collaborate with clients to measure the
                      impact of their social responsibility programs by adopting
                      the most effective approach, suited to the clients'
                      preferences. This service includes (if applicable);
                      on-site survey, first-hand data collection, interviews,
                      and preparation of impact reports for the public.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            }
          />
          <ContainerText
            props={
              <div className="expertiseSubContainer">
                <div
                  id="SustainabilityReport"
                  className="expertiseSubTitle afterFirstSubTitle"
                >
                  <p>Sustainability Report</p>
                </div>
                <Accordion defaultActiveKey="0" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        Why
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Transparency and accountability are two major keys in good
                      governance in a company. A company can’t achieve both if
                      they’re not determined to disclose their data,
                      performance, and plans to the public and investors.
                      <br />
                      <br />
                      Companies around the world are getting more conscious
                      about publishing the aforementioned information,
                      especially through Sustainability Reports. At ISA, we can
                      develop global-standard Sustainability Reports for
                      companies through writing, data collection and analysis,
                      and conducting interviews.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        Objective
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Prepare Sustainability Reports for companies by following
                      global standards and conforming to their industry.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        Output
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Deliver sustainability report that is in accordance to
                      Global Reporting Initiative (GRI), Sustainable Development
                      Goals (SDG) and other sustainability reporting frameworks.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        How
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      We form the sustainability report through data collection,
                      data analysis, conducting interviews and other methods.
                      During all this, we make sure to follow globally
                      recognized sustainability reporting frameworks in order to
                      deliver a world-class sustainability reporting service.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            }
          />
          <div className="expertiseParallax background2" />
          <ContainerText
            props={
              <div className="expertiseSubContainer">
                <div id="VocationalStudies" className="expertiseSubTitle">
                  <p>Vocational Studies</p>
                </div>
                <div className="expertiseChildrenTitle">
                  <p>
                    The recommended pathway for students and professionals that
                    would like to enhance their education, learn specific and
                    practical job skills, especially in the workforce. We are{" "}
                    <strong>
                      partnering with Australia-based Polytechnic, Interlink
                      Technology Services Pty Ltd
                    </strong>{" "}
                    RTO 31926{" "}
                    <a
                      href="https://www.itpl.com.au"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.itpl.com.au
                    </a>{" "}
                    to provide certifications and qualifications in:
                  </p>
                </div>
                <Accordion defaultActiveKey="0" alwaysOpen>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        BSB50820 Diploma of Project Management
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Project Management is an established industry discipline,
                      and skilled practitioners are in strong demand from
                      organizations across all sectors. Talented and
                      professionally trained Project Managers bring real value
                      to organizations. From time management to human resources,
                      or even communications and procurement, this Diploma
                      course will help you in becoming a knowledgeable Project
                      Manager. <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() => openExpertiseModal(BSBdipManagement)}
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        10941NAT Diploma of Integrated Risk Management
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Enterprise Risk Management (ERM) is the process of
                      identifying potential threats and opportunities that may
                      impact an organization, and preparing for them.
                      <br />
                      <br />
                      Integrated Risk Management (IRM) takes place when ERM is
                      fully integrated at all levels and functions within an
                      organization. IRM benefits all organizations, regardless
                      of size, as a robust IRM culture brings value and
                      strengthened business resilience.
                      <br />
                      <br />
                      Organizations that apply an integrated approach within
                      their business structure enjoy stability, security and
                      peace of mind. IRM allows businesses to react quickly and
                      appropriately to challenges, and the process of Risk
                      Identification also places organizations in a stronger
                      position to identify opportunity and enhance returns.
                      <br />
                      <br />
                      Here we are able to give you the necessary knowledge in
                      order to implement IRM in your company / organization.{" "}
                      <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() => openExpertiseModal(NATdiplomaRisk)}
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        10911NAT Certificate IV in Integrated Risk Management
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      The 10911NAT Certificate IV in Integrated Risk Management
                      is designed to introduce candidates to the fundamentals of
                      Risk Management.
                      <br />
                      <br />
                      The course is ideal for staff members who support or apply
                      Risk Management in a functional role within their
                      organization. Practical hands-on training is delivered
                      utilising a range of tools within the training workshops.{" "}
                      <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() => openExpertiseModal(NATcertificateIV)}
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        BSB40920 Certificate IV in Project Management Practice
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      This is a course developed specifically for project
                      coordinators and team members with a detailed overview of
                      fundamentals.
                      <br />
                      <br />
                      With this course we aim to provide our candidates with the
                      best practice knowledge and skills that are necessary to
                      contribute to the management of a project, or take
                      responsibility for components of larger projects.
                      Candidates will also learn of sound practical
                      understanding of project principles and methodologies{" "}
                      <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            openExpertiseModal(BSBprojectManagement)
                          }
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        BSB60720 Advanced Diploma of Program Management
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      This is a program with a scope set for Program Managers or
                      Project Directors, and it aims to provide essential skills
                      to navigate programs of projects Successfully negotiate
                      key challenges facing Program Managers.
                      <br />
                      <br />
                      Graduates will be equipped to: Implement best practice;
                      Direct programs of multiple projects; Actively manage
                      complex and competing schedules; Display outstanding
                      leadership. <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            openExpertiseModal(BSBprogramManagement)
                          }
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <span className="expertiseArrowRight">
                        <i className="fas fa-angle-right" />
                      </span>
                      <div style={{ fontSize: "27px", marginTop: "-0.15em" }}>
                        10942NAT Diploma of Consultancy
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      Consultants are recognised experts within their chosen
                      field and provide clients with a highly specialised,
                      professional service, which draws on their considerable
                      experience and qualifications. Today’s consultant works in
                      a wide range of professions, such as human resources,
                      information technology, financial management, project
                      management, marketing, and many more. The world moves in
                      one direction, forward. And in that direction, things
                      constantly interconnect with each other and new challenges
                      arise. In order to tread correctly to not only pass by but
                      also succeed in their respective fields, organizations and
                      companies are constantly in demand of consultants with
                      foresight and the readiness to face whatever comes their
                      way. We’re ready to equip you with said foresight and
                      readiness. <br />
                      <div className="expertiseDownloadButton">
                        <a
                          href="javascript:void(0)"
                          onClick={() => openExpertiseModal(NATdipConsultancy)}
                        >
                          <img src={DownloadBlue} alt="DownloadIcon" /> Download
                          Brochure
                        </a>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            }
          />
          <PopupExpertise
            backgroundColor="white"
            titleColor="black"
            titleText={
              !formContent
                ? "Get ready to download"
                : "Thank you for downloading"
            }
            hrefTo="javascript:void(0)"
            closePopup={closeExpertiseModal}
            closeColor="black"
            content={
              !formContent
                ? "Please fill in the form to download and see course details"
                : "Should you have any inquiry about the course, please contact our representative through"
            }
            whatsApp={
              !formContent ? null : (
                <a
                  href="https://api.whatsapp.com/send/?phone=6281218467661&text&app_absent=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  Whatsapp
                </a>
              )
            }
            contentColor="black"
            form={
              !formContent ? (
                <div className="popupExpertiseForm">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="expertiseFormInput">
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="emailSubscriber"
                        placeholder="Enter your email address"
                        {...register("emailSubscriber", {
                          required: "Please fill in your email",
                          onChange: () => setEmailError(null),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "email format invalid, please try again..",
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="emailSubscriber"
                        render={({ message }) => <p>{message}</p>}
                      />
                      <p>{emailError}</p>
                    </div>
                    <div className="expertiseFormInput">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        {...register("name", {
                          required: "Please fill in your name",
                          onChange: () => setNameError(null),
                          minLength: {
                            value: 3,
                            message: "minimum 3 characters",
                          },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message:
                              "alphabet characters only, please try again...",
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="name"
                        render={({ message }) => <p>{message}</p>}
                      />
                      <p>{nameError}</p>
                    </div>
                    <div className="expertiseFormInput">
                      <label>Company/Organization</label>
                      <input
                        type="text"
                        name="company"
                        placeholder="Enter your company/organizations"
                        {...register("company", {
                          required: "Please fill in your company/organizations",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="company"
                        render={({ message }) => <p>{message}</p>}
                      />
                    </div>
                    <div className="expertiseFormInput formSelectInput">
                      <label>Job Level</label>
                      <select
                        name="jobLevel"
                        {...register("jobLevel", {
                          required: "please select your job level",
                        })}
                      >
                        <option value="" disabled selected>
                          Choose your job level
                        </option>
                        <option value="C-suite">C-suite</option>
                        <option value="Vice President">Vice President</option>
                        <option value="Director">Director</option>
                        <option value="Manager">Manager</option>
                        <option value="Entry level">Entry level</option>
                        <option value="Students">Students</option>
                        <option value="Others">Others</option>
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="jobLevel"
                        render={({ message }) => <p>{message}</p>}
                      />
                    </div>
                    <div className="expertiseFormInput">
                      <label>Job Function</label>
                      <input
                        type="text"
                        name="jobFunction"
                        placeholder="Enter your job function"
                        {...register("jobFunction", {
                          required: "Please fill in your job function",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="jobFunction"
                        render={({ message }) => <p>{message}</p>}
                      />
                    </div>
                    <div className="expertiseFormInput">
                      <label>Phone Number</label>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="ID"
                        placeholder="Please input your phone number"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                      />
                      <p>{phoneError}</p>
                    </div>
                    <div className="expertiseFormInput">
                      <ReCAPTCHA
                        style={{
                          transform: "scale(0.65)",
                          webkitTransform: "scale(0.65)",
                          transformOrigin: "0 0",
                          webkitTransformOrigin: "0 0",
                          marginTop: "1em",
                        }}
                        sitekey="6LfXMYAeAAAAAAjkbjSL22SnQ5lqP29nDdPDuAO0"
                        onChange={onChangeCaptcha}
                      />
                    </div>
                    {isCaptchaCorrect ? (
                      <div className="popupExpertiseFormSubmit">
                        <input
                          type="submit"
                          value="Continue"
                          className="btn btn-warning"
                        />
                      </div>
                    ) : (
                      <div className="popupExpertiseFormSubmit">
                        <input
                          disabled
                          type="submit"
                          value="Continue"
                          style={{
                            backgroundColor: "grey",
                            color: "white",
                            border: "none",
                          }}
                          className="btn btn-warning"
                        />
                      </div>
                    )}
                    <div className="popupExpertiseFormNotice">
                      <span>
                        {" "}
                        By clicking the "Continue" button, you are agreeing to
                        the Institute for Sustainability and Agility (ISA){" "}
                        <a
                          href="Policy/PrivacyStatement"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </span>
                      <br />
                    </div>
                  </form>
                </div>
              ) : null
            }
          />
          <div className="expertiseBanner" style={{ display: "none" }}>
            <ContainerText
              props={
                <div className="expertiseBannerContainer">
                  <div className="expertiseBannerContent">
                    <div className="expertiseBannerTitle">
                      <p>Why ESG Matters?</p>
                    </div>
                    <div className="expertiseBannerSubTitle">
                      <p>
                        Lorem ipsum dolor sit amet lorem ipsum dolor sit amet
                      </p>
                    </div>
                    <div className="expertiseBannerLink">
                      <a
                        href="https://www.youtube.com/channel/UCWsQCHf9PodPl-_V_GaYorg/featured"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={PlayButton} alt="" /> Watch more video series
                      </a>
                    </div>
                  </div>
                  <div className="expertiseBannerImage">
                    <img src={ESGmatters} alt="ESGmatters" />
                  </div>
                </div>
              }
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Expertise;
