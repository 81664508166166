// Axios
import Axios from "./axios"

export const deleteCategoryName = (id) => {
    return new Promise((resolve, reject) => {
        const data = Axios.delete(`/news/deleteCategory/${id}`, {
            headers: {
                'access-token': localStorage.getItem("access_token")
            }
        })
        setTimeout(() => resolve(data), 0)
    })

}