import styled from "styled-components";

import StatusAlert from "../images/icons/admin/StatusAlert.png";
import ErrorStatusAlert from "../images/icons/admin/ErrorStatusAlert.png";

const PostAlert = ({ show, name, isWorkshop }) => {
  const PostAlertContainer = styled.div`
    z-index: 9999;
    position: fixed;
    display: flex;
    background-color: ${isWorkshop ? "#FFD8A5" : "#2aaada"};
    width: 100%;
  `;

  const PostAlertLeftColor = styled.div`
    background-color: ${isWorkshop ? "#D9593D" : "#1d90bb"};
    padding: 0.5em;
  `;

  const PostAlertImage = styled.img`
    margin-right: 0.5em;
    ${isWorkshop
      ? `
    width: 22px;
    height: 22px;`
      : ""}
  `;

  const PostAlertText = styled.div`
    color: ${isWorkshop ? "#A52D2D" : "white"};
    padding: 0.5em;
    ${isWorkshop
      ? `font-style: normal;
    font-weight: 500;
    font-size: 0.8em;`
      : "font-size: 0.7em;"}
  `;

  return (
    <>
      {show && (
        <PostAlertContainer>
          <PostAlertLeftColor />
          <PostAlertText>
            <span>
              <PostAlertImage
                src={isWorkshop ? ErrorStatusAlert : StatusAlert}
                alt="Status alert"
              />
            </span>
            <span>{name}</span>
          </PostAlertText>
        </PostAlertContainer>
      )}
    </>
  );
};

export default PostAlert;
