import React, { useState, useEffect } from "react";

// Icons
import uploadImageBtn from "../../../images/icons/admin/uploadImageBtn.png";

// Components
import Loader from "../../../components/Loader";
import iconChangeImage from "../../../images/icons/admin/changeImageIcon.png";

// Axios and API
import { addWorkshopTestimonial } from "../../../api/addWorkshopTestimonial";
import { fetchTestimonialDetail } from "../../../api/fetchTestimonialDetail";
import { editWorkshopTestimonial } from "../../../api/editWorkshopTestimonial";

// Sweet Alert v2
import Swal from "sweetalert2";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../../lotties/paperplane.json";

const TestimonialForm = (props) => {
  const {
    navigate,
    setShowAlertWorkshop,
    setWorkshopAlertMsg,
    editId,
    fetchAllTestimonials,
  } = props;

  const [participantName, setParticipantName] = useState("");
  const [participantDetails, setParticipantDetails] = useState("");
  const [workshopTitle, setWorkshopTitle] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [imageToSave, setimageToSave] = useState(null);
  const [imageToView, setimageToView] = useState(null);

  const [errorParticipantName, setErrorParticipantName] = useState(false);
  const [errorParticipantDetails, setErrorParticipantDetails] = useState(false);
  const [errorWorkshopTitle, setErrorWorkshopTitle] = useState(false);
  const [errorTestimonial, setErrorTestimonial] = useState(false);
  const [errorTestimonialImage, setErrorTestimonialImage] = useState(false);
  const [imageFileTooLargeMsg, setImageFileTooLargeMsg] = useState("");

  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (editId) {
      fetchOneTestimonial(editId);
    }
  }, [editId]);

  const fetchOneTestimonial = async (testimonialId) => {
    setIsLoadingSave(true);
    const response = await fetchTestimonialDetail(testimonialId);
    if (response && response.status === 200 && response.data) {
      const {
        image_url,
        participant_details = "",
        participant_name = "",
        testimonial = "",
        workshop_title = "",
      } = response.data || {};
      setParticipantName(participant_name);
      setParticipantDetails(participant_details);
      setWorkshopTitle(workshop_title);
      setTestimonial(testimonial);
      setimageToView({ url: image_url });
      setIsLoadingSave(false);
    }
  };

  const onImageChange = (files) => {
    if (files && files[0]) {
      const { size } = files[0] || {};
      if (size >= 1045504) {
        setImageFileTooLargeMsg("File too large (Max: 1mb)");
      } else {
        setImageFileTooLargeMsg("");
      }
      setimageToSave(files[0]);
      setimageToView({
        fileName: files[0].name,
        url: URL.createObjectURL(files[0]),
      });
    }
  };

  const onInputChange = (str, setter) => {
    setter(str);
  };

  const checkAllInputs = (e) => {
    e.preventDefault();
    setIsLoadingSave(true);
    let participantNameValidationValue = false;
    let participantDetailsValidationValue = false;
    let workshopTitleValidationValue = false;
    let testimonialValidationValue = false;
    let testimonialImageValidationValue = false;

    if (!participantName) {
      participantNameValidationValue = true;
    }
    if (!participantDetails) {
      participantDetailsValidationValue = true;
    }
    if (!workshopTitle) {
      workshopTitleValidationValue = true;
    }
    if (!testimonial) {
      testimonialValidationValue = true;
    }
    if (!editId) {
      if (!imageToSave || !imageToView) {
        testimonialImageValidationValue = true;
      }
    }
    setErrorParticipantName(participantNameValidationValue);
    setErrorParticipantDetails(participantDetailsValidationValue);
    setErrorWorkshopTitle(workshopTitleValidationValue);
    setErrorTestimonial(testimonialValidationValue);
    setErrorTestimonialImage(testimonialImageValidationValue);
    if (
      participantNameValidationValue !== true &&
      participantDetailsValidationValue !== true &&
      workshopTitleValidationValue !== true &&
      testimonialValidationValue !== true
    ) {
      if (editId) {
        handleSave();
      } else {
        if (testimonialImageValidationValue !== true) {
          handleSave();
        } else {
          setShowAlertWorkshop(true);
          setWorkshopAlertMsg("Please check the error fields");
        }
      }
    } else {
      setIsLoadingSave(false);
      setShowAlertWorkshop(true);
      setWorkshopAlertMsg("Please check the error fields");
    }
  };

  const handleSave = async () => {
    const data = new FormData();
    data.append("participant_name", participantName);
    data.append("participant_details", participantDetails);
    data.append("workshop_title", workshopTitle);
    data.append("testimonial", testimonial);
    if (!editId) {
      data.append("workshopTestimonialImage", imageToSave);
    } else {
      if (imageToSave) {
        data.append("workshopTestimonialImage", imageToSave);
      }
    }
    try {
      let result;
      if (editId) {
        result = await editWorkshopTestimonial(editId, data);
      } else {
        result = await addWorkshopTestimonial(data);
      }
      if (result) {
        Swal.fire({
          customClass: {
            confirmButton: "publishButton",
            title: "publishTitle",
            popup: "publishPopup",
          },
          showCloseButton: true,
          confirmButtonColor: "#1F81DC",
          title: "Success!",
          text: editId
            ? "This testimonial has been succesfully edited"
            : "This testimonial has been succesfully created",
          confirmButtonText: "Okay, got it!",
        });
        fetchAllTestimonials();
        navigate(`/admin/isaAcademy-testimonial`);
      }
    } catch (err) {
      const { errorName = "", message } = err.response.data;
      if (
        message &&
        (message === "Unauthorized" ||
          message === "Unauthenticated" ||
          message === "TokenExpiredError")
      ) {
        Swal.fire({
          position: "top-end",
          text: "Token expired, please re-login",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          width: "450px",
        });
        setIsLoadingSave(false);
        localStorage.removeItem("isVerified");
        localStorage.removeItem("access_token");
        localStorage.removeItem("email");
        navigate("/signIn");
      } else if (errorName && errorName === "File too large (Max: 1mb)") {
        setImageFileTooLargeMsg(errorName);
      } else {
        setImageFileTooLargeMsg("");
      }
      Swal.fire({
        position: "top-end",
        text:
          errorName && errorName === "File too large (Max: 1mb)"
            ? err.response.data
            : `Please fill all required elements`,
        showConfirmButton: false,
        timer: 1500,
        backdrop: false,
        width: "450px",
      });
    } finally {
      setIsLoadingSave(false);
    }
  };
  return (
    <div className="adminPostList">
      {isLoadingSave ? null : (
        <div className="adminPostListTitleContainer">
          <div className="adminPostListTitle">
            <p>{editId ? "Edit Testimonial" : "Add New"}</p>
          </div>
        </div>
      )}
      {isLoadingSave ? (
        <div
          style={{
            width: "75%",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "5em",
          }}
        >
          <Lottie options={lottieOptions} height={200} width={400} />
          <h4
            style={{
              color: "#0D4C86",
            }}
          >
            LOADING
          </h4>
          <p
            style={{
              color: "#0D4C86",
            }}
          >
            Processing your request, please wait...
          </p>
        </div>
      ) : (
        <div className="adminPostAdd">
          <div className="typeOfWorkAndTagInput">
            <div className="eachContainer">
              <div className="eachLabel">Participant Name</div>
              <input
                required
                name="participantName"
                type="text"
                className="inputBox"
                placeholder="Please fill in participant name"
                value={participantName}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setParticipantName)
                }
              ></input>
              {errorParticipantName && !participantName ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="eachContainer">
              <div className="eachLabel">Participant details</div>
              <input
                required
                name="participantDetails"
                type="text"
                className="inputBox"
                placeholder="Please fill in participant details"
                value={participantDetails}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setParticipantDetails)
                }
              ></input>
              {errorParticipantDetails && !participantDetails ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="adminPostInput">
            <div className="eachLabel">Upload image</div>
            <div
              className={
                !imageToView
                  ? "inputImageContainer"
                  : "inputImageContainerNoPointer"
              }
              onClick={() =>
                !imageToView
                  ? document
                      .querySelector(`.input-field-testimonial-image`)
                      .click()
                  : null
              }
            >
              <input
                type="file"
                accept="image/*"
                className={`input-field-testimonial-image`}
                hidden
                onChange={({ target: { files } }) => onImageChange(files)}
              />
              <div className="imageToViewContainer">
                <img
                  src={
                    imageToView && imageToView.url
                      ? imageToView.url
                      : uploadImageBtn
                  }
                  style={
                    imageToView && imageToView.url
                      ? { maxWidth: 250, maxHeight: 175 }
                      : { maxWidth: 35, maxHeight: 35 }
                  }
                  alt="coverImage"
                ></img>
                {imageToView && imageToView.fileName ? (
                  <div className="imageFileNameFontStyle">
                    {imageToView.fileName}
                  </div>
                ) : null}
              </div>
              {imageToView && (
                <div
                  className="btnChangeImage"
                  onClick={() =>
                    document
                      .querySelector(`.input-field-testimonial-image`)
                      .click()
                  }
                >
                  <img
                    src={iconChangeImage}
                    alt="iconChangeImage"
                    width={17}
                    height={17}
                  ></img>
                  <div className="changeImageLabel">Change image</div>
                </div>
              )}
              {imageToView ? null : (
                <div className="labelImage">
                  {"Drag & drop your file(s) here"}
                </div>
              )}
              {imageToView ? null : (
                <div className="placeholderImage">
                  {"Recommended size is 300 x 300 px"}
                </div>
              )}
            </div>
            {errorTestimonialImage &&
            (!imageToView || (imageToView && !imageToView.url)) ? (
              <div className="errorMessage">
                <p>This field is required</p>
              </div>
            ) : null}
            {imageFileTooLargeMsg && (
              <div className="errorFailedToUpload">
                <p>{imageFileTooLargeMsg}</p>
              </div>
            )}
          </div>
          <div className="adminPostInput">
            <div className="labelForDescription">
              <div className="eachLabel">Workshop Title</div>
            </div>
            <textarea
              required
              name="workshopTitle"
              type="text"
              className="xSmallInputBox"
              placeholder="Please fill in workshop title"
              value={workshopTitle}
              onChange={({ target: { value } }) =>
                onInputChange(value, setWorkshopTitle)
              }
            ></textarea>
            {errorWorkshopTitle && !workshopTitle ? (
              <div className="errorMessage">
                <p>This field is required</p>
              </div>
            ) : null}
          </div>
          <div className="adminPostInput">
            <div className="labelForDescription">
              <div className="eachLabel">Testimonial</div>
            </div>
            <textarea
              required
              name="testimonial"
              type="text"
              className="normalInputBox"
              placeholder="Please enter the description here..."
              value={testimonial}
              onChange={({ target: { value } }) =>
                onInputChange(value, setTestimonial)
              }
            ></textarea>
            {errorTestimonial && !testimonial ? (
              <div className="errorMessage">
                <p>This field is required</p>
              </div>
            ) : null}
          </div>
          <div className="adminPostButtonContainer">
            <div>
              <span
                className="postCancelButton btn"
                onClick={() => navigate("/admin/isaAcademy-testimonial")}
              >
                Cancel
              </span>
            </div>
            <div>
              <button
                className="postSaveButton btn"
                onClick={(e) => checkAllInputs(e)}
              >
                {isLoadingSave ? (
                  <span>
                    <Loader
                      type="spin"
                      color="black"
                      height="25px"
                      width="25px"
                    />
                  </span>
                ) : (
                  <span>Save</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestimonialForm;
