
// CSS Styling
import "../styles/socialMedia.css";

// Icons
import TwitterBlack from "../images/icons/TwitterBlack.png"
import FacebookBlack from "../images/icons/FacebookBlack.png"
import LinkedInBlack from "../images/icons/LinkedInBlack.png"
import MessageBlack from "../images/icons/MessageBlack.png"
import UrlBlack from "../images/icons/UrlBlack.png"

const SocialMedia = ({ twitterURL, facebookURL, linkedinURL, mailSubject, mailContent, copyLink }) => {
    return(
        <div className="componentSocialMedia">
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${facebookURL}&quote=Check%20ISA%20Blog!"`} target="_blank" rel="noreferrer"><img src={FacebookBlack} alt=""/></a>
            <a href={`https://twitter.com/intent/tweet?text=Check%20ISA%20Blog!&url=${twitterURL}`} target="_blank" rel="noreferrer"><img src={TwitterBlack} alt=""/></a>
            <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkedinURL}`}><img src={LinkedInBlack} alt=""/></a>
            <a href={`https://mail.google.com/mail/u/0/?view=cm&to&su=${mailSubject}&body=${mailContent}%0A&bcc&cc&fs=1&tf=1`} target="_blank" rel="noreferrer"><img src={MessageBlack} alt=""/></a>
            <a href="#" onClick={copyLink}><img src={UrlBlack} alt=""/></a>
        </div>
    )
}

export default SocialMedia