import React from "react";

const InputFacilitator = (props) => {
  const {
    firstInput,
    counter,
    facilitators,
    addMoreFacilitatorInput,
    onInputChange,
    addMoreIcon,
    onImageChange,
    uploadImageBtn,
    facilitatorEmpty,
    iconChangeImage,
  } = props;
  return (
    <div className="workshopInputContainer" key={counter}>
      <div className="labelForWorkshopInput">
        <div className="eachLabel">{`Facilitator ${counter + 1}`}</div>
        {firstInput ? (
          <div
            className="btnAddMoreInput"
            onClick={() => addMoreFacilitatorInput()}
          >
            <img
              src={addMoreIcon}
              alt="add more icon"
              width={15}
              height={15}
            ></img>
            <div style={{ marginLeft: "7.5px" }}>Add more facilitator</div>
          </div>
        ) : null}
      </div>
      <div className="adminPostInput">
        <div className="eachFacilitatorLabel">Facilitator’s Image</div>
        <div
          className={
            facilitators[counter] && facilitators[counter]["facilitatorImg"]
              ? "inputImageContainerNoPointer"
              : "inputImageContainer"
          }
          onClick={() =>
            facilitators[counter] && facilitators[counter]["facilitatorImg"]
              ? null
              : document.querySelector(`.input-field${counter}`).click()
          }
        >
          <input
            type="file"
            accept="image/*"
            className={`input-field${counter}`}
            hidden
            onChange={({ target: { files } }) =>
              onImageChange(files, "facilitator", counter, "facilitatorImg")
            }
          />
          <div className="imageToViewContainer">
            <img
              src={
                facilitators[counter] && facilitators[counter]["facilitatorImg"]
                  ? facilitators[counter] &&
                    facilitators[counter]["facilitatorImg"]
                  : uploadImageBtn
              }
              style={
                facilitators[counter] && facilitators[counter]["facilitatorImg"]
                  ? { maxWidth: 250, maxHeight: 175 }
                  : { maxWidth: 35, maxHeight: 35 }
              }
              alt="coverImage"
            ></img>
            {facilitators[counter] &&
            !facilitators[counter]["failedToUpload"] &&
            facilitators[counter]["fileName"] ? (
              <div className="imageFileNameFontStyle">
                {facilitators[counter]["fileName"]}
              </div>
            ) : null}
            {facilitators[counter] &&
              facilitators[counter]["failedToUpload"] &&
              facilitators[counter]["failedToUpload"] !== "" && (
                <div className="errorFailedToUpload">
                  <p>{facilitators[counter]["failedToUpload"]}</p>
                </div>
              )}
          </div>
          {facilitators[counter] && facilitators[counter]["facilitatorImg"] && (
            <div
              className="btnChangeImage"
              onClick={() =>
                document.querySelector(`.input-field${counter}`).click()
              }
            >
              <img
                src={iconChangeImage}
                alt="iconChangeImage"
                width={17}
                height={17}
              ></img>
              <div className="changeImageLabel">Change image</div>
            </div>
          )}
          {facilitators[counter] &&
          facilitators[counter]["facilitatorImg"] ? null : (
            <div className="labelImage">{"Drag & drop your file(s) here"}</div>
          )}
          {facilitators[counter] &&
          facilitators[counter]["facilitatorImg"] ? null : (
            <div className="placeholderImage">
              {"Recommended size is 300 x 300 px"}
            </div>
          )}
        </div>
        {(facilitators.length <= 0 && facilitatorEmpty && firstInput) ||
        (facilitators.length > 0 &&
          facilitators[counter] &&
          facilitators[counter]["emptyFacilitatorImg"] === true) ||
        (facilitators.length > 0 &&
          facilitators[counter] &&
          facilitators[counter]["isFileSizeTooLargeMsg"] &&
          facilitators[counter]["isFileSizeTooLargeMsg"] !== "") ||
        (facilitators.length > 0 &&
          facilitators[counter] &&
          facilitators[counter]["imageFileTooLarge"] &&
          facilitators[counter]["imageFileTooLarge"] !== "") ? (
          <div className="errorMessage">
            <p>
              {(facilitators.length <= 0 && facilitatorEmpty && firstInput) ||
              (facilitators.length > 0 &&
                facilitators[counter] &&
                facilitators[counter]["emptyFacilitatorImg"] &&
                facilitators[counter]["emptyFacilitatorImg"] === true)
                ? "This field is required"
                : (facilitators.length <= 0 &&
                    facilitatorEmpty &&
                    firstInput) ||
                  (facilitators.length > 0 &&
                    facilitators[counter] &&
                    facilitators[counter]["isFileSizeTooLargeMsg"] &&
                    facilitators[counter]["isFileSizeTooLargeMsg"] !== "")
                ? facilitators[counter]["isFileSizeTooLargeMsg"]
                : facilitators[counter]["imageFileTooLarge"]}
            </p>
          </div>
        ) : null}
      </div>
      <div className="adminPostInput">
        <div className="labelForDescription">
          <div className="eachFacilitatorLabel">Facilitator Name</div>
        </div>
        <textarea
          required
          name="facilitatorName"
          type="text"
          className="xSmallInputBox"
          placeholder="Please fill in facilitator name here..."
          value={
            facilitators[counter] && facilitators[counter]["facilitatorName"]
              ? facilitators[counter]["facilitatorName"]
              : ""
          }
          onChange={({ target: { value } }) =>
            onInputChange(false, value, counter, "facilitatorName")
          }
        ></textarea>
        {(facilitators.length <= 0 && facilitatorEmpty && firstInput) ||
        (facilitators.length > 0 &&
          facilitators[counter] &&
          facilitators[counter]["emptyFacilitatorName"] === true) ? (
          <div className="errorMessage">
            <p>This field is required</p>
          </div>
        ) : null}
      </div>
      <div className="adminPostInput">
        <div className="eachFacilitatorLabel">Facilitator’s profile</div>
        <textarea
          required
          name="facilitatorProfile"
          type="text"
          className="bigInputBox"
          placeholder="Please enter the description here..."
          value={
            facilitators[counter] && facilitators[counter]["facilitatorProfile"]
              ? facilitators[counter]["facilitatorProfile"]
              : ""
          }
          onChange={({ target: { value } }) =>
            onInputChange(false, value, counter, "facilitatorProfile")
          }
        ></textarea>
        {(facilitators.length <= 0 && facilitatorEmpty && firstInput) ||
        (facilitators.length > 0 &&
          facilitators[counter] &&
          facilitators[counter]["emptyFacilitatorProfile"] &&
          facilitators[counter]["emptyFacilitatorProfile"] === true) ? (
          <div className="errorMessage">
            <p>This field is required.</p>
          </div>
        ) : null}
        {/* {facilitators.length > 0 &&
        facilitators[counter] &&
        facilitators[counter]["facilitatorProfile"] &&
        facilitators[counter]["facilitatorProfile"].length > 255 ? (
          <div className="errorMessage">
            <p>Please limit data to maximum of 255 characters.</p>
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

export default InputFacilitator;
