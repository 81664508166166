import React, { useEffect, useState } from "react";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../lotties/paperplane.json";

// Axios and API
import { createNewBook, editBook, fetchBookDetails } from "../../api/books";

// React Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Moment.js
import moment from "moment";

// Icons
import uploadImageBtn from "../../images/icons/admin/uploadImageBtn.png";
import iconChangeImage from "../../images/icons/admin/changeImageIcon.png";

// Sweet Alert v2
import Swal from "sweetalert2";

// Components
import Loader from "../../components/Loader";
import WorkshopDropdownSelect from "../ISA-Academy-Form/components/WorkshopDropdownSelect";
import ArrowDownIcon from "../../images/icons/admin/arrowDownIcon";
import EditorText from "../../components/EditorText";

const BookForm = (props) => {
  const {
    navigate,
    setShowAlertWorkshop,
    setWorkshopAlertMsg,
    editId,
    fetchAllBooks,
  } = props;

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [isLoadingSave, setIsLoadingSave] = useState(false);

  // input states
  const [bookTitle, setBookTitle] = useState("");
  const [imageToSave, setimageToSave] = useState(null);
  const [imageToView, setimageToView] = useState(null);
  const [description, setDescription] = useState("");
  const [whatYouWillLearn, setWhatYouWillLearn] = useState("");
  const [aboutTheGuideBook, setAboutTheGuideBook] = useState("");
  const [authors, setAuthors] = useState([]);
  const [authorOptions, setAuthorOptions] = useState([]);
  const [publishedOnDate, setPublishedOnDate] = useState(null);
  const [selectedPublishedOnDate, setSelectedPublishedOnDate] = useState(null);
  const [bookISBN, setBookISBN] = useState("");
  const [bookNumberOfPages, setBookNumberOfPages] = useState("");
  const [bookLanguage, setBookLanguage] = useState("");
  const [bookDownloadURL, setBookDownloadURL] = useState("");

  // error states
  const [bookTitleEmpty, setBookTitleEmpty] = useState(false);
  const [errorBookImage, setErrorBookImage] = useState(false);
  const [imageFileTooLargeMsg, setImageFileTooLargeMsg] = useState("");
  const [descriptionEmpty, setDescriptionEmpty] = useState(false);
  const [whatYouWillLearnEmpty, setWhatYouWillLearnEmpty] = useState(false);
  const [aboutTheGuideBookEmpty, setAboutTheGuideBookEmpty] = useState(false);
  const [authorsEmpty, setAuthorsEmpty] = useState(false);
  const [publishedOnDateEmpty, setPublishedOnDateEmpty] = useState(false);
  const [bookISBNEmpty, setBookISBNEmpty] = useState(false);
  const [bookNumberOfPagesEmpty, setBookNumberOfPagesEmpty] = useState(false);
  const [bookLanguageEmpty, setBookLanguageEmpty] = useState(false);
  const [bookDownloadURLEmpty, setBookDownloadURLEmpty] = useState(false);
  const [invalidBookDownloadURL, setInvalidBookDownloadURL] = useState(false);

  const onInputChange = (str, setter) => {
    setter(str);
  };

  const onImageChange = (files) => {
    if (files && files[0]) {
      const { size } = files[0] || {};
      let maxFileSize = 1 * 1024 * 1024;
      if (size > maxFileSize) {
        setImageFileTooLargeMsg("File too large (Max: 1mb)");
      } else {
        setImageFileTooLargeMsg("");
      }
      setimageToSave(files[0]);
      setimageToView({
        fileName: files[0].name,
        url: URL.createObjectURL(files[0]),
      });
    }
  };

  const onDropdownChange = (val, setter, flagger) => {
    if (flagger) {
      const isSameProperty = (val, authorOptions) =>
        val.value === authorOptions.value;

      const onlyInLeft = (left, right, compareFunction) =>
        left.filter(
          (leftValue) =>
            !right.some((rightValue) => compareFunction(leftValue, rightValue))
        );

      const onlyInVal = onlyInLeft(val, authorOptions, isSameProperty);
      const onlyInAuthorOptions = onlyInLeft(
        authorOptions,
        val,
        isSameProperty
      );

      const result = [...onlyInVal, ...onlyInAuthorOptions];

      let arrAuthorOptions = authorOptions;
      result.forEach(async (el) => {
        if (el["__isNew__"]) {
          const { label, value } = el || {};
          arrAuthorOptions.push({ label, value });
          setAuthorOptions(arrAuthorOptions);
          setter([...authors, { label, value }]);
        }
      });
    }
    setter(val);
  };

  const checkAllInputs = (e) => {
    e.preventDefault();
    setIsLoadingSave(true);

    let isBookTitleEmpty = false;
    let bookImageValidationValue = false;
    let isDescriptionEmpty = false;
    let isWhatYouWillLearnEmpty = false;
    let isAboutTheGuideBookEmpty = false;
    let isAuthorsEmpty = false;
    let isPublishedOnDateEmpty = false;
    let isBookISBNEmpty = false;
    let isBookNumberOfPagesEmpty = false;
    let isBookLanguageEmpty = false;
    let isBookDownloadURLEmpty = false;
    let invalidBookDownloadURL = false;

    if (!bookTitle) {
      isBookTitleEmpty = true;
    }

    if (!editId) {
      if (!imageToSave || !imageToView) {
        bookImageValidationValue = true;
      }
    }

    if (!description) {
      isDescriptionEmpty = true;
    }

    if (!whatYouWillLearn) {
      isWhatYouWillLearnEmpty = true;
    }

    if (!aboutTheGuideBook) {
      isAboutTheGuideBookEmpty = true;
    }

    if (authors.length <= 0) {
      isAuthorsEmpty = true;
    }

    if (!publishedOnDate) {
      if (!editId) {
        if (!selectedPublishedOnDate) {
          isPublishedOnDateEmpty = true;
        }
      } else {
        isPublishedOnDateEmpty = true;
      }
    }

    if (!bookNumberOfPages) {
      isBookNumberOfPagesEmpty = true;
    }

    if (!bookISBN) {
      isBookISBNEmpty = true;
    }

    if (!bookLanguage) {
      isBookLanguageEmpty = true;
    }

    if (bookDownloadURL) {
      const websiteUrlRegex =
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?([\/?].*)?$/;
      if (websiteUrlRegex.test(bookDownloadURL) !== true) {
        invalidBookDownloadURL = true;
      }
    } else {
      isBookDownloadURLEmpty = true;
    }

    setBookTitleEmpty(isBookTitleEmpty);
    setErrorBookImage(bookImageValidationValue);
    setDescriptionEmpty(isDescriptionEmpty);
    setWhatYouWillLearnEmpty(isWhatYouWillLearnEmpty);
    setAboutTheGuideBookEmpty(isAboutTheGuideBookEmpty);
    setAuthorsEmpty(isAuthorsEmpty);
    setPublishedOnDateEmpty(isPublishedOnDateEmpty);
    setBookISBNEmpty(isBookISBNEmpty);
    setBookNumberOfPagesEmpty(isBookNumberOfPagesEmpty);
    setBookLanguageEmpty(isBookLanguageEmpty);
    setBookDownloadURLEmpty(isBookDownloadURLEmpty);
    setInvalidBookDownloadURL(invalidBookDownloadURL);

    if (
      isBookTitleEmpty !== true &&
      isDescriptionEmpty !== true &&
      isWhatYouWillLearnEmpty !== true &&
      isAboutTheGuideBookEmpty !== true &&
      isAuthorsEmpty !== true &&
      isPublishedOnDateEmpty !== true &&
      isBookISBNEmpty !== true &&
      isBookNumberOfPagesEmpty !== true &&
      isBookLanguageEmpty !== true &&
      isBookDownloadURLEmpty !== true &&
      invalidBookDownloadURL !== true
    ) {
      if (editId) {
        handleSave();
      } else {
        if (bookImageValidationValue !== true) {
          handleSave();
        }
      }
    } else {
      setIsLoadingSave(false);
      setShowAlertWorkshop(true);
      setWorkshopAlertMsg("Please check the error fields");
    }
  };

  const handleChangeInputDate = (date) => {
    setSelectedPublishedOnDate(date);
    setPublishedOnDate(moment(date).format("DD/MM/YYYY"));
  };

  const handleSave = async () => {
    const data = new FormData();

    let published_on_date = moment(selectedPublishedOnDate, "YYYY-MM-DD");
    published_on_date = new Date(published_on_date).toISOString();

    const authorNames = [];
    authors.forEach((tag) => {
      authorNames.push(tag.value);
    });

    data.append("book_title", bookTitle);
    if (!editId) {
      data.append("book_image", imageToSave);
    } else {
      if (imageToSave) {
        data.append("book_image", imageToSave);
      }
    }
    data.append("book_description", description);
    data.append("what_you_will_learn", whatYouWillLearn);
    data.append("about_the_guide_book", aboutTheGuideBook);
    data.append("book_author_names", JSON.stringify(authorNames));
    data.append("book_published_on_date", published_on_date);
    data.append("book_ISBN", bookISBN);
    data.append("number_of_pages", bookNumberOfPages);
    data.append("book_language", bookLanguage);
    data.append("book_download_url", bookDownloadURL);

    try {
      let result;
      if (editId) {
        result = await editBook(editId, data);
      } else {
        result = await createNewBook(data);
      }
      if (result) {
        Swal.fire({
          customClass: {
            confirmButton: "publishButton",
            title: "publishTitle",
            popup: "publishPopup",
          },
          showCloseButton: true,
          confirmButtonColor: "#1F81DC",
          title: "Success!",
          text: editId
            ? "This Book has been succesfully edited"
            : "This Book has been succesfully created",
          confirmButtonText: "Okay, got it!",
        });
        fetchAllBooks();
        navigate(-1);
      }
    } catch (err) {
      const {
        founderStoryImg = "",
        index = "",
        errorName,
        errorMsg,
        message,
      } = err.response.data || {};

      if (
        message &&
        (message === "Unauthorized" ||
          message === "Unauthenticated" ||
          message === "TokenExpiredError")
      ) {
        Swal.fire({
          position: "top-end",
          text: "Token expired, please re-login",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          width: "450px",
        });
        setIsLoadingSave(false);
        localStorage.removeItem("isVerified");
        localStorage.removeItem("access_token");
        localStorage.removeItem("email");
        navigate("/signIn");
      } else if (errorName && errorName === "Bad request") {
      } else if (errorName && errorName === "File too large (Max: 5mb)") {
      } else {
        Swal.fire({
          position: "top-end",
          text: "Please fill all required elements",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          width: "450px",
        });
      }
    } finally {
      setIsLoadingSave(false);
    }
  };

  useEffect(() => {
    if (editId) {
      handleFetchOneBook(editId);
    }
  }, [editId]);

  const handleFetchOneBook = async (book_id) => {
    setIsLoadingSave(true);
    const response = await fetchBookDetails(book_id);
    if (response && response.status === 200 && response.data) {
      const {
        title,
        img_url,
        description,
        what_you_will_learn,
        about_guide_book,
        book_authors,
        published_on,
        isbn,
        number_of_pages,
        language,
        book_download_url,
      } = response.data;
      setBookTitle(title);
      setimageToView({
        fileName: null,
        url: img_url,
      });
      setDescription(description);
      setWhatYouWillLearn(what_you_will_learn);
      setAboutTheGuideBook(about_guide_book);
      const finalAuthors =
        book_authors &&
        Array.isArray(book_authors) &&
        book_authors.length > 0 &&
        book_authors.map((book_author) => {
          const { author_name } = book_author || {};
          return { label: author_name, value: author_name };
        });
      setAuthors(finalAuthors);
      setPublishedOnDate(moment(published_on).format("DD/MM/YYYY"));
      setSelectedPublishedOnDate(new Date(published_on));
      setBookISBN(isbn);
      setBookNumberOfPages(number_of_pages);
      setBookDownloadURL(book_download_url);
      setBookLanguage(language);
    }
    setIsLoadingSave(false);
  };

  return (
    <div className="adminPostList">
      {isLoadingSave ? null : (
        <div className="adminPostListTitleContainer">
          <div className="adminPostListTitle">
            <p>{editId ? "Edit Book" : "Add New Book"}</p>
          </div>
        </div>
      )}
      {isLoadingSave ? (
        <div
          style={{
            width: "75%",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "5em",
          }}
        >
          <Lottie options={lottieOptions} height={200} width={400} />
          <h4
            style={{
              color: "#0D4C86",
            }}
          >
            LOADING
          </h4>
          <p
            style={{
              color: "#0D4C86",
            }}
          >
            Processing your request, please wait...
          </p>
        </div>
      ) : (
        <div className="adminPostAdd">
          <form>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Title</div>
              </div>
              <textarea
                required
                name="workshopTitle"
                type="text"
                className="xSmallInputBox"
                placeholder="Please enter title here..."
                value={bookTitle}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setBookTitle)
                }
              ></textarea>
              {bookTitleEmpty && !bookTitle ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="eachLabel">Upload image</div>
              <div
                className={
                  !imageToView
                    ? "inputImageContainer"
                    : "inputImageContainerNoPointer"
                }
                onClick={() =>
                  !imageToView
                    ? document.querySelector(`.input-field-book-image`).click()
                    : null
                }
              >
                <input
                  type="file"
                  accept="image/*"
                  className={`input-field-book-image`}
                  hidden
                  onChange={({ target: { files } }) => onImageChange(files)}
                />
                <div className="imageToViewContainer">
                  <img
                    src={
                      imageToView && imageToView.url
                        ? imageToView.url
                        : uploadImageBtn
                    }
                    style={
                      imageToView && imageToView.url
                        ? { maxWidth: 250, maxHeight: 175 }
                        : { maxWidth: 35, maxHeight: 35 }
                    }
                    alt="coverImage"
                  ></img>
                  {imageToView && imageToView.fileName ? (
                    <div className="imageFileNameFontStyle">
                      {imageToView.fileName}
                    </div>
                  ) : null}
                </div>
                {imageToView && (
                  <div
                    className="btnChangeImage"
                    onClick={() =>
                      document.querySelector(`.input-field-book-image`).click()
                    }
                  >
                    <img
                      src={iconChangeImage}
                      alt="iconChangeImage"
                      width={17}
                      height={17}
                    ></img>
                    <div className="changeImageLabel">Change image</div>
                  </div>
                )}
                {imageToView ? null : (
                  <div className="labelImage">
                    {"Drag & drop your file(s) here"}
                  </div>
                )}
                {imageToView ? null : (
                  <div className="placeholderImage">
                    {"Recommended size is 230 x 260 px"}
                  </div>
                )}
              </div>
              {errorBookImage &&
              (!imageToView || (imageToView && !imageToView.url)) ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
              {imageFileTooLargeMsg && (
                <div className="errorFailedToUpload">
                  <p>{imageFileTooLargeMsg}</p>
                </div>
              )}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Description</div>
              </div>
              <EditorText
                onChange={(content) => onInputChange(content, setDescription)}
                preloadValue={description ? description : null}
              />
              {descriptionEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">What you will learn?</div>
              </div>
              <EditorText
                onChange={(content) =>
                  onInputChange(content, setWhatYouWillLearn)
                }
                preloadValue={whatYouWillLearn ? whatYouWillLearn : null}
              />
              {whatYouWillLearnEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">About the guide book</div>
              </div>
              <EditorText
                onChange={(content) =>
                  onInputChange(content, setAboutTheGuideBook)
                }
                preloadValue={aboutTheGuideBook ? aboutTheGuideBook : null}
              />
              {aboutTheGuideBookEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Author(s)</div>
              </div>
              <WorkshopDropdownSelect
                placeholder="Press enter to create new author..."
                selectStyle={{
                  borderRadius: "40px",
                  fontSize: "15px",
                  padding: "5px",
                  borderColor: "#0D4C86",
                }}
                optionStyle={{
                  backgroundColor: "#0D4C86",
                  fontSize: "15px",
                  color: "#FFFFFF",
                }}
                createAble={true}
                isMulti={true}
                onChange={(e) => onDropdownChange(e, setAuthors, true)}
                value={authors}
              />
              {authorsEmpty && authors.length <= 0 ? (
                <div className="errorMessage">
                  <p>This field is required.</p>
                </div>
              ) : null}
            </div>
            <div className="typeOfWorkAndTagInput">
              <div className="eachContainer">
                <div className="eachLabel">Published on</div>
                <DatePicker
                  selected={
                    selectedPublishedOnDate
                      ? selectedPublishedOnDate
                      : new Date()
                  }
                  onChange={(date) => handleChangeInputDate(date)}
                  customInput={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        border: "1px solid #0d4c86",
                        borderRadius: "40px",
                        height: "50px",
                        fontSize: "15px",
                        borderColor: "#0D4C86",
                        padding: "1em",
                      }}
                    >
                      <div>
                        {publishedOnDate ? `${publishedOnDate}` : "Select date"}
                      </div>
                      <ArrowDownIcon />
                    </div>
                  }
                  showMonthDropdown
                  showYearDropdown
                  todayButton="Today"
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                />
                {publishedOnDateEmpty && !publishedOnDate ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
              <div className="eachContainer">
                <div className="eachLabel">ISBN</div>
                <input
                  required
                  name="book_isbn"
                  type="text"
                  className="inputBox"
                  placeholder="Type in book ISBN"
                  value={bookISBN}
                  onChange={({ target: { value } }) =>
                    onInputChange(value, setBookISBN)
                  }
                ></input>
                {bookISBNEmpty && !bookISBN ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="typeOfWorkAndTagInput">
              <div className="eachContainer">
                <div className="eachLabel">Number of pages</div>
                <input
                  required
                  name="bookNumberOfPages"
                  type="number"
                  className="inputBox"
                  placeholder="Type in book number of pages"
                  value={bookNumberOfPages}
                  onChange={({ target: { value } }) =>
                    onInputChange(value, setBookNumberOfPages)
                  }
                ></input>
                {bookNumberOfPagesEmpty && !bookNumberOfPages ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
              <div className="eachContainer">
                <div className="eachLabel">Language</div>
                <input
                  required
                  name="bookLanguage"
                  type="text"
                  className="inputBox"
                  placeholder="Type in book language"
                  value={bookLanguage}
                  onChange={({ target: { value } }) =>
                    onInputChange(value, setBookLanguage)
                  }
                ></input>
                {bookLanguageEmpty && !bookLanguage ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Book download URL</div>
              </div>
              <textarea
                required
                name="bookDownloadURL"
                type="text"
                className="xSmallInputBox"
                placeholder="Please input the supporting link here..."
                value={bookDownloadURL}
                onChange={({ target: { value } }) =>
                  onInputChange(value, setBookDownloadURL)
                }
              ></textarea>
              {bookDownloadURLEmpty && !bookDownloadURL ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
              {invalidBookDownloadURL ? (
                <div className="errorMessage">
                  <p>Please input the correct form of the website URL.</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostButtonContainer">
              <div>
                <span
                  className="postCancelButton btn"
                  onClick={() => navigate("/admin/founders-story")}
                >
                  Cancel
                </span>
              </div>
              <div>
                <button
                  className="postSaveButton btn"
                  onClick={(e) => checkAllInputs(e)}
                >
                  {isLoadingSave ? (
                    <span>
                      <Loader
                        type="spin"
                        color="black"
                        height="25px"
                        width="25px"
                      />
                    </span>
                  ) : (
                    <span>Save</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default BookForm;
