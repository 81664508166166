import { useEffect, useState } from "react";

// React DOM
import { useNavigate } from "react-router-dom";

// Icons
import ISAlogo from "../../images/icons/ISA.png";
import EmailLogin from "../../images/icons/EmailLogin.png";
import PasswordLogin from "../../images/icons/PasswordLogin.png";
import EyePasswordHide from "../../images/icons/EyePasswordHide.png";
import EyePasswordShow from "../../images/icons/EyePasswordShow.png";

// Axios
import Axios from "../../api/axios";

// CSS Styling
import "../../styles/loginPage.css";

// React Form
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// React countdown timer
import Countdown, { zeroPad } from "react-countdown";

// React OTP Input
import OTPInput from "otp-input-react";

// Components
import ContainerText from "../../components/Container";
import Loader from "../../components/Loader";

// Sweetalert
import Swal from "sweetalert2";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// API
import { sendResetPassword } from "../../api/sendResetPassword";
import { resendEmailVerification } from "../../api/resendEmail";

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState("password");
  const [OTP, setOTP] = useState("");
  const [otpExpiration, setOtpExpiration] = useState("");
  const [otpValidation, setOtpValidation] = useState(false);
  const [otpCounter, setOtpCounter] = useState(0);
  const [isForgetPass, setForgetPass] = useState(false);
  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [isResetPassword, setResetPassword] = useState(false);
  const [isEmail, setEmail] = useState(false);
  const [isPassword, setPassword] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [resendEmail, setResendEmail] = useState(null);

  const userAuthentication = JSON.parse(localStorage.getItem("isVerified"));
  const userEmail = localStorage.getItem("email");

  const handleEyePassword = () => {
    if (showPassword === "password") {
      setShowPassword("text");
    } else {
      setShowPassword("password");
    }
  };

  // Google ReCaptcha
  const onChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  const resendOTP = async () => {
    try {
      const result = await Axios.post("/user/resendOTP", null, {
        headers: {
          "access-token": localStorage.getItem("access_token"),
        },
      });
      window.location.reload();
      if (result) {
        alert("OTP has been sent again");
      } else {
        alert("Something error");
      }
    } catch (err) {
      alert("Something error");
    }
  };

  const submitOTP = async () => {
    try {
      setLoading(true);
      const result = await Axios.patch(
        "/user/verifyOTP",
        {
          otp: OTP,
        },
        {
          headers: {
            "access-token": localStorage.getItem("access_token"),
          },
        }
      );
      const { otpVerification } = result.data;
      if (!result) {
        alert("Wrong OTP code");
      } else {
        localStorage.setItem("isVerified", otpVerification);
        navigate("/admin/post");
      }
    } catch (err) {
      setOtpValidation(true);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      if (isCaptchaCorrect) {
        const result = await Axios.post("/user/login", {
          email: values.email,
          password: values.password,
        });
        if (!result) {
          alert("User not found");
        } else {
          localStorage.setItem("email", result.data.email);
          localStorage.setItem("access_token", result.data.access_token);
          if (result.data.otpVerification === false) {
            const sendResult = await Axios.post("/user/sendOTP", null, {
              headers: {
                "access-token": localStorage.getItem("access_token"),
              },
            });
            if (!sendResult) {
              Swal.fire(
                "Error!",
                `OTP couldn't be sent, please immediately contact our team`,
                "error"
              );
            } else {
              localStorage.setItem("isVerified", result.data.otpVerification);
              window.location.reload();
            }
          } else {
            localStorage.setItem("isVerified", result.data.otpVerification);
            navigate("/admin/post");
          }
        }
      } else {
        Swal.fire(
          `Verify captcha`,
          `Please check the captcha box before you proceed`,
          `error`
        );
      }
    } catch (err) {
      console.log(err.response);
      if (err.response.data === "Email not verified") {
        Swal.fire({
          customClass: {
            htmlContainer: "subscriberText",
          },
          title: "Please verify your email first",
          imageUrl:
            "https://media.discordapp.net/attachments/796711355876245534/965791174315089961/3459559.jpg?width=676&height=676",
          text: "You have not yet verified your account. Please check your email and follow the verification link. If you have not received it, you can click resend email verification.",
          imageWidth: 300,
          showCloseButton: true,
          showConfirmButton: true,
          confirmButtonText: "Resend email",
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(resendEmail);
            resendEmailVerification({
              email: resendEmail,
            })
              .then(() => {
                Swal.fire(`Success`, `Email has been sent again`, `success`);
              })
              .catch((err) => {
                Swal.fire("Error!", `${err.response.data}`, "error");
              });
          }
        });
      } else {
        Swal.fire("Error!", `${err.response.data}`, "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const submitForget = async (value) => {
    try {
      const result = await sendResetPassword({
        email: value.email,
      });
      if (result) {
        Swal.fire(`Success`, `${result.data}`, `success`);
        setResetPassword(true);
      } else {
        Swal.fire(
          `Oops`,
          `Something error happened, please contact our team [500]`,
          `error`
        );
        setResetPassword(false);
      }
    } catch (err) {
      setEmailError(true);
    }
  };

  // Submit button validation - email
  const handleEmail = (e) => {
    setResendEmail(e.target.value);
    if (!e.target.value.trim().length) return setEmail(false);
    return setEmail(true);
  };

  // Submit button validation - password
  const handlePassword = (e) => {
    if (!e.target.value.trim().length) return setPassword(false);
    return setPassword(true);
  };

  // Submit button validation - password
//   const handleResetPassword = (e) => {
//     if (!e.target.value.trim().length) return setResetPassword(false);
//     return setResetPassword(true);
//   };

  const countdownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return window.location.reload();
    } else {
      return (
        <span style={{ fontSize: "13px", color: "black" }}>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  useEffect(() => {
    let mounted = true;
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      Axios.get(`user/verifiedUser/${userEmail}`, {
        headers: {
          "access-token": localStorage.getItem("access_token"),
        },
      })
        .then((res) => {
          if (mounted) {
            setOtpExpiration(res.data.otpFutureDate);
            setOtpCounter(res.data.otpCounter);
            console.log(res.data.otpCounter);
          }
        })
        .catch((err) => {
          console.log("User not signed, please sign in first...");
        });
    }

    return () => (mounted = false);
  }, [otpExpiration, otpCounter]);

  return (
    <div id="loginPage">
      <div className="loginPageContainer">
        {/* OTP verification page */}
        {localStorage.getItem("access_token") &&
        userAuthentication === false ? (
          <ContainerText
            props={
              <div className="loginFormContainer">
                <div className="loginFormImage">
                  <img src={ISAlogo} alt="ISA Logo" />
                </div>
                <div className="loginFormTitle">
                  <p>Enter OTP code</p>
                </div>
                <div className="loginFormSubtitle">
                  <p>We have sent the verification code to your email</p>
                </div>
                <div className="loginForm">
                  <form onSubmit={handleSubmit(submitOTP)}>
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                      style={{ justifyContent: "center" }}
                    />
                    {otpValidation ? (
                      otpCounter === 3 ? (
                        <div
                          className="loginFormMinitext"
                          style={{ color: "#A52D2D" }}
                        >
                          <p>You have reached maximum attempt</p>
                        </div>
                      ) : (
                        <div
                          className="loginFormMinitext"
                          style={{ color: "#A52D2D" }}
                        >
                          <p>Verification failed. Please try again</p>
                        </div>
                      )
                    ) : (
                      <div className="loginFormMinitext">
                        <p>
                          If you didn't receive code, click{" "}
                          {new Date() < new Date(otpExpiration) ? (
                            <span style={{ fontWeight: "700" }}>Resend</span>
                          ) : (
                            <span
                              style={{
                                fontWeight: "700",
                                color: "#2AAADA",
                                cursor: "pointer",
                              }}
                              onClick={resendOTP}
                            >
                              Resend
                            </span>
                          )}
                        </p>
                      </div>
                    )}
                    <div style={{ textAlign: "center" }}>
                      {otpValidation ? (
                        new Date() < new Date(otpExpiration) ? (
                          <div className="loginFormMinitext">
                            <span style={{ fontWeight: "700" }}>Resend in</span>
                          </div>
                        ) : (
                          <div className="loginFormMinitext">
                            <span
                              style={{
                                fontWeight: "700",
                                color: "#2AAADA",
                                cursor: "pointer",
                              }}
                              onClick={resendOTP}
                            >
                              Resend in
                            </span>
                          </div>
                        )
                      ) : null}
                      {new Date() < new Date(otpExpiration) ? (
                        <Countdown
                          date={otpExpiration}
                          zeroPadTime={2}
                          renderer={countdownRenderer}
                        />
                      ) : null}
                    </div>
                    <button className="btn" type="submit">
                      Submit
                    </button>
                  </form>
                  {isLoading ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1em",
                      }}
                    >
                      <Loader
                        type="spin"
                        color="black"
                        height="25px"
                        width="25px"
                      />
                    </span>
                  ) : null}
                </div>
              </div>
            }
          />
        ) : null}
        {/* Login Page */}
        {!localStorage.getItem("access_token") && !isForgetPass ? (
          <ContainerText
            props={
              <div className="loginFormContainer">
                <div className="loginFormImage">
                  <img src={ISAlogo} alt="ISA Logo" />
                </div>
                <div className="loginFormTitle">
                  <p>Welcome Back</p>
                </div>
                <div className="loginFormSubTitle">
                  <p>Sign in to your account</p>
                </div>
                <div className="loginForm">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="loginFormInput">
                      <label>
                        <img src={EmailLogin} alt="Email Icon" /> Email
                      </label>
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => <p>{message}</p>}
                      />
                      <input
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Enter your email address"
                        {...register("email", {
                          required: "This field is required",
                          onChange: (e) => handleEmail(e),
                        })}
                        style={{ marginBottom: "0px" }}
                      ></input>
                    </div>
                    <div className="loginFormInput">
                      <label>
                        {" "}
                        <img src={PasswordLogin} alt="Password Icon" />
                        Password
                      </label>
                      <ErrorMessage
                        errors={errors}
                        name="password"
                        render={({ message }) => <p>{message}</p>}
                      />
                      <input
                        name="password"
                        type={showPassword}
                        className="form-control"
                        placeholder="Enter your password"
                        {...register("password", {
                          required: "This field is required",
                          onChange: (e) => handlePassword(e),
                        })}
                        style={{ marginBottom: "0px" }}
                      ></input>
                      <span
                        className="loginEyePassword"
                        onClick={handleEyePassword}
                      >
                        <img
                          src={
                            showPassword === "password"
                              ? EyePasswordHide
                              : EyePasswordShow
                          }
                          alt="Eye Password"
                        />
                      </span>
                      <span
                        className="loginForgotPassword"
                        onClick={() => setForgetPass(true)}
                      >
                        <p>Forgot password?</p>
                      </span>
                    </div>
                    <ReCAPTCHA
                      style={{ marginBottom: "3em" }}
                      sitekey="6LfXMYAeAAAAAAjkbjSL22SnQ5lqP29nDdPDuAO0"
                      onChange={onChangeCaptcha}
                    />
                    <button
                      disabled={!isCaptchaCorrect || !isEmail || !isPassword}
                      className="btn"
                      type="submit"
                    >
                      Sign in
                    </button>
                  </form>
                  {isLoading ? (
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1em",
                      }}
                    >
                      <Loader
                        type="spin"
                        color="black"
                        height="25px"
                        width="25px"
                      />
                    </span>
                  ) : null}
                  <div className="authenticationPageNavigation">
                    <p>
                      Dont have an account yet?{" "}
                      <span onClick={() => navigate("/register")}>
                        Register here
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            }
          />
        ) : null}
        {localStorage.getItem("access_token") &&
        localStorage.getItem("email") &&
        localStorage.getItem("isVerified") === "true" ? (
          <ContainerText
            props={
              <div className="loginFormContainer">
                <div className="loginFormImage">
                  <img src={ISAlogo} alt="ISA Logo" />
                </div>
                <div className="loginFormTitle">
                  <p>You're logged in</p>
                  <a href="#" onClick={() => navigate("/admin/post")}>
                    Go back to Admin Page
                  </a>
                </div>
              </div>
            }
          />
        ) : null}
        {isForgetPass ? (
          <ContainerText
            props={
              <div className="loginFormContainer">
                <div className="loginFormImage">
                  <img src={ISAlogo} alt="ISA Logo" />
                </div>
                <div className="loginFormTitle">
                  <p>Reset password</p>
                </div>
                <div className="loginForm">
                  <form onSubmit={handleSubmit(submitForget)}>
                    <div className="loginFormInput">
                      <label>
                        <img src={EmailLogin} alt="Email Icon" /> Email
                      </label>
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => <p>{message}</p>}
                      />
                      <input
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Enter your email address"
                        {...register("email", {
                          required: "This field is required",
                        //   onChange: (e) => handleResetPassword(e),
                        })}
                        style={{ marginBottom: "0px" }}
                      ></input>
                      {isEmailError ? (
                        <div className="loginResetPasswordError">
                          <p>email address has not found, please try again..</p>
                        </div>
                      ) : null}
                    </div>
                    <button
                      disabled={isResetPassword}
                      className="btn"
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export default LoginPage;
