import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// Icons
import SearchIcon from "../images/icons/admin/Magnifier.png";
import ClearIcon from "../images/icons/admin/Clear.png";

// CSS styling
import "../styles/search.css";

// Moment.js
import moment from "moment";

const SearchBar = ({ selectedValue, workshops, testimonials, params }) => {
  const [display, setDisplay] = useState(false);
  const [options, setOptions] = useState([]);
  const [newOptions, setNewOptions] = useState(0);
  const [search, setSearch] = useState("");
  const [height, setHeight] = useState(0);

  const wrapperRef = useRef(null);
  const resultRef = useRef(null);
  const filterRef = useRef(null);

  const navigate = useNavigate();

  // Page navigation onClick
  const navigatePage = (path) => {
    navigate(path);
    window.location.reload();
  };

  // Close dropdown function
  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      return setDisplay(false);
    }
  };

  // On change search
  const onChangeSearch = (event) => {
    setSearch(event.target.value);
    setDisplay(true);
  };

  // Clear search
  const clearSearch = () => {
    return setSearch("");
  };

  // Close dropdown
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Set max-height of result to make scrollable
  useEffect(() => {
    const newHeight = resultRef.current?.clientHeight;
    return setHeight(newHeight * 3 + 20 * 4); // newHeight multiplied by 3 to show only 3 elements, 20px is the margin of every included element
  }, [display, search]);

  // Dynamic number search result
  useEffect(() => {
    if (params === "post") {
      const newOptions = options.filter(
        ({ title, category, author, description }) =>
          title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
          category.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
            -1 ||
          author.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
          description.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
            -1
      );
      return setNewOptions(newOptions.length);
    } else if (params === "isaAcademy-workshops") {
      const newWorkshopOptions = options.filter(
        ({ title, author }) =>
          title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
          author.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
      return setNewOptions(newWorkshopOptions.length);
    } else {
      const newTestimonialOptions = options.filter(
        ({ title, name, author }) =>
          title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
          name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1 ||
          author.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
      );
      return setNewOptions(newTestimonialOptions.length);
    }
  }, [params, search]);

  // Rearrange value from props
  useEffect(() => {
    if (params === "post") {
      const values = selectedValue.map((value) => {
        return {
          id: value.id,
          title: value.title,
          author: value.User.username,
          category: value.contentType,
          description: value.description,
          date: value.date,
        };
      });
      return setOptions(values);
    } else if (params === "isaAcademy-workshops") {
      const finalWorkshops = workshops.map((workshop) => {
        return {
          id: workshop.id,
          title: workshop.workshopTitle,
          author: workshop.postedBy,
          date: moment(workshop.lastModified).format("DD MMMM YYYY"),
          schedule: workshop.schedule,
        };
      });
      return setOptions(finalWorkshops);
    } else {
      const finalTestimonials = testimonials.map((testimonial) => {
        return {
          id: testimonial.id,
          title: testimonial.workshopTitle,
          author: testimonial.postedBy,
          date: moment(testimonial.lastModified).format("DD MMMM YYYY"),
          name: testimonial.name,
        };
      });
      return setOptions(finalTestimonials);
    }
  }, [params, selectedValue, workshops, testimonials, search]);

  return (
    <div className="searchBar" ref={wrapperRef}>
      <span className="searchIconMagnifier">
        <img src={SearchIcon} alt="Search icon" />
      </span>
      <div className="searchBarContainer">
        <input
          placeholder="Search..."
          value={search}
          onChange={(event) => onChangeSearch(event)}
        />
        {search ? (
          <span className="searchIconClear" onClick={clearSearch}>
            <i>
              <img src={ClearIcon} alt="Clear icon" />
            </i>
          </span>
        ) : null}
        {display && (
          <div
            className="searchResult"
            style={{ maxHeight: height, overflowY: "auto" }}
          >
            <div className="searchFilterBy">
              {params === "post" ? (
                <p
                  ref={filterRef}
                  style={{ margin: "1em", marginBottom: "2em" }}
                >
                  Filter by: <span>All results</span> | <span>Articles</span> |{" "}
                  <span>Events</span> | <span>Members</span>
                </p>
              ) : params === "isaAcademy-workshops" ? (
                <p
                  ref={filterRef}
                  style={{ margin: "1em", marginBottom: "2em" }}
                >
                  Filter by: <span>Workshop Title</span> |{" "}
                  <span>Posted by</span>
                </p>
              ) : (
                <p
                  ref={filterRef}
                  style={{ margin: "1em", marginBottom: "2em" }}
                >
                  Filter by: <span>Testimonial Name</span> |{" "}
                  <span>Posted by</span>
                </p>
              )}
            </div>
            <p
              style={{
                marginLeft: "1.5em",
                marginBottom: 0,
                fontSize: "11px",
                color: "#798692",
              }}
            >
              Showing {newOptions} results
            </p>
            {params === "post" && newOptions > 0 ? (
              options
                .filter(
                  ({ title, category, author, description }) =>
                    title
                      .toLocaleLowerCase()
                      .indexOf(search.toLocaleLowerCase()) > -1 ||
                    category
                      .toLocaleLowerCase()
                      .indexOf(search.toLocaleLowerCase()) > -1 ||
                    author
                      .toLocaleLowerCase()
                      .indexOf(search.toLocaleLowerCase()) > -1 ||
                    description
                      .toLocaleLowerCase()
                      .indexOf(search.toLocaleLowerCase()) > -1
                )
                .map((value) => {
                  return (
                    <div
                      className="searchResultOption"
                      key={value.id}
                      ref={resultRef}
                      onClick={() =>
                        navigatePage(`/admin/post/edit/${value.id}`)
                      }
                    >
                      <div>
                        <p style={{ fontSize: "16px", fontWeight: "500" }}>
                          {value.title}
                          <span style={{ fontSize: "10px", marginLeft: "1em" }}>
                            <button
                              style={{
                                background: "#F0F8FF",
                                borderRadius: "3px",
                                border: "none",
                              }}
                            >
                              {value.category}
                            </button>
                          </span>
                        </p>
                      </div>
                      <div>
                        <p style={{ fontSize: "11px", maxWidth: "50%" }}>
                          {value.description}
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "#798692", fontSize: "10px" }}>
                          Posted on {value.date}
                          <span
                            style={{ marginLeft: "0.5em", color: "#0D4C86" }}
                          >
                            By {value.author}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })
            ) : params === "isaAcademy-workshops" && newOptions > 0 ? (
              options
                .filter(
                  ({ title, author }) =>
                    title
                      .toLocaleLowerCase()
                      .indexOf(search.toLocaleLowerCase()) > -1 ||
                    author
                      .toLocaleLowerCase()
                      .indexOf(search.toLocaleLowerCase()) > -1
                )
                .map((value) => {
                  return (
                    <div
                      className="searchResultOption"
                      key={value.id}
                      ref={resultRef}
                      onClick={() =>
                        navigatePage(
                          `/admin/isaAcademy-workshops/edit/${value.id}`
                        )
                      }
                    >
                      <div>
                        <p style={{ fontSize: "16px", fontWeight: "500" }}>
                          {value.title}
                          <span style={{ fontSize: "10px", marginLeft: "1em" }}>
                            <button
                              style={{
                                background: "#F0F8FF",
                                borderRadius: "3px",
                                border: "none",
                              }}
                            >
                              {value.schedule < 2
                                ? `${value.schedule} schedule`
                                : `${value.schedule} schedules`}
                            </button>
                          </span>
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "#798692", fontSize: "10px" }}>
                          Posted on {value.date}
                          <span
                            style={{ marginLeft: "0.5em", color: "#0D4C86" }}
                          >
                            By {value.author}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })
            ) : params === "isaAcademy-testimonial" && newOptions > 0 ? (
              options
                .filter(
                  ({ title, author, name }) =>
                    title
                      .toLocaleLowerCase()
                      .indexOf(search.toLocaleLowerCase()) > -1 ||
                    author
                      .toLocaleLowerCase()
                      .indexOf(search.toLocaleLowerCase()) > -1 ||
                    name
                      .toLocaleLowerCase()
                      .indexOf(search.toLocaleLowerCase()) > -1
                )
                .map((value) => {
                  return (
                    <div
                      className="searchResultOption"
                      key={value.id}
                      ref={resultRef}
                      onClick={() =>
                        navigatePage(
                          `/admin/isaAcademy-testimonial/edit/${value.id}`
                        )
                      }
                    >
                      <div>
                        <p style={{ fontSize: "16px", fontWeight: "500" }}>
                          {value.name}
                        </p>
                      </div>
                      <div>
                        <p style={{ fontSize: "11px", maxWidth: "50%" }}>
                          {value.title}
                        </p>
                      </div>
                      <div>
                        <p style={{ color: "#798692", fontSize: "10px" }}>
                          Posted on {value.date}
                          <span
                            style={{ marginLeft: "0.5em", color: "#0D4C86" }}
                          >
                            By {value.author}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div style={{ margin: "1em", paddingBottom: "3em" }}>
                <p>No results found. Please try another keywords.</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
