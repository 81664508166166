// Axios
import Axios from "./axios";

export const fetchFounderStories = () => {
  return new Promise((resolve, reject) => {
    const data = Axios.get(`/founderStory`);
    resolve(data);
  });
};

export const fetchFounderStoryDetail = (id) => {
  return new Promise((resolve, reject) => {
    const data = Axios.get(`/founderStory/${id}`);
    resolve(data);
  });
};

export const addFounderStory = (body) => {
  return new Promise((resolve, reject) => {
    const data = Axios.post(`/founderStory/create`, body, {
      headers: {
        "access-token": localStorage.getItem("access_token"),
      },
    });
    resolve(data);
  });
};

export const editFounderStory = (founderStoryId, body) => {
  return new Promise((resolve, reject) => {
    const data = Axios.put(`/founderStory/edit/${founderStoryId}`, body, {
      headers: {
        "access-token": localStorage.getItem("access_token"),
      },
    });
    resolve(data);
  });
};

export const deleteFounderStory = (founderStoryId) => {
  return new Promise((resolve, reject) => {
    const data = Axios.delete(`/founderStory/delete/${founderStoryId}`, {
      headers: {
        "access-token": localStorage.getItem("access_token"),
      },
    });
    resolve(data);
  });
};
