import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// HTML react parser
import parse from "html-react-parser";

// Components
import ContainerText from "../../components/Container";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

// Images
import RegisterIcon from "../../images/icons/RegisterIcon.png";
import WhatsappIcon from "../../images/icons/WhatsappIcon.png";
import ArrowDownIcon from "../../images/icons/ArrowDownIcon.png";
import ArrowUpIcon from "../../images/icons/ArrowUpIcon.png";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// CSS
import "../../styles/academy.css";

// import { academies } from "../../utilities/content";

// Axios and API
import { fetchWorkshopDetail } from "../../api/fetchWorkshopDetail";

// Moment.js
import moment from "moment";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DetailAcademy = () => {
  const forceUpdate = useForceUpdate();
  const { id } = useParams();

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  const [workshop, setWorkshop] = useState(null);
  const [arrSchedules, setArrSchedules] = useState([]);

  useEffect(() => {
    if (id) {
      fetchDetailWorkshop(id);
    }
  }, [id]);

  const fetchDetailWorkshop = async (workshopId) => {
    const result = await fetchWorkshopDetail(workshopId);
    if (result && result.status === 200 && result.data) {
      const { data = {}, data: { Schedules = [] } = {} } = result || {};
      const schedules =
        Schedules &&
        Array.isArray(Schedules) &&
        Schedules.length > 0 &&
        Schedules.map((schedule) => {
          return { ...schedule, isOpenInvestmentFee: false };
        });
      setArrSchedules(schedules);
      setWorkshop(data);
    }
  };

  const handleOpenScheduleInvestmentFee = (index, key, value) => {
    const finalSchedules = arrSchedules;
    finalSchedules[index] = {
      ...finalSchedules[index],
      [`${key}`]: !value,
    };
    setArrSchedules(finalSchedules);
    forceUpdate();
  };

  if (workshop) {
    const { 
      // AcademyWorkshopTags = [],
       Facilitators = [] } = workshop || {};
    return (
      <div>
        <Header />
        <div>
          <img
            src={workshop && workshop.main_image_url}
            alt="banner"
            style={{
              maxHeight: "700px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <ContainerText
            props={
              <>
                <div className="academyTitle">
                  <p>{parse(workshop.workshop_title)}</p>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginLeft: "2em",
                    marginRight: "2em",
                  }}
                >
                  <div className="eachPropertyAcademyContainer">
                    <div className="eachPropertyAcademy">
                      What you will learn
                    </div>
                    <div className="eachPropertyAcademyDesc">
                      {workshop &&
                        workshop.what_you_will_learn &&
                        parse(workshop.what_you_will_learn)}
                    </div>
                  </div>
                  {arrSchedules &&
                  Array.isArray(arrSchedules) &&
                  arrSchedules.length > 0 ? (
                    <div className="eachPropertyAcademyContainer">
                      <div className="eachPropertyAcademy">
                        Workshop Schedule
                      </div>
                      <div className="tableSchedule">
                        <table>
                          <thead>
                            <tr>
                              <th className="thSchedule">
                                <div>Schedule</div>
                              </th>
                              <th className="thDuration">Duration</th>
                              <th className="thLearningMethod">
                                Learning Method
                              </th>
                              <th className="thAction"></th>
                            </tr>
                          </thead>
                          {arrSchedules.map((schedule, index) => {
                            const {
                              id,
                              isOpenInvestmentFee,
                              investment_fee,
                              start_date,
                              end_date,
                              duration,
                              method_of_learning,
                            } = schedule || {};
                            return (
                              <tbody key={id}>
                                <tr className="adminTableRow">
                                  <td>
                                    <div className="tdScheduleValue">
                                      {moment(start_date).format(
                                        "DD MMMM YYYY"
                                      ) ===
                                      moment(end_date).format("DD MMMM YYYY")
                                        ? `${moment(start_date).format(
                                            "DD MMMM YYYY"
                                          )}`
                                        : `${moment(start_date).format(
                                            "DD"
                                          )}-${moment(end_date).format(
                                            "DD MMMM YYYY"
                                          )}`}
                                    </div>
                                  </td>
                                  <td className="tdDuration">
                                    <div>{duration}</div>
                                  </td>
                                  <td className="tdDuration">
                                    <div>{method_of_learning}</div>
                                  </td>
                                  <td>
                                    <div
                                      className="btnInvestmentFee"
                                      onClick={() =>
                                        handleOpenScheduleInvestmentFee(
                                          index,
                                          "isOpenInvestmentFee",
                                          isOpenInvestmentFee
                                        )
                                      }
                                    >
                                      <div>View Investment Fee</div>
                                      <div>
                                        <img
                                          src={
                                            isOpenInvestmentFee
                                              ? ArrowUpIcon
                                              : ArrowDownIcon
                                          }
                                          alt={
                                            isOpenInvestmentFee
                                              ? ArrowUpIcon
                                              : ArrowDownIcon
                                          }
                                          className="arrowUpDownIcon"
                                        ></img>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {isOpenInvestmentFee ? (
                                  <tr
                                    className="adminTableRow"
                                    style={{ width: "100%" }}
                                  >
                                    <td>
                                      <div className="tdScheduleValue">
                                        {investment_fee &&
                                          parse(investment_fee)}
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                ) : null}
                              </tbody>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  ) : null}
                  {Facilitators &&
                  Array.isArray(Facilitators) &&
                  Facilitators.length > 0 ? (
                    <div className="eachPropertyAcademyContainer">
                      <div className="eachPropertyAcademy">Facilitator</div>
                      {Facilitators.map((facilitator, index) => {
                        const {
                          facilitator_image_url,
                          facilitator_profile,
                          facilitator_name,
                        } = facilitator || {};
                        return (
                          <div key={facilitator.id}>
                            <ContainerText
                              props={
                                <div
                                  className="allFacilitatorsContainer"
                                  style={
                                    index === Facilitators.length - 1
                                      ? {
                                          marginLeft: "-1em",
                                          marginBottom: "1em",
                                        }
                                      : {
                                          marginLeft: "-1em",
                                          marginBottom: "4em",
                                        }
                                  }
                                >
                                  <div className="eachFacilitators">
                                    <div className="eachFacilitatorsDescContainer">
                                      <div className="eachMembersPicture">
                                        <img
                                          src={facilitator_image_url}
                                          alt={facilitator_image_url}
                                        />
                                      </div>
                                      <div className="containerDesc">
                                        <div className="facilitatorName">
                                          <p>{facilitator_name}</p>
                                        </div>
                                        <div
                                          className="eachMembersDesc"
                                          style={{
                                            marginTop: "20px",
                                            fontSize: 15,
                                          }}
                                        >
                                          <p>{facilitator_profile}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <div className="eachPropertyAcademyContainer">
                    <div className="eachPropertyAcademy">Target Audience</div>
                    <div className="eachPropertyAcademyDesc">
                      {workshop &&
                        workshop.target_audience &&
                        parse(workshop.target_audience)}
                    </div>
                  </div>
                  <div className="eachPropertyAcademyContainer">
                    <div className="eachPropertyAcademy">How to Register?</div>
                    <div className="eachPropertyAcademyDesc">
                      {workshop &&
                        workshop.how_to_register &&
                        parse(workshop.how_to_register)}
                    </div>
                  </div>
                  {/* {AcademyWorkshopTags &&
                  Array.isArray(AcademyWorkshopTags) &&
                  AcademyWorkshopTags.length > 0 ? (
                    <div
                      className="eachPropertyAcademyContainer"
                      style={{ marginTop: "20px" }}
                    >
                      <div className="blogCardTags">Tags:</div>
                      {AcademyWorkshopTags.map((tag, index) => {
                        const { Tag: { tag_name } = {} } = tag;
                        return (
                          <div className="blogCardTagsBoxContainer" key={index}>
                            <div className="blogCardTagsBox">
                              <p>{tag_name}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null} */}
                  <div
                    className="contactFormSubmit"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "center",
                      marginBottom: "3em",
                    }}
                  >
                    <a
                      href={
                        workshop && workshop.register_link
                          ? `http://${workshop.register_link}`
                          : ""
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ marginRight: "3em" }}
                    >
                      <button className="btnRegister">
                        <img
                          src={RegisterIcon}
                          alt="register"
                          style={{
                            marginRight: "0.5em",
                          }}
                        />
                        Register here
                      </button>
                    </a>
                    <a
                      href="https://api.whatsapp.com/send/?phone=6281218467661&text&app_absent=0"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <button className="btnWhatsapp">
                        <img
                          src={WhatsappIcon}
                          alt="whatsapp"
                          style={{
                            marginRight: "0.5em",
                          }}
                        />
                        Whatsapp
                      </button>
                    </a>
                  </div>
                </div>
              </>
            }
          />
        </div>
        <Footer />
      </div>
    );
  } else {
    return null;
  }
};

export default DetailAcademy;
