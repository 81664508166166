// Axios
import Axios from "./axios"

export const addWorkshopTestimonial = (value) => {
    return new Promise((resolve, reject) => {
        const data = Axios.post(`/workshopTestimonial/create`, value, {
            headers: {
                'access-token': localStorage.getItem("access_token")
            }
        })
        resolve(data)
    })

}