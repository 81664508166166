import { useEffect, useState } from "react";

// React DOM
import { useParams, useNavigate } from "react-router-dom";

// Icons and Backgrounds
import SocialMedia from "../../components/SocialMedia";

// Axios
import Axios from "../../api/axios";

// CSS Styling
import "../../styles/detailBlog.css";

// HTML react parser
import parse from "html-react-parser";

// Sweet Alert v2
import Swal from "sweetalert2";

// Components
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// API
import { getNewsById } from "../../api/getNewsById";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../lotties/paperplane.json";

const DetailBlog = () => {
  const navigate = useNavigate();

  const [news, setNews] = useState([]);
  const [relatedNews, setRelatedNews] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isMobileSize, setIsMobileSize] = useState(false);
  const [isTabSize, setIsTabSize] = useState(false);

  const mediaQueryMobile = window.matchMedia("(max-width: 640px)");
  const mediaQueryTab = window.matchMedia("(max-width: 910px)");

  const { id } = useParams();

  // React lotties
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function refreshNews(id) {
    navigate(`/Blog/${id}`);
    window.location.href = "#detailBlog";
    window.location.reload(false);
  }

  const HTMLparse = (string) => {
    return parse(string);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(
      `https://www.sustainability.co.id/Blog/${id}`
    );
    Swal.fire({
      position: "center",
      imageUrl:
        "https://cdn.discordapp.com/attachments/796711355876245534/986824551537209404/copy_clipboard_icon.png",
      imageWidth: 200,
      imageHeight: 200,
      width: "45em",
      title: "Success",
      text: "Link has been copied to clipboard",
      showCloseButton: true,
      showConfirmButton: false,
      timer: 1500,
      background: "#E4E4E4",
    });
  };

  const fetchRelatedNews = async () => {
    const response = await Axios.get(`/news/relatedNews`).catch((err) => {
      console.log("Err: ", err);
    });
    console.log(response, 'response <<');
    setRelatedNews(response.data);
  };

  useEffect(() => {
    const fetchNewsById = async () => {
      setLoading(true);
      try {
        const response = await getNewsById(id);
        if (response.data.status === "Published") {
          setNews(response.data);
          HTMLparse(response.data.htmlContent);
          fetchRelatedNews();
        } else {
          Swal.fire(`Oops`, `Data not found`, `error`).then(() =>
            navigate("/Blog")
          );
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchNewsById();
  }, [id]);

  const handleDetectWindowSize = (mediaQuery, tab) => {
    if (tab === "mobile") {
      setIsMobileSize(mediaQuery.matches);
    } else {
      setIsTabSize(mediaQuery.matches);
    }
  };

  useEffect(() => {
    if (mediaQueryMobile) {
      handleDetectWindowSize(mediaQueryMobile, "mobile");
    }
  }, [mediaQueryMobile]);

  useEffect(() => {
    if (mediaQueryTab) {
      handleDetectWindowSize(mediaQueryTab, "tab");
    }
  }, [mediaQueryTab]);

  return (
    <div id="detailBlog">
      <Header />
      {!isLoading ? (
        <ContainerText
          props={
            <div className="detailBlogContainer">
              <div className="previousButton" onClick={() => navigate("/Blog")}>
                <span className="expertiseArrowRight">
                  <i className="fas fa-angle-left" />
                </span>
                <a href="">Back to previous page</a>
              </div>
              <div className="detailBlogMainTitle">
                <p>{news.title}</p>
              </div>
              <div className="detailBlogDate">
                <p>
                  <i>
                    {news.date} – {news.contentType}
                  </i>
                </p>
                <p>
                  <i>Written by: {news.written_by ? news.written_by : "-"}</i>
                </p>
              </div>
              <div className="detailBlogSocialMedia right">
                <SocialMedia
                  twitterURL={`https://www.sustainability.co.id/Blog/${id}`}
                  facebookURL={`https://www.sustainability.co.id/Blog/${id}`}
                  linkedinURL={`https://www.sustainability.co.id/Blog/${id}`}
                  mailSubject={news.title}
                  mailContent={`Hello, I recommend you to read the articles on Institute for Sustainability and Agility (ISA): ${news.title} https://www.sustainability.co.id/Blog/${id}`}
                  copyLink={copyLink}
                />
              </div>
              <div className="detailBlogHtmlContent">
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobileSize ? "column" : "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <div style={{ paddingRight: "3em" }}>
                    <img
                      src={news.content_image}
                      alt="Content Image"
                      style={{ width: "300 px", height: "300 px" }}
                    ></img>
                  </div> */}
                  <div style={{ marginTop: isMobileSize ? "0.5em" : "-0.5em" }}>
                    {HTMLparse(`${news.htmlContent}`)}
                  </div>
                </div>
              </div>
              <div className="detailBlogSocialMedia left">
                <SocialMedia
                  twitterURL={`https://www.sustainability.co.id/Blog/${id}`}
                  facebookURL={`https://www.sustainability.co.id/Blog/${id}`}
                  linkedinURL={`https://www.sustainability.co.id/Blog/${id}`}
                  mailSubject={news.title}
                  mailContent={`Hello, I recommend you to read the articles on Institute for Sustainability and Agility (ISA): ${news.title} https://isa-stg.cloudxier.com/Blog/${id}`}
                  copyLink={copyLink}
                />
              </div>
            </div>
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            paddingTop: "15em",
          }}
        >
          <Lottie options={lottieOptions} height={200} width={400} />
          <h4
            style={{
              color: "#0D4C86",
            }}
          >
            LOADING
          </h4>
          <p
            style={{
              color: "#0D4C86",
            }}
          >
            Processing your request, please wait...
          </p>
        </div>
      )}
      {relatedNews.length > 0 ? (
        <div className="detailUpdates">
          <ContainerText
            props={
              <div className="detailUpdatesContainer">
                <div className="detailUpdatesMainTitle">
                  <p>Related Updates</p>
                </div>
                <div className="detailUpdatesCardContainer">
                  {relatedNews.map((element) => {
                    return element.status === "Published" ? (
                      <div className="detailUpdatesCardEach" key={element.id}>
                        <div className="detailUpdatesCardImage">
                          <img src={element.picture} alt="Card blog" />
                        </div>
                        <div className="detailUpdatesCardTitle">
                          <p onClick={() => refreshNews(element.id)}>
                            {element.title}
                          </p>
                        </div>
                        <div className="detailUpdatesCardDate">
                          <p>
                            <i>
                              {element.date} – {element.contentType}
                            </i>
                          </p>
                        </div>
                        <div className="detailUpdatesCardSubTitle">
                          <p>{element.description}</p>
                        </div>
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            }
          />
        </div>
      ) : null}
      <Footer />
    </div>
  );
};

export default DetailBlog;
