import React from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const WorkshopDropdownSelect = ({
  options,
  placeholder = null,
  inputRef,
  value,
  onChange,
  selectStyle,
  optionStyle,
  isMulti = false,
  createAble = false,
  handleOnCloseDropdown = null,
  handleOnOpenDropdown = null,
}) => {
  if (createAble) {
    return (
      <CreatableSelect
        ref={inputRef}
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        isClearable={value.some((v) => !v.isFixed)}
        styles={{
          control: (provided, state) => ({
            ...provided,
            ...selectStyle,
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              ...optionStyle,
              cursor: isDisabled ? "not-allowed" : "default",
            };
          },
          multiValue: (styles, { data }) => {
            return {
              ...styles,
              backgroundColor: "#0D4C86",
              borderRadius: "20px",
              color: "#FFFFFF",
              padding: "2.5px",
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "white",
            ":hover": {
              backgroundColor: "#0D4C86",
              color: "white",
              borderRadius: "20px",
            },
          }),
        }}
      />
    );
  } else {
    return (
      <Select
        ref={inputRef}
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        styles={{
          control: (provided, state) => ({
            ...provided,
            ...selectStyle,
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              ...optionStyle,
              cursor: isDisabled ? "not-allowed" : "default",
            };
          },
        }}
        onMenuOpen={handleOnOpenDropdown}
        onMenuClose={handleOnCloseDropdown}
      />
    );
  }
};

export default WorkshopDropdownSelect;
