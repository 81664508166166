// Axios
import Axios from "./axios";

export const fetchBooks = () => {
  return new Promise((resolve, reject) => {
    const data = Axios.get(`/books`);
    resolve(data);
  });
};

export const fetchBookDetails = (id) => {
  return new Promise((resolve, reject) => {
    const data = Axios.get(`/books/${id}`);
    resolve(data);
  });
};

export const createNewBook = (body) => {
  return new Promise((resolve, reject) => {
    const data = Axios.post(`/books/create`, body, {
      headers: {
        "access-token": localStorage.getItem("access_token"),
      },
    });
    resolve(data);
  });
};

export const editBook = (book_id, body) => {
  return new Promise((resolve, reject) => {
    const data = Axios.put(`/books/edit/${book_id}`, body, {
      headers: {
        "access-token": localStorage.getItem("access_token"),
      },
    });
    resolve(data);
  });
};

export const deleteBook = (book_id) => {
  return new Promise((resolve, reject) => {
    const data = Axios.delete(`/books/delete/${book_id}`, {
      headers: {
        "access-token": localStorage.getItem("access_token"),
      },
    });
    resolve(data);
  });
};
