import { useEffect } from "react";

// React DOM
import { useNavigate, useParams } from "react-router-dom";

// CSS styling
import "../../styles/privacy.css";
import "../../styles/previousButton.css";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// Components
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const PrivacyStatement = () => {
  const { params } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <div>
      <Header />
      {/* Privacy Statement */}
      {params === "PrivacyStatement" ? (
        <div id="privacyStatement">
          <ContainerText
            props={
              <div className="privacyStatementContainer">
                <div className="privacyPageTitle">
                  <p>Privacy Policy Statement</p>
                </div>
                <div className="privacyDate">
                  <p>
                    <i>Updated as per March 4, 2022</i>
                  </p>
                </div>
                <div className="privacyParagraph">
                  <p>
                    Institute for Sustainability and Agility (ISA) values
                    privacy and is committed to protecting your personal
                    information.
                    <br />
                    <br />
                    We collect your personal information in a number of
                    different ways and for different purposes. How and what we
                    collect and how we manage your information is fully set out
                    in the privacy statement below.
                    <br />
                    <br />
                    <strong>
                      Institute for Sustainability and Agility (ISA)
                    </strong>{" "}
                    is a subsidiary brand{" "}
                    <strong>
                      under the legal entity of PT CENDEKIA EDUKASI CITRA
                      TRITUNGGAL
                    </strong>{" "}
                    by guarantee with its registered address at AKR Tower 16
                    <sup>th</sup> Floor Unit 16A - Indonesia (“ISA”), operating
                    as an Indonesia-based company in Sustainability Education
                    and Consultancy.
                    <br />
                    <br />
                    We are committed to respecting your privacy and protecting
                    your personal data, which is any information that is capable
                    of identifying you as an individual person. This Privacy
                    Policy describes how we handle and protect your personal
                    data in connection with ISA's business activities, like
                    webinars and events, and client services, and on websites
                    and communications that post a link to this Privacy Policy
                    (collectively, “the Website”), in our capacity as data
                    controllers.
                  </p>
                </div>
                <div className="privacyContentContainer">
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Use of information</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        ISA uses your personal data to fulfill your requests for
                        information, process your requests to participate in
                        webinars and events, personalize content that you view
                        or receive on the Sites, evaluate and improve our
                        services, distribute newsletters to you, analyze the
                        Website’s performance and functioning, prevent fraud,
                        enforce our terms of use, comply with all applicable
                        laws and corporate reporting obligations, enforce ISA’s
                        agreements, and accomplish other purposes you may
                        initiate or request.
                        <br />
                        <br />
                        The collection of personal data may be required for the
                        operation of the Website, or we may use it in the course
                        of our business activities, including in connection with
                        some client services, for example, to provide certain
                        services or benchmarking our products.
                        <br />
                        <br />
                        We may combine and/or analyze personal data to evaluate
                        and offer content and services most relevant to you. We
                        may keep any of your personal data on file and use it to
                        contact you.
                        <br />
                        <br />
                        ISA and its service providers may use first and third
                        party Cookies and Other Tracking Technologies, including
                        web beacons, to manage our website and our services and
                        collect analytics about how you use them. ISA and its
                        service providers may collect information about whether
                        you open or click any links in the knowledge, research
                        or event communications that we send to you. The
                        information provided throughout this Privacy Policy
                        about cookies also applies to these other tracking
                        technologies. Please refer to our Cookie Policy for more
                        details regarding our use of Cookies and Other Tracking
                        Technologies.
                        <br />
                        <br />
                        Our Sites do not track you by collecting personal data
                        about your online activities over time and across third
                        party websites or online services. Accordingly, we do
                        not alter our data collection and use practices in
                        response to “do not track” signals transmitted from web
                        browsers.
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>What data we collect</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        If you visit our website, the data we collect from you
                        are as follows:
                        <br />
                        <ul>
                          <li>IP address</li>
                          <li>cookies ID</li>
                          <li>
                            personal information includes, but is not limited
                            to: name, email address, date of birth, occupation,
                            phone number, gender
                          </li>
                        </ul>
                        <br />
                        IP address is a numerical label assigned to each device
                        connected to the Internet Protocol for communication and
                        required to show you websites, images, files and other
                        information on the Internet. IP address typically can be
                        used to find out the location of your device, generally
                        down to the city level. In case of business IP address,
                        it can be matched with a business name and street
                        address.
                        <br />
                        <br />
                        Cookies are small text files that are sent from a
                        website and stored on your computer or other device by
                        your web browser when you visit a site. Cookies are
                        created when you visit a website that uses cookies to
                        recognize you, keep track of your interaction with the
                        site, remember your preferences and make all site’s
                        functions available for you.
                        <br />
                        <br />
                        Cookies, among other information, typically contain ID
                        tags. A website that uses cookies stores a file with
                        same ID tags corresponding to cookies placed on your
                        computer. When you visit the site again, your browser
                        sends the relevant cookies from your computer to the
                        website. Using cookies ID tags, website, for instance,
                        can recognize you as a returning visitor and apply your
                        preferences automatically.
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>How we collect data</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        IP address of your networked device is automatically
                        captured whenever a connection is made over the Internet
                        regardless of your consent.
                        <br />
                        <br />
                        This website uses cookies and requests your consent
                        before placing any persistent cookies on your computer.
                        You can accept our website’s cookies by clicking “
                        <strong>Accept</strong>” button in the cookie consent
                        banner that pops up at the bottom of the website’s page
                        you open.
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>How we store data</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        IP addresses and cookies ID tags are logged
                        automatically and kept in the relevant log files.
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>How we use data</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        We use IP addresses of our website’s visitors for
                        security reasons, particularly to identify malicious
                        site visits and hacking attempts, to protect the
                        website’s data and traffic and make it safer for anyone
                        visiting and using the website. Thus, we collect and use
                        IP addresses of our website’s visitors based on
                        legitimate interest.
                        <br />
                        <br />
                        We will not disclose IP addresses of the website’s
                        visitors to any third party except where required to do
                        so by law.
                        <br />
                        <br />
                        We use cookies ID tags to recognize you, keep track of
                        your interaction with the site, remember your
                        preferences and make all site’s functions available for
                        you. Since we may use third-party service providers to
                        track and report websites traffic, those third-party
                        service providers may place their own cookies on your
                        computer and use the relevant cookies ID tags to measure
                        and analyze information of your visits.
                        <br />
                        <br />
                        We collect and use cookies ID tags based on your
                        acceptance of the website’s cookies. For more
                        information about how we use cookies, how you can
                        accept, manage or withdraw you consent for use of
                        cookies please check our Cookie Notice
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Protection of Personal Data</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        To safeguard your personal data from unauthorized
                        access, collection, use, disclosure, copying,
                        modification, disposal or similar risks, we have
                        introduced appropriate administrative, physical and
                        technical measures such as up-to-date antivirus
                        protection, encryption and the use of privacy filters to
                        secure all storage and transmission of personal data by
                        us, and disclosing personal data both internally and to
                        our authorized third party service providers and agents
                        only on a need-to-know basis.
                        <br />
                        <br />
                        You should be aware, however, that no method of
                        transmission over the Internet or method of electronic
                        storage is completely secure. While security cannot be
                        guaranteed, we strive to protect the security of your
                        information and are constantly reviewing and enhancing
                        our information security measures.
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Sharing of personal information which we collect</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        We may disclose to, allow access to or share with
                        affiliates and other parties your Personal Information
                        for any of the following purposes as well as for such
                        other purposes as are permitted by applicable law:
                        <br />
                        <ul>
                          <li>
                            where required or authorised by applicable law
                            (including but not limited to responding to
                            regulatory enquiries, investigations or directives,
                            or complying with statutory or regulatory filing and
                            reporting, and licensing requirements), for the
                            purpose so specified in that applicable law
                          </li>
                          <li>
                            where instructed, requested, required or authorised
                            by the government authorities, for the purpose as
                            specified in the government policy, regulations or
                            applicable law;
                          </li>
                          <li>
                            where there is any form of legal proceeding between
                            you and us, or between you and another party, in
                            connection with, or relating to the services, for
                            the purposes of that legal proceeding;
                          </li>
                          <li>
                            where we enable our services in third parties’
                            platform, (i) to assist us in obtaining your
                            Personal Information and/or (ii) to register you or
                            allow you to use our services from such platforms.
                          </li>
                          <li>
                            to third parties (including our agents, vendors,
                            suppliers, partners and any others who provide
                            services to us or you, perform functions on our
                            behalf, or whom we enter into commercial
                            collaboration with) for or in connection with the
                            purposes for which such third parties are engaged,
                            to perform certain disclosure to the relevant third
                            parties which are technically required to process
                            your transaction or for the purposes of our
                            collaboration with such third parties (as the case
                            may be), which may include allowing such third
                            parties to introduce or offer products or services
                            to you, authenticate you or connect with your
                            Account, or conducting other activities including
                            marketing, research, analysis and product
                            development; and
                          </li>
                          <li>
                            where we share Personal Information with affiliates,
                            we will do so for the purpose of them helping us to
                            to operate our business (including, where you have
                            subscribed to our mailing list, for direct marketing
                            purposes), or for the purpose of them conducting
                            data processing on our behalf. All of our affiliates
                            are committed to processing the Personal Information
                            that they receive from us in line with this Privacy
                            Policy and applicable law.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Retention of Personal Data</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        We may retain your personal data for as long as it is
                        necessary to fulfill the purpose for which it was
                        collected, or as required or permitted by applicable
                        laws.
                        <br />
                        <br />
                        We will cease to retain your personal data, or remove
                        the means by which the data can be associated with you,
                        as soon as it is reasonable to assume that such
                        retention no longer serves the purpose for which the
                        personal data was collected, and is no longer necessary
                        for legal or business purposes.
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Your Personal Data Protection Rights</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        We would like to make sure you are fully aware of your
                        data protection rights.
                        <br />
                        <br />
                        <strong>The right to withdraw.</strong> You have the
                        right to recall the consent at any time. But please note
                        that it does not affect the possibility to continue
                        processing your personal data based on your consent,
                        which was given prior to its recall.
                        <br />
                        <br />
                        <strong>The right to access.</strong> You have the right
                        to request from us for copies of your personal data,
                        under certain conditions. We may charge you a fee for
                        this service.
                        <br />
                        <br />
                        <strong>The right to rectification.</strong> You have
                        the right to request from us to correct any information
                        you believe is inaccurate. You also have the right to
                        request us to complete information you believe is
                        incomplete.
                        <br />
                        <br />
                        <strong>The right to erasure.</strong> You have the
                        right to request from us to erase your personal data,
                        under certain conditions.
                        <br />
                        <br />
                        <strong>The right to restrict processing.</strong> You
                        have the right to request from us to restrict the
                        processing of your personal data, under certain
                        conditions.
                        <br />
                        <br />
                        <strong>The right to object to processing.</strong> You
                        have the right to object to our processing of your
                        personal data, under certain conditions.
                        <br />
                        <br />
                        <strong>The right to data portability.</strong> You have
                        the right to request from us to transfer the data that
                        we have collected to another organization, or directly
                        to you, under certain conditions.
                        <br />
                        <br />
                        <strong>The right on information.</strong> You have the
                        right to be informed about a personal data breach in
                        certain cases.
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Privacy policies of other websites</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        Our website contains links to other websites. This
                        Privacy Policy applies only to our website, so if you
                        click on a link to another website, you should read
                        their privacy policy.
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Changes To This Privacy Statement</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        We may update this privacy statement at any time by
                        publishing an updated version here. So you know when we
                        make changes to this privacy statement, we will amend
                        the revision date at the top of this page. The new
                        modified or amended privacy statement will apply from
                        that revision date. Therefore, we encourage you to
                        review this privacy statement periodically to be
                        informed about how we are protecting your information.
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Acknowledgement and Consent</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        By accepting the Privacy Policy, you acknowledge that
                        you have read and understood this Privacy Policy and you
                        accept all of its terms. In particular, you agree and
                        consent to us collecting, using, sharing, disclosing,
                        storing, transferring, or otherwise processing your
                        Personal Information in accordance with this Privacy
                        Policy.
                        <br />
                        <br />
                        In circumstances where you provide us with Personal
                        Information relating to other individuals (such as
                        Personal Information relating to your spouse, family
                        members, friends, or other parties), you represent and
                        warrant that you have obtained such individual’s consent
                        for, and hereby consent on behalf of such individual to,
                        the collection, use, disclosure and processing of
                        his/her Personal Information by us.
                        <br />
                        <br />
                        You may withdraw your consent to any or all collection,
                        use or disclosure of your Personal Information at any
                        time by giving us reasonable notice in writing using the
                        contact details stated below. You may also withdraw your
                        consent for us to send you certain communications and
                        information via any “opt-out” or “unsubscribe” facility
                        contained in our messages to you.
                        <br />
                        <br />
                        Depending on the circumstances and the nature of the
                        consent which you are withdrawing, you must understand
                        and acknowledge that after such withdrawal of consent,
                        you may no longer be able to access our website or
                        service. a Withdrawal of consent by you may result in
                        the termination of your account or of your contractual
                        relationship with us, with all accrued rights and
                        obligations remaining fully reserved. Upon receipt of
                        your notice to withdraw consent for any collection, use
                        or disclosure of your Personal Information, we will
                        inform you of the likely consequences of such withdrawal
                        so that you can decide if indeed you wish to withdraw
                        consent.
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>How to contact us</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        If you have any questions regarding this Privacy
                        Statement or you would like to obtain access to your
                        Personal Information, please contact{" "}
                        <a href="mailto:company@sustainability.co.id">
                          company@sustainability.co.id
                        </a>
                        <br />
                        <br />
                        This privacy statement was last updated on 4th March
                        2022
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      ) : null}

      {/* Sustainability Policy */}
      {params === "Sustainability" ? (
        <div id="privacyStatement">
          <ContainerText
            props={
              <div className="privacyStatementContainer">
                {/* <div className="previousButton" onClick={() => navigate("/Contact")}>
                                <span className="expertiseArrowRight"><i className="fas fa-angle-left"/></span>
                                <a href="">
                                        Back to contact
                                </a>
                            </div> */}
                <div className="privacyContentContainer">
                  <div className="privacyEachContent">
                    <div className="privacyPageTitle">
                      <p>Sustainability Policy Statement</p>
                    </div>
                    <div className="privacyDate">
                      <p>
                        <i>Updated as per March 4, 2022</i>
                      </p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        This policy applies to the Institute for Sustainability
                        and Agility (ISA) including its employees, associates,
                        facilitators, communities, and third parties who
                        undertake activity for and on behalf of ISA. It applies
                        to the goods and services we procure, our direct
                        operations and the services we provide to our customers
                        and partners. ISA is committed to creating a sustainable
                        society actively managing our operations in a way that
                        balances our social, environmental, economic, and
                        well-being objectives. To do this, sustainability
                        considerations are tied throughout a suite of
                        interdependent policies and their procedures, which are
                        implemented collectively in order to deliver the
                        objectives of the Sustainability Policy.
                      </p>
                    </div>
                    <div className="privacyMainTitle">
                      <p>Purpose</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        To provide an effective framework for realising ISA’s
                        commitment to creating a sustainable society by
                        protecting and where possible enhancing the social,
                        environmental, well-being and economic impacts,
                        empowering partnership for the goals, providing quality
                        education, and driving sustainable economic development
                        for a better world.
                      </p>
                    </div>
                    <div className="privacyMainTitle">
                      <p>Policy</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        ISA is Indonesia's leading Sustainability Education and
                        Consultancy with a mission to inspire excellence and
                        make a difference. Our vision is to inspire, educate,
                        and collaborate with individuals and organizations in
                        achieving sustainability.
                        <br />
                        <br />
                        In recognising this, ISA is committed in contributing to
                        a more sustainable society and to continually improve
                        and build positive impacts by ensuring:
                        <br />
                        <ul>
                          <li>
                            Compliance with all relevant legislation and
                            standards, and the expectations of our customers,
                            partners and other key stakeholders;
                          </li>
                          <li>
                            Continually to develop our products and services to
                            enhance the sustainability initiatives for our
                            customers, partners and other key stakeholders;
                          </li>
                          <li>
                            Continually review how we can make improvements to
                            our sustainability performance;
                          </li>
                          <li>
                            Operate sustainably and strive to reduce waste,
                            prevent pollution, and minimise resource consumption
                            in all of our systems and procedures;
                          </li>
                          <li>
                            Continually to encourage our employees, customers,
                            partners and other key stakeholders to support our
                            sustainability commitments;
                          </li>
                          <li>
                            Continually educate, inspire and engage employees on
                            implementing sustainability at home, work, and
                            throughout their communities through a combination
                            of employee communications, learning sessions, and
                            hands-on opportunities;
                          </li>
                          <li>
                            Continually to create healthy, collaborative and
                            innovative work environments for our key
                            stakeholders through sustainable operations and
                            certifications whenever possible;
                          </li>
                          <li>
                            Report results of our sustainability program to our
                            employees, customers, partners and communities and
                            other key stakeholders on an annual basis;
                          </li>
                          <li>
                            Continually improve tracking of sustainability
                            metrics throughout our operations.
                          </li>
                        </ul>
                        To realise our sustainability objectives ISA recognises
                        the need for and is committed to communicating these
                        objectives to our employees, customers, partners and
                        communities and other key stakeholders. This is achieved
                        via our procurement processes, and our existing
                        communication channels to employees including the
                        business plan, newsletters, team briefings.
                        <br />
                        <br />
                        This policy and the actions arising from it will be
                        annually reviewed as part of the business planning
                        process which involves ISA’s leadership and management
                        teams, with final approval via the ISA Board.
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyPageTitle">
                      <p>Environmental Policy Statement</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        This policy applies to the Institute for Sustainability
                        and Agility (ISA) including its employees, associates,
                        facilitators, communities, and third parties who
                        undertake activity for and on behalf of ISA. It applies
                        to the goods and services we procure, our direct
                        operations and the services we provide to our customers
                        and partners. ISA operates within a suite of
                        interdependent policies and their procedures, which are
                        implemented collectively in order to deliver the
                        objectives of the Environmental Policy.
                      </p>
                    </div>
                    <div className="privacyMainTitle">
                      <p>Purpose</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        To provide an effective framework for realising ISA’s
                        commitment to protecting and where possible enhancing
                        the environment by reducing the environmental impacts,
                        preventing pollution, mitigating and adapting to climate
                        change and a low carbon future.
                      </p>
                    </div>
                    <div className="privacyMainTitle">
                      <p>Policy</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        ISA is Indonesia's leading Sustainability Education and
                        Consultancy with a mission to inspire excellence and
                        make a difference. Our vision is to inspire, educate,
                        and collaborate with individuals and organizations in
                        achieving sustainability. Our main activities are
                        Research and Publication, Stakeholder Engagement & CSR
                        Implementation, Academic Research and Review,
                        Sustainability Awards, Consultative Research, Policy
                        Research, White Papers, Tools Development, Case Study
                        Writing, and Sustainability Education. ISA recognises
                        that its activities impact the environment in a number
                        of ways, principally through our use of:
                        <br />
                        <ul>
                          <li>Energy (our office building, business travel)</li>
                          <li>Paper</li>
                          <li>
                            Information Communication and Technology (ICT) tools
                          </li>
                        </ul>
                        In recognising this, ISA is committed in contributing to
                        protect the environment and continually improving its
                        environmental performance by ensuring:
                        <br />
                        <ul>
                          <li>
                            Compliance with all relevant legislation and
                            standards, and the expectations of our customers,
                            partners and other key stakeholders;
                          </li>
                          <li>
                            Continually to encourage our employees, customers,
                            partners and other key stakeholders to support our
                            environmental commitments;
                          </li>
                          <li>Our systems and procedures prevent pollution;</li>
                          <li>
                            Continually minimise harmful emissions to air, land
                            and water;
                          </li>
                          <li>
                            Continually review how we can make improvements to
                            our environmental performance;
                          </li>
                          <li>
                            Continually to develop the ability to measure and
                            reduce emissions by encouraging our employees,
                            customers, partners and communities and other key
                            stakeholders to commit to low-carbon commuting;
                          </li>
                        </ul>
                        Our actions to protecting environment, specifically
                        includes:
                        <br />
                        <ul>
                          <li>
                            Reduce business travel miles through better journey
                            planning;
                          </li>
                          <li>
                            Reduce our employee travel by prioritizing virtual
                            meetings over offline meetings;
                          </li>
                          <li>
                            Reduce energy consumption across our office and
                            company operations;
                          </li>
                          <li>
                            Reduce paper consumption through employees’
                            awareness and increase use of electronic
                            communication and cloud data storage;
                          </li>
                          <li>
                            Ensure that all waste electrical & electronic
                            equipments are reused or recycled where possible;
                          </li>
                          <li>
                            Monitor water consumption and reduce leakage and
                            eliminate unnecessary and excessive use;
                          </li>
                          <li>
                            Reduce and minimise our use of Information
                            Communication and Technology (ICT) tools.
                          </li>
                        </ul>
                        To realise our environmental objectives ISA recognises
                        the need for and is committed to communicating these
                        objectives to our employees, customers, partners and
                        communities and other key stakeholders. This is achieved
                        via our procurement processes, and our existing
                        communication channels to employees including the
                        business plan, newsletters, team briefings.
                        <br />
                        <br />
                        This policy and the actions arising from it will be
                        annually reviewed as part of the business planning
                        process which involves ISA’s leadership and management
                        teams, with final approval via the ISA Board.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      ) : null}

      {/* Cookies Policy */}
      {params === "Cookies" ? (
        <div id="privacyStatement">
          <ContainerText
            props={
              <div className="privacyStatementContainer">
                {/* <div className="previousButton" onClick={() => navigate("/Contact")}>
                                <span className="expertiseArrowRight"><i className="fas fa-angle-left"/></span>
                                <a href="">
                                        Back to contact
                                </a>
                            </div> */}
                <div className="privacyContentContainer">
                  <div className="privacyEachContent">
                    <div className="privacyDate">
                      <p>
                        <i>Updated as per March 4, 2022</i>
                      </p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        This cookie policy (“<strong>Policy</strong>”) describes
                        how PT CENDEKIA EDUKASI CITRA TRITUNGGAL, and our
                        affiliates (collectively “<strong>ISA</strong>”, “
                        <strong>us</strong>”, “<strong>our</strong>”) use
                        cookies and tracking technologies on our websites and
                        applications.
                        <br />
                        <br />
                        Cookies are small text files that are sent from a
                        website and stored on your computer or other device by
                        your web browser when you visit a site. We use cookies
                        to track user activity to enhance user interface and
                        experience. Most mobile devices and internet browsers
                        support the use of cookies; It is our policy to use
                        cookies and tracking technologies only with the consent
                        of the users of the digital services we provide, except
                        where the use of cookies or tracking technologies is
                        strictly necessary for the operation or use of a
                        service.
                        <br />
                        <br />
                        This Policy describes how ISA may use cookies in
                        association with the operation of our main website{" "}
                        <a
                          href="https://www.sustainability.co.id"
                          target="_blank"
                          rel="noreferrer"
                        >
                          www.sustainability.co.id
                        </a>
                        , and all of our other websites (collectively, “
                        <strong>Services</strong>”).
                        <br />
                        <br />
                        We set out below information about:
                        <ul>
                          <li>Cookies and online tracking technologies;</li>
                          <li>How we use cookies and tracking technologies;</li>
                          <li>Your choices;</li>
                          <li>Managing cookies; and</li>
                          <li>How to contact us.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Cookies</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        A cookie is a small text file that is sent by a website
                        to your computer or mobile device where it is stored by
                        your web browser. Web browser cookies may store
                        information such as your IP address, your browser type,
                        and information about the content you view and interact
                        with on digital services. By storing such information,
                        web browser cookies can remember your preferences and
                        settings for online services and analyze how you use
                        online services.
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>How we use cookies and Tracking Technologies</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        We use some cookies and tracking technologies for
                        purposes which are strictly necessary for your use of
                        our Services. These may include, for example, cookies
                        which remember your preferences and settings; which
                        remember information that you may enter online (so that
                        it does not have to be re-entered every time you move to
                        another page); or to keep you logged in to portals that
                        we may offer where you wish this to happen.
                        <br />
                        <br />
                        With your consent we may also use cookies and tracking
                        technologies for other purposes, such as the generation
                        of aggregate statistics about how people use our
                        Services; for error management and troubleshooting; to
                        help you share content with others; to enable you to
                        receive information about other products and services,
                        and to improve your experience of the Services.
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>Strictly Necessary Cookies</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        These cookies are necessary for the core features of
                        this site to operate properly. Because they are needed
                        for the site’s operation, they are always set to
                        “Active”. You may disable these by changing your browser
                        settings, but this may affect how the website functions.
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>Functional Cookies</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        These cookies are used to provide a better user
                        experience on the site, such as by measuring
                        interactions with particular content or remembering your
                        settings such as language or video playback preferences.
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>Performance Cookies</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        These cookies allow us to count visits and traffic
                        sources so we can measure and improve the performance of
                        our site. They help us to know which pages are the most
                        and least popular and see how visitors move around the
                        site. All information these cookies collect is
                        aggregated and therefore anonymous. If you do not allow
                        these cookies we will not know when you have visited our
                        site, and will not be able to monitor its performance.
                        <br />
                        <br />
                        Where you have permitted the use of performance cookies,
                        we use Google Analytics to collect statistical
                        information about how our Services are used. Google
                        Analytics services involve the use of cookies that
                        collect information such as your IP address or other
                        identifiers, browser information, and information about
                        the content you view and interact with to record how you
                        use our Services.
                        <br />
                        <br />
                        These analytics services help us to know how many users
                        we have, which parts of our sites are most popular, what
                        browsers are used (so we can maximize compatibility),
                        the country or region where our users are located, and
                        the demographics and interests of our users. This
                        enables us to better understand who is using our
                        Services and to improve how we present content on the
                        Services.
                        <br />
                        <br />
                        You can find more information about Google Analytics
                        services <a href="#">here</a>.
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>Targeting cookies</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        These cookies may be set through our site by our
                        advertising partners. They may be used by those
                        companies to build a profile of your interests and show
                        you relevant adverts on other sites. They do not store
                        personal information but are based on uniquely
                        identifying your browser and internet device. If you do
                        not allow these cookies, you will experience less
                        personalized advertising.These cookies are used by
                        advertising companies to inform and serve personalized
                        ads to your devices based on your interests.
                      </p>
                    </div>
                  </div>
                  <div className="privacyEachContent">
                    <div className="privacyMainTitle">
                      <p>Managing Cookies</p>
                    </div>
                    <div className="privacySubTitle">
                      <p>Your Preferences</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        When you first visit our Services you will be presented
                        with a banner which offers you a choice about whether to
                        accept or reject certain cookies or tracking
                        technologies, with the exception of those cookies which
                        are strictly necessary for a particular service.
                        <br />
                        <br />
                        You may amend your choice at any time, by using the
                        Cookie Settings link found in the home page. Kindly note
                        that disabling cookies will affect the functionality or
                        features of this website.
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>Managing Cookies and Local Storage on Your Device</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        If you wish, you can also choose how web browser cookies
                        are handled by your device via your browser settings.
                        Some devices allow you to control this through your
                        device settings. If you choose not to receive cookies at
                        any time, websites may not function properly and certain
                        services will not be provided. Each browser and device
                        is different, please check the settings menu of the
                        browser or device to learn how to change your settings
                        and cookie preferences.
                        <br />
                        <br />
                        Guidance on how to control cookies for common browsers
                        is linked below:
                        <br />
                        <a href="#">Google Chrome</a>
                        <br />
                        <a href="#">Mozilla Firefox</a>
                        <br />
                        <a href="#">Internet Explorer</a>
                        <br />
                        <a href="#">Safari</a>
                        <br />
                      </p>
                    </div>
                    <div className="privacySubTitle">
                      <p>How to contact us</p>
                    </div>
                    <div className="privacyParagraph">
                      <p>
                        To find out more about our approach to privacy policy
                        statement, please read our{" "}
                        <a
                          href="/Policy/PrivacyStatement"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      ) : null}
      <Footer />
    </div>
  );
};

export default PrivacyStatement;
