import { Route, Routes } from "react-router-dom";

import "./App.css";

// Customer Page
import About from "./views/Home/About";
import Blog from "./views/Home/Blog";
import Client from "./views/Home/Client";
import Contact from "./views/Home/Contact";
import DetailBlog from "./views/Home/DetailBlog";
import Expertise from "./views/Home/Expertise";
import Home from "./views/Home/Home";
import PrivacyStatement from "./views/Home/PrivacyStatement";
import TeamDetail from "./views/Home/TeamDetail";
import Academy from "./views/Home/Academy";
import DetailAcademy from "./views/Home/DetailAcademy";
import Activities from "./views/Home/Activities";
import ActivitiyDetails from "./views/Home/ActivityDetails";
import Registration from "./views/Home/Registration";
import FoundersStory from "./views/Home/FoundersStory";
import FoundersStoryDetails from "./views/Home/FoundersStoryDetails";
import Books from "./views/Home/Books";
import BookDetails from "./views/Home/BookDetails";
import LearningVideos from "./views/Home/LearningVideos";

// Login Admin
import Register from "./views/Authentication/Register";
import LoginPage from "./views/Authentication/LoginPage";
import ResetPassword from "./views/Authentication/ResetPassword";

// Admin Page
import AdminPage from "./views/Authentication/AdminPage";
import SendEmail from "./views/Authentication/SendEmail";
import EmailVerified from "./views/Authentication/EmailVerified";

// Navigation guard
import { RequireAuth, IsSignedIn } from "./helpers/navigationGuard";

// React DOM
import { useLocation } from "react-router-dom";
import DiplomaESG from "./views/DiplomaESG/DiplomaESG";
import RegistrationForm from "./views/DiplomaESG/RegistrationForm";
import DownloadFileForm from "./views/DiplomaESG/DownloadFileForm";

function App() {
  const { pathname } = useLocation();
  return (
    <div
      className="App"
      style={
        pathname &&
        (pathname.includes("/admin") ||
          pathname.includes("/signIn") ||
          pathname.includes("/register"))
          ? {}
          : { zoom: "80%" }
      }
    >
      <Routes>
        <Route
          path="/signIn"
          element={
            <IsSignedIn>
              <LoginPage />
            </IsSignedIn>
          }
        />
        <Route
          path="/resetPassword/:encryptedEmail"
          element={
            <IsSignedIn>
              <ResetPassword />
            </IsSignedIn>
          }
        />
        <Route
          path="/register"
          element={
            <IsSignedIn>
              <Register />
            </IsSignedIn>
          }
        />
        <Route path="/verifyEmail/:uniqueCode" element={<EmailVerified />} />
        <Route path="/sendEmail/:encryptedEmail" element={<SendEmail />} />
        <Route
          path="/admin"
          element={
            <RequireAuth>
              <AdminPage />
            </RequireAuth>
          }
        >
          <Route path=":params" element={<AdminPage />}>
            <Route path=":crud" element={<AdminPage />}>
              <Route path=":editId" element={<AdminPage />} />
            </Route>
          </Route>
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/About/:params" element={<About />} />
        <Route path="/About/TeamMembers/:id" element={<TeamDetail />} />
        <Route path="/Expertise" element={<Expertise />}>
          <Route path=":section" element={<Expertise />} />
        </Route>
        <Route path="/Academy" element={<Academy />}>
          <Route path=":id" />
        </Route>
        <Route path="/Academy/details" element={<DetailAcademy />}>
          <Route path=":id" />
        </Route>
        {/* <Route path="/Registration/:id" element={<Registration/>} /> */}
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Clients" element={<Client />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Blog/:id" element={<DetailBlog />} />
        <Route path="/Policy/:params" element={<PrivacyStatement />} />
        <Route path="/Activities" element={<Activities />} />
        <Route path="/Activities/:activityId" element={<ActivitiyDetails />} />
        <Route path="/Founders-story" element={<FoundersStory />} />
        <Route
          path="/Founders-story/:foundersStoryId"
          element={<FoundersStoryDetails />}
        />
        <Route path="/Diploma-PM-for-ESG" element={<DiplomaESG />} />
        <Route
          path="/Diploma-PM-for-ESG/registration-form"
          element={<RegistrationForm />}
        />
        <Route
          path="/Diploma-PM-for-ESG/download-file-form/:paramsKey"
          element={<DownloadFileForm />}
        />
        <Route path="/Books" element={<Books />} />
        <Route path="/Books/:book_id" element={<BookDetails />} />
        <Route path="/Learning-videos" element={<LearningVideos />} />
      </Routes>
    </div>
  );
}

export default App;
