import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Icons
import ISAlogo from "../../images/icons/ISA.png";
import ApprovalLogo from "../../images/icons/Approval.png";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../lotties/paperplane.json";

// API
import { findEncryptedEmail } from "../../api/findEncryptedEmail";

// CSS Styling
import "../../styles/loginPage.css";

// Components
import ContainerText from "../../components/Container";

const SendEmail = () => {
  const { encryptedEmail } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);

  const findEncryptedEmailUser = async (encryptedEmail) => {
    try {
      const result = await findEncryptedEmail({ email: encryptedEmail });
      if (result && result.data && result.data.email) {
        setEmail(result.data.email);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (encryptedEmail) {
        findEncryptedEmailUser(encryptedEmail);
    }
  }, [encryptedEmail]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            width: "75%",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "5em",
          }}
        >
          <Lottie options={lottieOptions} height={200} width={400} />
          <h4
            style={{
              color: "#0D4C86",
            }}
          >
            LOADING
          </h4>
          <p
            style={{
              color: "#0D4C86",
            }}
          >
            Processing your request, please wait...
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div id="sendEmailPage">
        <div className="loginPageContainer">
          <ContainerText
            props={
              <div className="loginFormContainer">
                <div className="loginFormImage">
                  <img src={ISAlogo} alt="ISA Logo" />
                </div>
                <div className="loginFormTitle" style={{ marginTop: "3em" }}>
                  <p>
                    {email ? (
                      <span>
                        <img
                          style={{ marginRight: "0.5em" }}
                          src={ApprovalLogo}
                          alt="Approve logo"
                        />
                        Approval Success
                      </span>
                    ) : (
                      "Forbidden 403"
                    )}
                  </p>
                </div>
                <div
                  className="loginFormSubtitle"
                  style={{ fontWeight: "700", marginTop: "3em" }}
                >
                  {email ? (
                    <p>You have successfully approved new user</p>
                  ) : (
                    <p>
                      You're not permitted to enter this page, please go back
                      into{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/signIn")}
                      >
                        login page
                      </span>
                    </p>
                  )}
                  <p>{email ? email : null}</p>
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
};

export default SendEmail;
