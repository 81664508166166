import React from "react";

// CSS Styling
import "../../styles/admin.css";
import "../../styles/isaAcademy.css";

// Images and Icons
import NotepadPencil from "../../images/icons/NotepadPencil.png";
import DeleteIcon from "../../images/icons/DeleteIcon.png";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../lotties/paperplane.json";

// Axios and API
import { deleteBook } from "../../api/books";

// Moment.js
import moment from "moment";

// Sweet Alert v2
import Swal from "sweetalert2";

const BookTable = (props) => {
  const { navigate, isLoading, books, setLoading } = props;

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleDeleteBook = (book_id) => {
    Swal.fire({
      customClass: {
        cancelButton: "cancelPostButton",
        confirmButton: "confirmPostButton",
        title: "cancelPostButtonTitle",
      },
      title: "are you sure you want to delete this? ",
      text: "Once confirmed, you can’t undo this.",
      padding: "3em",
      showCancelButton: true,
      confirmButtonColor: "white",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    })
      .then((res) => {
        if (res.isConfirmed) {
          setLoading(true);
          deleteBook(book_id)
            .then((res) => {
              Swal.fire({
                customClass: {
                  confirmButton: "publishButton",
                  title: "publishTitle",
                  popup: "publishPopup",
                },
                showCloseButton: true,
                confirmButtonColor: "#1F81DC",
                title: "Success!",
                text: "You have successfully deleted founder's story",
                confirmButtonText: "Okay, got it!",
              });
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            })
            .finally(() => {
              window.location.reload();
            });
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="adminPostList">
        <div className="adminPostListTitleContainer">
          <div className="adminPostListTitle">
            <p>Books</p>
          </div>
          <div className="adminPostListFunction">
            {/* filter button goes here */}
            <div className="adminPostListEachFunction addPostButton">
              <button onClick={() => navigate("/admin/books/add")}>
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="adminPostTable">
        <div className="adminPostTableContainer">
          <table>
            <thead>
              <tr>
                <th className="thLastModified">Last modified</th>
                <th className="thWorkshopTitle">Title</th>
                <th>Posted by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading && books.length > 0 ? (
                books.map((book) => (
                  <tr key={book.id} className="adminTableRow">
                    <td className="tdLastModified">
                      <div style={{ marginRight: "1em" }}>
                        {`${moment(book.lastModified).format("DD/MM/YYYY")}`}
                      </div>
                      <div>{moment(book.lastModified).format("HH:mm")}</div>
                    </td>
                    <td>{book.title}</td>
                    <td>{book.postedBy}</td>
                    <td className="tdEdit">
                      <div
                        className="editWorkshopBtn"
                        onClick={() => navigate(`/admin/books/edit/${book.id}`)}
                      >
                        Edit
                      </div>
                      <img
                        src={DeleteIcon}
                        alt="Delete Icon"
                        className="deleteWorkshopBtn"
                        onClick={() => handleDeleteBook(book.id)}
                      ></img>
                    </td>
                  </tr>
                ))
              ) : isLoading ? (
                <div
                  style={{
                    width: "75%",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "5em",
                  }}
                >
                  <Lottie options={lottieOptions} height={200} width={400} />
                  <h4
                    style={{
                      color: "#0D4C86",
                    }}
                  >
                    LOADING
                  </h4>
                  <p
                    style={{
                      color: "#0D4C86",
                    }}
                  >
                    Processing your request, please wait...
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    width: "75%",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "5em",
                  }}
                >
                  <div>
                    <img
                      src={NotepadPencil}
                      alt="Data not found icon"
                      width={350}
                    />
                  </div>
                  <h4
                    style={{
                      color: "#0D4C86",
                    }}
                  >
                    You have not written any book yet
                  </h4>
                  <p
                    style={{
                      color: "#0D4C86",
                    }}
                  >
                    Add new book to start..
                  </p>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BookTable;
