// Axios
import Axios from "./axios"

export const addActivity = (body) => {
    return new Promise((resolve, reject) => {
        const data = Axios.post(`/activities/create`, body, {
            headers: {
                'access-token': localStorage.getItem("access_token")
            }
        })
        resolve(data)
    })

}