import { useState } from "react"
import { useNavigate } from "react-router-dom"

// Icons
import ISAlogo from "../../images/icons/ISA.png"
import EmailLogin from "../../images/icons/EmailLogin.png"
import UsernameLogin from "../../images/icons/UsernameLogin.png"
import PasswordLogin from "../../images/icons/PasswordLogin.png"
import EyePasswordHide from "../../images/icons/EyePasswordHide.png"
import EyePasswordShow from "../../images/icons/EyePasswordShow.png"

// CSS Styling
import "../../styles/loginPage.css";

// React Form
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Sweetalert
import Swal from "sweetalert2"

// Components
import ContainerText from "../../components/Container"
import { registerUser } from "../../api/registerUser"

const Register = () => {

    const { register, handleSubmit, formState: {errors} } = useForm({ mode: "all" })
    const navigate = useNavigate()

    const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const [isEmail, setEmail] = useState(false)
    const [isPassword, setPassword] = useState(false)
    const [isRetypePassword, setRetypePassword] = useState(false)

    // Handle show/hide password
    const handleEyePassword = () => {
        if (showPassword === "password") {
            setShowPassword("text")
        } else {
            setShowPassword("password")
        }
    }

    // Submit button validation - email
    const handleEmail = (e) => {
        if (!e.target.value.trim().length) return setEmail(false)
        return setEmail(true)
    }

    // Submit button validation - password
    const handlePassword = (e) => {
        if (!e.target.value.trim().length) return setPassword(false)
        return setPassword(true)
    }

    // Submit button validation - retype password
    const handleRetypePassword = (e) => {
        if (!e.target.value.trim().length) return setRetypePassword(false)
        return setRetypePassword(true)
    }

    // Google ReCaptcha
    const onChangeCaptcha = (value) => {
        if (value) {
            setCaptchaCorrect(true)
        } else {
            setCaptchaCorrect(false)
        }
    }

    // Register submission
    const onSubmit = async (values) => {
        try {
            if (values.password !== values.retypePassword) return Swal.fire(`Oops`, `password not match, please try again….`, `error`)
            if (isCaptchaCorrect) {
                const result = await registerUser({
                    email: values.email,
                    username: values.username,
                    password: values.password,
                    role: "Admin"
                })
                if (!result) return alert("Please input the form properly")
                Swal.fire(
                    'Success!',
                    'Your email will be reviewed by the team',
                    'success'
                )
                navigate("/signIn")
            } else {
                Swal.fire(
                    `Verify captcha`,
                    `Please check the captcha box before you proceed`,
                    `error`
                )
            }
        } catch (err) {
            console.log(err.response)
            Swal.fire(
                'Error!',
                `${err.response.data}`,
                'error'
            )  
        }
    }

    return (
        <div id="registerPage">
            <div className="loginPageContainer">
                <ContainerText props={
                    <div className="loginFormContainer">
                        <div className="loginFormImage">
                            <img src={ISAlogo} alt="ISA Logo"/>
                        </div>
                        <div className="loginFormTitle">
                            <p>Create your account</p>
                        </div>
                        <div className="loginFormSubTitle">
                            <p>Register to ISA Admin Panel</p>
                        </div>
                        <div className="loginForm">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="registerFormInput">
                                    <label><img src={UsernameLogin} alt="Username icon" /> Username</label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="username"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                    <input name="username" type="text" pattern="^\S*$" className="form-control" placeholder="Enter your username" {...register('username', { required: "This field is required", 
                                    pattern: {
                                        value: /^\S*$/,
                                        message: "no space allowed"
                                    },
                                    minLength: {
                                        value: 5,
                                        message: "username at least must have 5 characters"
                                    },
                                    onChange: (e) => handleEmail(e)
                                    })} style={{marginBottom: "0px"}}></input>
                                </div>
                                <div className="registerFormInput">
                                    <label><img src={EmailLogin} alt="Email Icon" /> Email</label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                    <input name="email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="form-control" placeholder="Enter your email address" {...register('email', { required: "This field is required", 
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "email format invalid, please try again.."
                                    },
                                    onChange: (e) => handleEmail(e)
                                    })} style={{marginBottom: "0px"}}></input>
                                </div>
                                <div className="registerFormInput">
                                    <label> <img src={PasswordLogin} alt="Password Icon"/>Password</label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                    <input name="password" pattern="(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$" type={showPassword} className="form-control" placeholder="Enter your password" {...register('password', { required: "This field is required", 
                                    pattern: {
                                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
                                        message: "password must contain at least one letter, one number and one special character"
                                    },
                                    minLength: {
                                        value: 8,
                                        message: "password at least must have 8 characters"
                                    },
                                    onChange: (e) => handlePassword(e)
                                    })} style={{marginBottom: "0px"}}></input>
                                    <span className="loginEyePassword" onClick={handleEyePassword}><img src={showPassword === "password" ? EyePasswordHide : EyePasswordShow} alt="Eye Password"/></span>
                                </div>
                                <div className="registerFormInput">
                                    <label> <img src={PasswordLogin} alt="Password Icon"/>Retype password</label>
                                    <ErrorMessage
                                        errors={errors}
                                        name="retypePassword"
                                        render={({ message }) => <p>{message}</p>}
                                    />
                                    <input name="retypePassword" type={showPassword} className="form-control" placeholder="Retype your password" {...register('retypePassword', { required: "This field is required", 
                                    onChange: (e) => handleRetypePassword(e)
                                    })} style={{marginBottom: "0px"}}></input>
                                    <span className="loginEyePassword" onClick={handleEyePassword}><img src={showPassword === "password" ? EyePasswordHide : EyePasswordShow} alt="Eye Password"/></span>
                                </div>
                                <ReCAPTCHA
                                style={{marginBottom: "3em"}}
                                    sitekey='6LfXMYAeAAAAAAjkbjSL22SnQ5lqP29nDdPDuAO0'
                                    onChange={onChangeCaptcha} />
                                <button disabled={!isEmail || !isPassword || !isRetypePassword || !isCaptchaCorrect} className="btn" type="submit">
                                    Register Account
                                </button>
                            </form>
                            <div className="authenticationPageNavigation">
                                <p>Already have an account? <span onClick={() => navigate("/signIn")}>Login here</span></p>
                            </div>
                        </div>
                    </div>
                }/> 
            </div>
        </div>
    )
}

export default Register