
// CSS Styling
import "../styles/popup.css";

// Components
import ContainerText from "./Container";

// Helpers

const PopupExpertise = ({backgroundColor, image, titleColor, titleText, hrefTo, closePopup, closeColor, content, whatsApp, contentColor, form}) => {

    return(
        <ContainerText props={
            <div id="popupModal" className="overlay">
                <div className="popupCloseOverlay" onClick={closePopup}></div>
                <div className="popup" style={{ background: backgroundColor, padding: "2.5em", paddingBottom: "3em" }}>
                    {
                        image ? <img src={image} alt="popupMessage"></img> : null
                    }
                    <p style={{color: titleColor, marginBottom: "0.5em"}}>{titleText}</p>
                    <a className="popupClose" href={hrefTo} onClick={closePopup} style={{color: closeColor}}>&times;</a>
                    <div className="popupContent" style={{color: contentColor, marginBottom: "0.7em"}}>
                        {content} <a>{whatsApp}</a>
                    </div>
                    {form}
                </div>
            </div>
        }/>
    )
}

export default PopupExpertise