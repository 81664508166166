import Select from 'react-select';

const DropdownSelect = ({
    options,
    placeholder = null,
    inputRef,
    value,
    onChange
}) => {

    return (
        <Select
            ref={inputRef}
            options={options}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    borderColor: 'black',
                    width: '100%',
                    borderRadius: '10px',
                    fontSize: '13px',
                })
            }}
        />
    )
}

export default DropdownSelect;