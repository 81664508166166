import React from "react";

// React DOM
import { useParams, useNavigate, useLocation } from "react-router-dom";

// Components
import WorkshopForm from "./components/WorkshopForm";
import TestimonialForm from "./components/TestimonialForm";
import TestimonialList from "./components/TestimonialList";
import WorkshopList from "./components/WorkshopList";

const IsaAcademyForm = (props) => {
  const {
    isLoading,
    setLoading,
    setShowAlertWorkshop,
    setWorkshopAlertMsg,
    fetchAllWorkshops,
    fetchAllTestimonials,
    workshops,
    testimonials,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location || {};
  const { crud } = useParams();
  const { editId } = useParams();

  if (pathname && pathname.includes("/admin/isaAcademy-testimonial")) {
    if (crud === "add" || crud === "edit") {
      return (
        <TestimonialForm
          navigate={navigate}
          editId={editId}
          fetchAllTestimonials={fetchAllTestimonials}
          setShowAlertWorkshop={setShowAlertWorkshop}
          setWorkshopAlertMsg={setWorkshopAlertMsg}
        />
      );
    } else {
      return (
        <TestimonialList
          navigate={navigate}
          fetchAllTestimonials={fetchAllTestimonials}
          testimonials={testimonials}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      );
    }
  } else {
    if (crud === "add" || crud === "edit") {
      return (
        <WorkshopForm
          navigate={navigate}
          editId={editId}
          setShowAlertWorkshop={setShowAlertWorkshop}
          setWorkshopAlertMsg={setWorkshopAlertMsg}
          fetchAllWorkshops={fetchAllWorkshops}
        />
      );
    } else {
      return (
        <WorkshopList
          navigate={navigate}
          setLoading={setLoading}
          fetchAllWorkshops={fetchAllWorkshops}
          workshops={workshops}
          isLoading={isLoading}
        />
      );
    }
  }
};

export default IsaAcademyForm;
