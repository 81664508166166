// Axios
import Axios from "./axios"

export const logoutUser = (email) => {
    return new Promise((resolve, reject) => {
        const data = Axios.patch(`/user/logout/${email}`, null, {
            headers: {
                'access-token': localStorage.getItem("access_token")
            }
        })
        setTimeout(() => resolve(data), 0)
    })
}