// Axios
import Axios from "./axios"

export const convertPicture = (value) => {
    return new Promise((resolve, reject) => {
        const data = Axios.post(`/news/convertPicture`, value, {
            headers: {
                'access-token': localStorage.getItem("access_token"),
                'content-type': 'multipart/form-data'
            }
        })
        resolve(data)
    })

}