import React from "react";

// React DOM
import { useNavigate, useParams } from "react-router-dom";

// Components
import FoundersStoryForm from "./FoundersStoryForm";
import FoundersStoryTable from "./FoundersStoryTable";

const FoundersStory = (props) => {
  const {
    isLoading,
    setLoading,
    foundersStories,
    setShowAlertWorkshop,
    setWorkshopAlertMsg,
    fetchAllFoundersStories,
  } = props;
  const { crud } = useParams();
  const navigate = useNavigate();
  const { editId } = useParams();

  if (crud) {
    return (
      <FoundersStoryForm
        navigate={navigate}
        setShowAlertWorkshop={setShowAlertWorkshop}
        setWorkshopAlertMsg={setWorkshopAlertMsg}
        fetchAllFoundersStories={fetchAllFoundersStories}
        editId={editId}
      />
    );
  } else {
    return (
      <FoundersStoryTable
        navigate={navigate}
        isLoading={isLoading}
        fetchAllFoundersStories={fetchAllFoundersStories}
        setLoading={setLoading}
        foundersStories={foundersStories}
      />
    );
  }
};

export default FoundersStory;
