// Axios
import Axios from "./axios"

export const editNews = (id, value) => {
    return new Promise((resolve, reject) => {
        const data = Axios.put(`/news/${id}`, value, {
            headers: {
                'access-token': localStorage.getItem("access_token")
            }
        })
        setTimeout(() => resolve(data), 3000)
    })

}