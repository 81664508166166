// Axios
import Axios from "./axios";

export const editWorkshopTestimonial = (testimonialId, value) => {
  return new Promise((resolve, reject) => {
    const data = Axios.put(
      `/workshopTestimonial/edit/${testimonialId}`,
      value,
      {
        headers: {
          "access-token": localStorage.getItem("access_token"),
        },
      }
    );
    resolve(data);
  });
};
