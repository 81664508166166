import React from "react";

// CSS Styling
import "../../../styles/admin.css";
import "../../../styles/isaAcademy.css";

// React Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Icons
import ArrowDownIcon from "../../../images/icons/admin/arrowDownIcon";

// Components
import EditorText from "../../../components/EditorText";

const InputSchedule = (props) => {
  const {
    counter,
    firstInput,
    addMoreScheduleInput,
    addMoreIcon,
    WorkshopDropdownSelect,
    onDropdownScheduleChange,
    schedules,
    scheduleEmpty,
    onChangeInputDateSchedule,
    onInputChange,
    handleClickMethodOfLearning,
  } = props;
  return (
    <div className="workshopInputContainer" key={counter}>
      <div className="labelForWorkshopInput">
        <div className="eachLabel">{`Workshop Schedule ${counter + 1}`}</div>
        {firstInput ? (
          <div
            className="btnAddMoreInput"
            onClick={() => addMoreScheduleInput()}
          >
            <img
              src={addMoreIcon}
              alt="add more icon"
              width={15}
              height={15}
            ></img>
            <div style={{ marginLeft: "7.5px" }}>Add more schedule</div>
          </div>
        ) : null}
      </div>
      <div className="adminPostInput">
        <div className="labelForDescription">
          <div
            className="eachFacilitatorLabel"
            style={{ marginLeft: "0.25em" }}
          >
            Method of learning
          </div>
        </div>
        <WorkshopDropdownSelect
          placeholder="Select method of learning"
          options={[
            { value: "Online", label: "Online" },
            { value: "Offline", label: "Offline" },
            {
              value: "Hybrid (Online and Offline)",
              label: "Hybrid (Online and Offline)",
            },
          ]}
          selectStyle={{
            height: "50px",
            borderRadius: "40px",
            fontSize: "15px",
            padding: "5px",
            borderColor: "#0D4C86",
          }}
          optionStyle={{
            backgroundColor: "#0D4C86",
            fontSize: "15px",
            color: "#FFFFFF",
          }}
          onChange={(e) =>
            onDropdownScheduleChange(e, counter, "methodOfLearning")
          }
          value={
            schedules[counter] && schedules[counter]["methodOfLearning"]
              ? schedules[counter]["methodOfLearning"]
              : null
          }
          handleOnCloseDropdown={() =>
            handleClickMethodOfLearning(counter, false)
          }
          handleOnOpenDropdown={() =>
            handleClickMethodOfLearning(counter, true)
          }
        />
        {(schedules.length <= 0 && scheduleEmpty && firstInput) ||
        (schedules.length > 0 &&
          schedules[counter] &&
          schedules[counter]["emptyMethodOfLearning"] === true) ? (
          <div className="errorMessage">
            <p>This field is required</p>
          </div>
        ) : null}
      </div>
      {schedules.length > 0 &&
        schedules[counter] &&
        schedules[counter]["methodOfLearning"] &&
        schedules[counter]["methodOfLearning"]["value"] && (
          <div
            className="adminPostInput"
            style={
              schedules[counter] && schedules[counter]["addMarginBottom"]
                ? { marginTop: "8em" }
                : { marginTop: "1em" }
            }
          >
            <div className="labelForDescription">
              <div className="eachFacilitatorLabel">Investment Fee</div>
            </div>
            <EditorText
              onChange={(content) =>
                onInputChange(false, content, counter, "investmentFee")
              }
              preloadValue={
                schedules[counter] && schedules[counter]["investmentFee"]
                  ? schedules[counter]["investmentFee"]
                  : null
              }
            />
            {(schedules.length <= 0 && scheduleEmpty && firstInput) ||
            (schedules.length > 0 &&
              schedules[counter] &&
              schedules[counter]["emptyInvestmentFee"] === true) ? (
              <div className="errorMessage">
                <p>This field is required</p>
              </div>
            ) : null}
          </div>
        )}
      {schedules.length > 0 &&
        schedules[counter] &&
        schedules[counter]["methodOfLearning"] &&
        schedules[counter]["methodOfLearning"]["value"] && (
          <div className="workshopInputContainer">
            <div className="labelForWorkshopInput">
              <div className="eachContainer">
                <div
                  className="labelForDescription"
                  style={{ marginLeft: "0.25em" }}
                >
                  <div className="eachFacilitatorLabel">
                    Schedule Start Date
                  </div>
                </div>
                <DatePicker
                  selected={
                    schedules[counter]["selected_start_date"]
                      ? schedules[counter]["selected_start_date"]
                      : new Date()
                  }
                  onChange={(date) =>
                    onChangeInputDateSchedule(date, counter, true, "startDate")
                  }
                  customInput={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        border: "1px solid #0d4c86",
                        borderRadius: "40px",
                        height: "50px",
                        fontSize: "15px",
                        borderColor: "#0D4C86",
                        padding: "1em",
                      }}
                    >
                      <div>
                        {schedules[counter]["start_date"]
                          ? `${schedules[counter]["start_date"]}`
                          : "Select start date"}
                      </div>
                      <ArrowDownIcon />
                    </div>
                  }
                  showMonthDropdown
                  showYearDropdown
                  todayButton="Today"
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  autoComplete="off"
                />
                {(schedules.length <= 0 && scheduleEmpty && firstInput) ||
                (schedules.length > 0 &&
                  schedules[counter] &&
                  schedules[counter]["emptyStartDate"] === true) ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
              <div className="eachContainer">
                <div
                  className="labelForDescription"
                  style={{ marginLeft: "0.25em" }}
                >
                  <div className="eachFacilitatorLabel">Schedule End Date</div>
                </div>
                <DatePicker
                  selected={
                    schedules[counter]["selected_end_date"]
                      ? schedules[counter]["selected_end_date"]
                      : new Date()
                  }
                  onChange={(date) =>
                    onChangeInputDateSchedule(date, counter, true, "endDate")
                  }
                  customInput={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#ffffff",
                        border: "1px solid #0d4c86",
                        borderRadius: "40px",
                        height: "50px",
                        fontSize: "15px",
                        borderColor: "#0D4C86",
                        padding: "1em",
                      }}
                    >
                      <div>
                        {schedules[counter]["end_date"]
                          ? `${schedules[counter]["end_date"]}`
                          : "Select end date"}
                      </div>
                      <ArrowDownIcon />
                    </div>
                  }
                  showMonthDropdown
                  showYearDropdown
                  todayButton="Today"
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  autoComplete="off"
                />
                {(schedules.length <= 0 && scheduleEmpty && firstInput) ||
                (schedules.length > 0 &&
                  schedules[counter] &&
                  schedules[counter]["emptyEndDate"] === true) ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default InputSchedule;

