const initialState = {
    login_status: null
}

export const LoginComponent = (state = initialState, action) => {
    switch (action.type) {
        
        // This reducer handles any action with type "LOGIN"
        case "LOGIN":
            return state.map(user => {
                if (user.email !== action.email) {
                    return user;
                }

                if (user.password === action.password) {
                    return {
                        ...user,
                        login_status: "LOGGED IN"
                    }
                }
            });
        default:
                return state;
            } 
        };