// Axios
import Axios from "./axios";

export const sendNotificationRegistrationESGtoAdmin = (body) => {
  return new Promise((resolve, reject) => {
    const data = Axios.post(
      `/email/notification/registration/dip-pm-esg/admin`,
      body
    );
    resolve(data);
  });
};
