// Axios
import Axios from "./axios"

export const addCategory = (value) => {
    return new Promise((resolve, reject) => {
        const data = Axios.post(`/news/addCategory`, value, {
            headers: {
                'access-token': localStorage.getItem("access_token")
            }
        })
        setTimeout(() => resolve(data), 0)
    })

}