import { useEffect, useState } from "react";

// Axios
import Axios from "../../api/axios";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// CSS styling
import "../../styles/clients.css";

// Components
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Client = () => {
  const [clients, setClients] = useState([]);

  const fetchClients = async () => {
    const response = await Axios.get("/client").catch((err) => {
      console.log("Err: ", err);
    });
    setClients(response.data);
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
    fetchClients();
  }, []);

  return (
    <div id="clientsPage">
      <Header />
      <ContainerText
        props={
          <div className="clientsPageContainer">
            <div className="clientsTitle">
              <p>Our Clients</p>
            </div>
            <div className="clientsSubTitle">
              <p>Recent Projects</p>
            </div>
            <div className="clientsLogoPage">
              {clients.map((client) => (
                <div className="clientsLogoContainerPage" key={client.id}>
                  <img src={client.clientImage} alt={client.clientName} />
                </div>
              ))}
            </div>
          </div>
        }
      />
      <div className="pastProjects">
        <ContainerText
          props={
            <div className="pastProjectsContainer">
              <div className="clientsSubTitle">
                <p>Our Past Projects</p>
              </div>
              <div className="clientsMiniSubTitle">
                <p>
                  Our president director Dr. Maria R. Nindita Radyati was
                  involved in projects below:
                </p>
              </div>
              <div className="clientsProject">
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Perusahaan Gas Negara Tbk (PGN)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>Project Management - CSR Implementation</li>
                      <li>
                        Monitoring & Evaluation of CSR impact{" "}
                        <i>(Lampung, Palembang, Surabaya, Bekasi, Serang)</i>
                      </li>
                      <li>
                        Training and Monitoring:{" "}
                        <i>Desa binaan di Palembang dan Batam</i>
                      </li>
                      <li>
                        Training and Monitoring: Pelatihan dan pendampingan
                        pembentukan BUMDES{" "}
                        <i>Desa binaan di Palembang dan Batam</i>
                      </li>
                      <li>
                        Ceremony of{" "}
                        <i>Taman Bacaan & Rumah Baca Tembesi Tower</i>
                      </li>
                      <li>Desa Binaan Pagardewa (bersama PT Kirana)</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Pertamina Lubricants</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Social and Business Mapping:{" "}
                        <i>Cilacap, Gresik, Jakarta Utara</i>
                      </li>
                      <li>
                        Designing CSR programs that focuses on Shared Value dan
                        Cause-Related Marketing: Enduro Student Program (ESP)
                      </li>
                      <li>Project Management - CSR Implementation</li>
                      <li>
                        Training and Monitoring: Enduro Student Program (ESP)
                        Entrepreneurs - Cilacap
                      </li>
                      <li>
                        Monitoring dan Evaluation: Enduro Student Program (ESP)
                        Entrepreneurs
                      </li>
                      <li>
                        Training and Monitoring for
                        <i>
                          {" "}
                          Komunitas untuk Pembentukan Kelompok Enduro Student
                          Program (ESP)
                        </i>
                      </li>
                      <li>
                        Developing Blue Print for Enduro Student Program (ESP)
                      </li>
                      <li>Developing CSR Report</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Astra International, Tbk (Astra Group)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Capacity building about CSR for all managers and staffs
                      </li>
                      <li>
                        Pelatihan Measuring Social Return on your CSR Investment
                        (2017-2020).
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>‍PT Adaro Energy, Tbk</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>CSR training for all departments</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>‍PT Pertamina Geothermal Energy</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Capacity building and mentoring for community of{" "}
                        <i>Desa Wisata Kamojang</i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Chevron Pacific Indonesia</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Evaluate microfinance program using Social Return on
                        Investment (SROI)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Permodalan Nasional Madani (Persero)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>Evaluation of effectiveness CSR program by PNM</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Bio Farma (Persero)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Capacity building about CSR for all managers and staffs
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Bank Tabungan Pensiunan Nasional, Tbk</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training: Measuring Social Return on your CSR Investment
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Wijaya Karya Beton, Tbk</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training “Project Management based on ISO 21500” & “CSR
                        Communication Strategy”
                      </li>
                      <li>
                        Developing “Sustainability Roadmap Wika Beton 2020-2023”
                        (2019)
                      </li>
                      <li>
                        Training "Achieving Sustainable Business through
                        Sustainability Roadmap based on ISO 26000" (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Pertamina (Persero)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training “Measuring Social Return on Investment (SROI)”
                        (2019)
                      </li>
                      <li>
                        Training “Project Management based on ISO 21500” (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Bank Central Asia, Tbk</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training "Communicating Social Responsibility: Tools and
                        Techniques" (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Hanjaya Mandala Sampoerna</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training "Sustainability & CSR Based on ISO 26000"
                        (2019)
                      </li>
                      <li>
                        Focus Group Discussions on Entrepreneurship Curriculum
                        with local partners PT HM Sampoerna (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT United Tractors, Tbk</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Promoting CSR as business strategy that is contributing
                        to Sustainable Development Goals (2017)
                      </li>
                      <li>
                        Training: Measuring Social Return on your CSR Investment
                        (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT ANTAM (Persero) Tbk</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>Planting and maintaining 10.000 trees</li>
                      <li>
                        <i>PKBL</i> monitoring activities
                      </li>
                      <li>Social mapping in East Halmahera</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Pertamina EP</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>Social Mapping and Need Assessment in Bekasi</li>
                      <li>CSR Project Management</li>
                      <li>
                        Formulating Integrated Business Intelligent Mapping
                        (IBIM)
                      </li>
                      <li>Creating CSR project management blueprint</li>
                      <li>Training capacity building for all PR managers</li>
                      <li>Capacity building for Community Entrepreneurship</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Gajah Tunggal Tbk</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>Creating CSR Policy (2013)</li>
                      <li>Formulating CSR Manual (2013)</li>
                      <li>
                        <i>
                          Pembuatan Sustainability Report & Perhitungan SROI
                          Politeknik GT (2019)
                        </i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Perusahaan Listrik Negara (PLN)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>Capacity building about CSR for all managers</li>
                      <li>Sharing CSR, ISO 26000 dan Sustainability</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Pupuk Indonesia</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>Capacity building about CSR for all managers</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>DSN Group - Swakarsa</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        <i>Social Mapping di Muara Wahau</i>
                      </li>
                      <li>
                        <i>
                          Assessment Kinerja Kegiatan CSR yang telah
                          dilaksanakan perusahaan
                        </i>
                      </li>
                      <li>
                        <i>
                          Monitoring dan Evaluasi kegiatan CSR yang telah
                          dilaksanakan
                        </i>
                      </li>
                      <li>
                        <i>Inhouse training tentang CSR</i>
                      </li>
                      <li>
                        <i>Pembuatan Milestone, Renstra dan Renja CSR</i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>
                        PT Exploration and Production Public Company Limited &
                        SKK Migas
                      </li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        <i>
                          Seminar & Workshop Nasional “Membangun Kemitraan Yang
                          Berkelanjutan untuk Mencapai Sustainable Development
                          Goals” di 4 kota: Balikpapan, Pekanbaru, Bali,
                          Yogyakarta (2019)
                        </i>
                      </li>
                      <li>
                        <i>
                          Seminar & Workshop Nasional “Membangun Kemitraan Yang
                          Berkelanjutan untuk Mencapai Sustainable Development
                          Goals” di 5 kota: Jakarta, Makassar, Palembang, Medan,
                          Surabaya (2018)
                        </i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Bank Mestika Dharma, Tbk</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        <i>Narasumber dalam acara Rapat Kerja Triwulanan </i>
                        (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Kementerian Lingkungan Hidup</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Formulating <i>“Pedoman CSR Untuk Lingkungan Hidup”</i>
                        (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Toyota Manufacturing Motor Indonesia</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Executive Seminar: CSR Program for Sustainable Business
                        (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>British Aerospace Australia</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training “Training Stakeholder Engagement dan Project
                        Management” (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Agung Concern, Tbk</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Executive Seminar{" "}
                        <i>"CSR Untuk Bisnis Berkelanjutan"</i> (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT East West Seed Indonesia</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Pelatihan “Developing a Crisis Management Plan" (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Korindo Indonesia</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Capacity building about CSR for all managers and staffs
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>PT Vale Inco Indonesia</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Capacity building about CSR for all managers and staffs
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>British Council</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training “Governance (Tata Kelola) Social Enterprise”
                        (2017-2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Badan Perencanaan Pembangunan Daerah Banten</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        <i>
                          Pemaparan Konsep ISO 26000 dan kaitannya dengan CSR
                          kepada Forum CSR & BAPPEDA Banten (2019)
                        </i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>University of Melbourne</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        EduTrip and Visited Sustainable Campus - University of
                        Melbourne (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Uni Papua FC - Football for Peace</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Sharing Research{" "}
                        <i>
                          "Uni-Papua Football for Peace: the Challenges &
                          Choices to Achieve Sustainability"
                        </i>
                        , was held together with Thailand Embassy (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Perhimpunan Ahli Pertambangan Indonesia (PERHAPI)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Academic discussion{" "}
                        <i>
                          "Era Digitalisasi Pertambangan & Sustainable CSR
                          Program"
                        </i>{" "}
                        (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Badan Perencanaan Pembangunan Daerah Banten</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        <i>
                          Pemaparan Konsep ISO 26000 dan kaitannya dengan CSR
                          kepada Forum CSR & BAPPEDA Banten
                        </i>{" "}
                        (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Universidad de Sevilla</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Penelitian Uni Eropa (FAB-MOVE){" "}
                        <i>
                          "Collaborative Social Entreprise Research: Final
                          Meeting"
                        </i>{" "}
                        (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Global Alliance for Project Performance Standards</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training Seminar and Thought Leadership Forum{" "}
                        <i>
                          "Socially Responsible Project Management and The SDGs”
                        </i>{" "}
                        (Bersama Ausmincham, Interlink Australia and ITSI)
                        (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Pemerintah Kabupaten Tangerang</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Seminar{" "}
                        <i>
                          "Sinergitas Pemerintah Daerah dengan Pihak Swasta
                          Melalui Program TJSLP/CSR"
                        </i>{" "}
                        (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>SMP Kolese Kanisius</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training for teachers and staffs{" "}
                        <i>"Kewirausahaan untuk Remaja"</i> (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>ISTR 13th International Conference Amsterdam</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Board Meeting at Vrije Universiteit Amsterdam (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Most Valuable Brands (MVB) Indonesia</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Seminar{" "}
                        <i>
                          "Securing a Sustainable Future through Innovation and
                          Dedication"
                        </i>{" "}
                        (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Yayasan Filantra</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training Project{" "}
                        <i>
                          “Management for Sustainability based on ISO 21500”
                        </i>{" "}
                        (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Barito Pacific Group</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Training: Stakeholder Engagement: What is Your Plan?
                        (2018)
                      </li>
                      <li>
                        Training: Measuring Social Return on your CSR Investment
                        (2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Le Havre University</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Research in <i>"The Rise of Asia Presentation"</i>{" "}
                        (2018)
                      </li>
                      <li>
                        The Rise of Asia International and Multidisciplinary
                        Conference (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Project FAB-Move</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Participated as Guest Lecturer at Università Della
                        Calabria, Italy (2018)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Center for Asian Asian Philanthropy and Society</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Doing Good INDEXTM:{" "}
                        <i>“Maximizing Asia's Potential Expert Workshop”</i>{" "}
                        (2018)
                      </li>
                      <li>
                        Ketua peneliti case study Telapak{" "}
                        <i>
                          "Evolution and Resolution Telapak: Seeking Natural
                          Resource Justice for Communities”
                        </i>{" "}
                        (2016)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Universitas Katolik Atma Jaya</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Sebagai narasumber dalam pelaksanaan Diskusi
                        Kewirausahaan Sosial: “Integrasi Kewirausahaan Sosial di
                        Sistem Perguruan Tinggi” (2017)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Australian Institute of Project Management: AIPM</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Kerja sama mengenai Corporate Social Responsibility and
                        Sustainability (2017)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Indonesian Global Compact Network (IGCN)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Moderator dalam acara IGCN Forum 2017 "Making Global
                        Goals Local Business" (2017)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>The Society of Project Managers (SPM)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>SPM Project Management Conference (2017)</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>
                        International Society for Third-Sector Research (ISTR)
                      </li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>ISTR Asia Pacific PhD Seminar (2017)</li>
                      <li>ISTR 10th Asia Pacific Regional Conference (2017)</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>
                        Ministry of Health of Republic Indonesia (KEMENKES RI)
                      </li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        <i>
                          Dewan Juri Awards Prakarsa Promkes Kementerian
                          Kesehatan{" "}
                        </i>
                        (2017 – 2019)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>
                        The House of Representatives of Republic Indonesia Dewan
                        Perwakilan Rakyat RI
                      </li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Tim Ahli Penyusun Naskah Akademik Rancangan
                        Undang-Undang CSR (2017)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Interlink Technology Group</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>Diploma of Project Management Course (2016)</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>College of Management Academic Studies</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Research project in "Project European Union, For a
                        Better Tomorrow: Social Enterprises on the Move" (2016)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>International Geothermal Convention & Exhibition</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        <i>
                          Menjadi narasumber dalam pagelaran keempat
                          “International Geothermal Convention & Exhibition”
                          (2016)
                        </i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>The University of Sydney</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Participated in{" "}
                        <i>
                          "13th Biennial Australian and new Zealand Third Sector
                          Research Conference. Social justice, social enterprise
                          and the market: Challenge or Opportunity"
                        </i>{" "}
                        (2016)
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Bursa Efek Indonesia (BEI)</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Held CSR seminars for CEO for all publicly listed
                        companies
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>The Nature Conservancy</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>Village Baseline Data Assessment</li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Yayasan Sahabat Cipta</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Workshop "Measuring the Social Return on your CSR
                        Investment (SROI)"
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clientsEachProject">
                  <div className="clientsProjectTitle">
                    <ul>
                      <li>Save The Children</li>
                    </ul>
                  </div>
                  <div className="clientsProjectContent">
                    <ul>
                      <li>
                        Round table Discussion and Develop a policy brief for
                        CSR
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
      <Footer />
    </div>
  );
};

export default Client;
