import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

// API
import { getUserByEmail } from "../api/getUserByEmail";
import { logoutUser } from "../api/logoutUser";

// Sweet Alert v2
import Swal from "sweetalert2";

export function RequireAuth({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [otp, setOtp] = useState(false);

  const access_token = localStorage.getItem("access_token");
  const isVerified = localStorage.getItem("isVerified");
  const email = localStorage.getItem("email");

  useEffect(() => {
    const getUser = async () => {
      try {
        const result = await getUserByEmail(email);
        setOtp(result.data.otpVerification);
        localStorage.setItem("isVerified", result.data.otpVerification);
      } catch (err) {
        const { message } = err.response.data || {};
        if (
          message &&
          (message === "Unauthorized" ||
            message === "Unauthenticated" ||
            message === "TokenExpiredError")
        ) {
          Swal.fire({
            position: "top-end",
            text: "Token expired, please re-login",
            showConfirmButton: false,
            timer: 1500,
            backdrop: false,
            width: "450px",
          });
          localStorage.removeItem("isVerified");
          localStorage.removeItem("access_token");
          localStorage.removeItem("email");
          navigate("/signIn");
        }
      }
    };
    getUser();
  }, [location]);

  if (otp) {
    return access_token && otp ? (
      children
    ) : (
      <Navigate to="/signIn" replace state={{ path: location.pathname }} />
    );
  } else if (isVerified !== "true") {
    logoutUser(email).then(() => {
      localStorage.removeItem("isVerified");
      localStorage.removeItem("access_token");
      localStorage.removeItem("email");
    });
  } else if (!email || !access_token) {
    localStorage.removeItem("isVerified");
    localStorage.removeItem("access_token");
    localStorage.removeItem("email");
  }

  return access_token && isVerified === "true" ? (
    children
  ) : (
    <Navigate to="/signIn" replace state={{ path: location.pathname }} />
  );
}

export function IsSignedIn({ children }) {
  const location = useLocation();

  const access_token = localStorage.getItem("access_token");
  const isVerified = localStorage.getItem("isVerified");
  const email = localStorage.getItem("email");

  if (isVerified === "false") {
    return children;
  } else if (
    !isVerified ||
    localStorage.getItem("isVerified") !== "true" ||
    !email ||
    !access_token
  ) {
    localStorage.removeItem("isVerified");
    localStorage.removeItem("access_token");
    localStorage.removeItem("email");
  }

  return access_token && email ? (
    <Navigate to="/admin/post" replace state={{ path: location.pathname }} />
  ) : (
    children
  );
}
