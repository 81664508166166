// React DOM
import { useLocation, useNavigate } from "react-router-dom";

// Styled components
import styled from "styled-components";

// Icons
import eventAdmin from "../images/icons/admin/eventAdmin.png";
import homeAdmin from "../images/icons/admin/homeAdmin.png";
import memberAdmin from "../images/icons/admin/memberAdmin.png";
import postAdmin from "../images/icons/admin/postAdmin.png";
import settingsAdmin from "../images/icons/admin/settingsAdmin.png";
import sidebarISAacademyIcon from "../images/icons/admin/sidebarISAacademyIcon.png";
import AdminSidebarActivitiesIcon from "../images/icons/admin/AdminSidebarActivitiesIcon.png";

// CSS Styling
import "../styles/admin.css";

const AdminNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onActive = (path) => {
    navigate(path);
    window.location.reload();
  };

  return (
    <div className="adminNavbar">
      <div className="adminNavbarContainer">
        <div className="adminNavbarTitle">
          <span>
            <img
              src="https://media.discordapp.net/attachments/929955483522043905/984000206137884672/ICON__LOGOTYPE_WHITE.png?width=676&height=676"
              alt="ISA white icon"
              width={150}
              height={150}
            />
          </span>
        </div>
        <div className="adminNavbarList">
          <ul className="list-group list-group-vertical border-0">
            <SideBarItem
              style={{ display: "none" }}
              location={location.pathname}
              route="/admin/dashboard"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/dashboard"
                >
                  <SideBarItemImage src={homeAdmin} alt="dashboard" />
                </SideBarImageContainer>
                Dashboards
              </SideBarText>
            </SideBarItem>
            <SideBarItem
              location={location.pathname}
              onClick={() => onActive("/admin/post")}
              route="/admin/post"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/post"
                >
                  <SideBarItemImage src={postAdmin} alt="post" />
                </SideBarImageContainer>
                Posts
              </SideBarText>
            </SideBarItem>
            <SideBarItem
              location={location.pathname}
              onClick={() => onActive("/admin/isaAcademy-workshops")}
              route="/admin/isaAcademy"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/isaAcademy-workshops"
                >
                  <SideBarItemImage
                    src={sidebarISAacademyIcon}
                    alt="isa academy"
                  />
                </SideBarImageContainer>
                ISA Academy
              </SideBarText>
            </SideBarItem>
            <SideBarItemWorkshop
              location={location.pathname}
              onClick={() => onActive("/admin/isaAcademy-workshops")}
              route="/admin/isaAcademy-workshops"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/isaAcademy-workshops"
                ></SideBarImageContainer>
                Workshops
              </SideBarText>
            </SideBarItemWorkshop>
            <SideBarItemTestimonial
              location={location.pathname}
              onClick={() => onActive("/admin/isaAcademy-testimonial")}
              route="/admin/isaAcademy-testimonial"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/isaAcademy-testimonial"
                ></SideBarImageContainer>
                Testimonial
              </SideBarText>
            </SideBarItemTestimonial>
            <SideBarItem
              location={location.pathname}
              onClick={() => onActive("/admin/activities")}
              route="/admin/activities"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/activities"
                >
                  <SideBarItemImage
                    src={AdminSidebarActivitiesIcon}
                    alt="activities"
                  />
                </SideBarImageContainer>
                Activities
              </SideBarText>
            </SideBarItem>
            <SideBarItem
              location={location.pathname}
              onClick={() => onActive("/admin/founders-story")}
              route="/admin/founders-story"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/founders-story"
                >
                  <SideBarItemImage
                    src={AdminSidebarActivitiesIcon}
                    alt="founders-story"
                  />
                </SideBarImageContainer>
                Founder's Story
              </SideBarText>
            </SideBarItem>
            <SideBarItem
              location={location.pathname}
              onClick={() => onActive("/admin/books")}
              route="/admin/books"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/books"
                >
                  <SideBarItemImage src={postAdmin} alt="post" />
                </SideBarImageContainer>
                Books
              </SideBarText>
            </SideBarItem>
            <SideBarItem
              style={{ display: "none" }}
              location={location.pathname}
              route="/admin/event"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/event"
                >
                  <SideBarItemImage src={eventAdmin} alt="event" />
                </SideBarImageContainer>
                Events
              </SideBarText>
            </SideBarItem>
            <SideBarItem
              style={{ display: "none" }}
              location={location.pathname}
              route="/admin/member"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/member"
                >
                  <SideBarItemImage src={memberAdmin} alt="member" />
                </SideBarImageContainer>
                Members
              </SideBarText>
            </SideBarItem>
            <SideBarItem
              style={{ display: "none" }}
              location={location.pathname}
              route="/admin/setting"
            >
              <SideBarText>
                <SideBarImageContainer
                  location={location.pathname}
                  route="/admin/setting"
                >
                  <SideBarItemImage src={settingsAdmin} alt="setting" />
                </SideBarImageContainer>
                Settings
              </SideBarText>
            </SideBarItem>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;

const SideBarItem = styled.li`
  padding: 1em;
  color: ${({ location, route }) =>
    location.includes(route) ? "white" : "#FFC635"};
  background-color: ${({ location, route }) =>
    location.includes(route) ? "#2AAADA" : "none"};
  padding-left: 3em;
  padding-bottom: 1em;
  box-shadow: ${({ location, route }) =>
    location.includes(route) ? "0px 8px 30px rgba(0, 0, 0, 0.21)" : "none"};
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #2aaada !important;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  }
`;

const SideBarItemWorkshop = styled.div`
  padding: 1em;
  color: white;
  font-weight: ${({ location, route }) =>
    location.includes(route) ? "700" : "400"};
  padding-left: 5.2em;
  padding-bottom: 1em;
  cursor: pointer;
`;

const SideBarItemTestimonial = styled.div`
  padding: 1em;
  color: white;
  font-weight: ${({ location, route }) =>
    location.includes(route) ? "700" : "400"};
  padding-left: 5.2em;
  padding-bottom: 1em;
  cursor: pointer;
`;

const SideBarText = styled.p`
  margin-bottom: 0;
`;

const SideBarImageContainer = styled.span`
  filter: ${({ location, route }) =>
    location.includes(route) ? "brightness(0) invert(1)" : "none"};
  &:hover {
    filter: brightness(0) invert(1);
  }
`;

const SideBarItemImage = styled.img`
  max-width: 1.1em;
  margin-right: 1.2em;
  margin-top: -0.3em;
`;
